<template>
      <router-link v-for="tag in categorys" :to="tag.get_absolute_url" class="tagbtn--category" :class="{'tagbtn--category-active': tag.id == current_tag}">
        <!-- <img class="icon--tags" :src="tag.get_icon"> --> {{ tag.name }}
      </router-link>
</template>

<script>
  export default {
  	name: 'TagsCategory',
  	props: {
  		categorys: Object,
  		current_tag: Number,
  	},

  }
</script>