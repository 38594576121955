<template>
    <div class="full--auth--back">

        <HeaderSmall />

        <div class="position--auth-form">
            <div class="max--form">
                <h4 class="pb-3 h4--sub-main">Ошибка</h4>
            </div>
        </div>

        <Footersmall />

    </div>
</template>

<script>
    import { toast } from 'bulma-toast'
    import axios from 'axios'

    import HeaderSmall from '@/components/Header/HeaderSmall'
    import Footersmall from '@/components/Footer/Footersmall'

    export default {
        name: 'ErrorSubs',
        data() {
            return {
                sub_id: "",
                status: {},
                status_payment: "",
            }
        },
        components: {
            HeaderSmall,
            Footersmall
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {
            document.title = 'Ошибка подписки'
            this.alertDanger();

            this.sub_id = this.$store.state.sub_id

            this.statusNotifacation();

            setTimeout(() => {
                this.removeSub();
            }, 300)
        },
        methods: {
            async statusNotifacation() {
                await axios
                    .get(`/api/v1/paymentStatus/${this.sub_id}/`)
                    .then(response => {
                        this.status = response.data

                        this.status_payment = response.data[9][1]
                    })
                    .catch(error => {
                        console.log(error)
                    })                
            },
            alertDanger() {
                toast({
                    message: 'Что-то пошло не так попробуйте еще раз',
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'bottom-right',
                })
            },
            removeSub() {

            },
        },
        computed: {
      
        }
    }
</script>