<template>
    <div class="position--auth w-100">
        <div class="container paddingcontainernew">
            <div class="footer-menu-sub open-flex-992">
                <a href="mailto:info@fsop-russia.ru" class="rd-nav-sub">info@fsop-russia.ru</a>         
                <!-- <a href="" class="rd-nav-sub">Пользовательское соглашение</a> -->
                <a href="" class="rd-nav-sub">
                @ 2024 Fsop-Russia. All rights reserved.</a>
                <a href="" class="studio_by_dmitriy_markin">Разработанно студией Дмитрия Маркина</a>                        
            </div>
            <div class="footer-menu-sub close-flex-992">
                <a href="" class="rd-nav-sub">@ 2024 Fsop-Russia. All rights reserved.</a>          
                <!-- <a href="" class="rd-nav-sub">Пользовательское соглашение</a> -->
                <a href="mailto:info@fsop-russia.ru" class="rd-nav-sub">info@fsop-russia.ru</a>
                <a href="" class="studio_by_dmitriy_markin">Разработанно студией Дмитрия Маркина</a>                        
            </div>              
        </div>             
    </div>    
</template>

<script>
export default {
  name: 'Footersmall',
}
</script>