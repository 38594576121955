<template>
    <div class="box--blue mb-4 p-lg-4" style="overflow: auto !important;">
        <h3 class="font-order mb-6 fff">Заказ №{{ order.id }}</h3>

        <h4 class="is-size-5 pb-3 fff">Товары:</h4>

        <table class="table is-fullwidth" >
            <thead>
                <tr>
                    <th>Название</th>
                    <th>Цена</th>
                    <th>Количество</th>
                    <th>Итог</th>
                </tr>
            </thead>

            <tbody>
                <tr
                    v-for="item in order.items"
                    v-bind:key="item.product"
                >
                    <td>{{ item.product.name }}</td>
                    <td v-if="item.product.discount">
                       <span>{{ item.product.discount }}₽</span>
                    </td>
                    <td v-else>
                        {{ item.product.price }}₽
                    </td>
                    <td>{{ item.quantity }}</td>
                    <td style="font-weight: 600;">{{ getItemTotal(item).toFixed(2) }}₽</td>
                </tr>
            </tbody>
        </table>

        <div class="border--order-last"></div>

        <div class="d-flex justify-content-end">
            <h4 class="is-size-6 pb-3 fff">Общая сумма заказа: <span class="fontWeight600">{{ orderTotalPrice().toFixed(2) }}₽</span></h4>
        </div>
    </div>
</template>

<style type="text/css">
    .table {
        background-color: #0B1629 !important;
        color: #fff !important;
    }

    .border--order-last {
        width: 100%;
        height: 1px;
        background-color: #919191;
        opacity: 0.4;
        margin-bottom: 20px;
    }

    .table thead td, .table thead th {
        color: #fff !important;
    }

    .box--blue {
        background-color: #0B1629;
        border-radius: 6px;
        box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
        color: #4a4a4a;
        display: block;
        padding: 1.5rem;
    }

    .fontWeight600 {
        font-weight: 600;
    }
</style>

<script>
export default {
    name: 'OrderSummary',
    props: {
        order: Object
    },
    mounted() {
        console.log(this.order)
    },
    methods: {
        getItemTotal(item) {
            if (item.product.discount) {
                return item.quantity * item.product.discount
            } else {
                return item.quantity * item.product.price
            }  
        },
        orderTotalLength(order) {
            return order.items.reduce((acc, curVal) => {
                return acc += curVal.quantity
            }, 0)
        },
        orderTotalPrice() {
            return this.order.items.reduce((acc, curVal) => {
                if (curVal.product.discount) {
                    return acc += curVal.product.discount * curVal.quantity
                } else {
                    return acc += curVal.product.price * curVal.quantity
                }
            }, 0)
        },
    }
}
</script>