<template>

  <div class="search--block--main w-100">
   <form class="form--search-flex w-100" method="get" action="/search">
    <div class="search--block" style="width: 100% !important;">
      <div v-show="focused" :class="{'hide_search': search_validate}"
      class="search--label--icon"> 
      <span class="icon--search">
        <svg xmlns="http://www.w3.org/2000/svg" width="17.483" height="21.291" viewBox="0 0 17.483 21.291">
          <g id="Сгруппировать_1151" data-name="Сгруппировать 1151" transform="translate(-434 -2857.354)">
            <g id="Сгруппировать_799" data-name="Сгруппировать 799" transform="translate(1297 -6544.646)">
              <g id="Сгруппировать_798" data-name="Сгруппировать 798">
                <g id="Эллипс_77" data-name="Эллипс 77" transform="translate(-863 9402)" fill="none" stroke="#ccc" stroke-width="1">
                  <circle cx="8" cy="8" r="8" stroke="none"/>
                  <circle cx="8" cy="8" r="7.5" fill="none"/>
                </g>
                <g id="Прямоугольник_182" data-name="Прямоугольник 182" transform="translate(-850.659 9416.52) rotate(50)" fill="#ccc" stroke="#ccc" stroke-width="1">
                  <rect width="8" height="1" stroke="none"/>
                  <rect x="0.5" y="0.5" width="7" fill="none"/>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </span>
      <label class="search--label">Найти товар</label>              
    </div>

    <input @focus="focused = false" class="search--input" style="width: 100% !important;" type="text" v-model="query__search" name="query">            
  </div>

  <button class="search--button-find">Найти</button>
</form>       
</div>    

<div class="d-flex justify-content-start tags-block--category">
  <TagsCategory 
  :categorys="categorys"
  :current_tag="current_tag" 
  />
</div>

<div class="row mb-4">
  <div class="col-lg-2 marginbottomlist" v-for="(prod, index) in products">
    <router-link :to="prod.get_absolute_url">
      <!-- <div class="img--list"></div> -->
      <div class="img--inner">
        <img class="img-fluid img-fluid--hovered" :src="prod.get_image">
      </div>
    </router-link>
    <div class="block-tittle--product">
     <router-link :to="prod.get_absolute_url" class="product--title">{{ prod.name }}</router-link>

     <h4 class="product--price">
      <span v-if="prod.discount" class="product--discount">{{ prod.discount }} Руб.</span>
      <span v-if="prod.discount" class="text--product-s">
        {{ prod.price }} Руб.
      </span>
      <span v-else>
        {{ prod.price }} Руб.
      </span> 

    </h4>            
  </div>
  <button v-if="prod.amount > 0" class="product--btn" @click="addToCart(prod.id)">Добавить в корзину</button>         
  <button v-else class="product--btn-disabled" disabled="disabled">Нет в наличии</button> 
</div>
</div>

<!--   <div class="d-flex w-100 justify-content-center">
    <div class="notMoreBlock">
      <h3 class="notMoreProducts">{{ sortProducts.length }} / {{ total }} 
      Товаров</h3>
    </div>
  </div> --> 


</template>

<script>
  import TagsCategory from '@/components/TagsCategory'

  export default {
    name: 'ProductBoxCategory',
    components: {
      TagsCategory
    },
    props: {
      products: Object,
      categorys: Object,
      current_tag: Number,
    },
    data() {
      return {
        quantity: 1,
        focused: true,

        cart: {
          items: []
        },

        query__search: "",
        quantity: 1,
        page: 1,
        total: 1,
      }
    },
    mounted() {
      this.cart = this.$store.state.cart
    },
    methods: {
      addToCart(index) {

        let check_amount = 0;

        if (this.cart.items.length > 0) {

          for (let q = 0; q < this.cart.items.length; q++) {
            const item = this.cart.items[q]           
            let id = item.product.id

            if (id == this.products[index].id) {
              if (item.quantity >= this.products[index].amount) {
                check_amount = 1
              }
            }

          }

        }

        if (check_amount == 0) {

          const item = {
            product: this.products[index],
            quantity: this.quantity
          }

          this.$store.commit('addToCart', item)

          toast({
            message: 'Товар был добавлен в корзину',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          })
        } else {
          toast({
            message: 'Товар больше нет в наличии',
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          })
        }

      }, 
    },  
    computed: {
      search_validate() {
        if(this.query__search.length > 0) {
          return true;
        } else {
          return false;
        }
      },

    }

  }
</script>