<template>

  <HeaderFsop />
<!-- 

      <div class="container paddingcontainernew">
          <div class="footer-menu--block" style="padding-bottom: 30px !important;">
              <h3 class="contact">О Спортобществе «Россия»</h3>
          </div>
      </div>

      <div class="position-relative max-h--aboutfsop mb-5">
        <img src="@/assets/aboutfsop.png" class="img-fluid w-100">
        <div class="back--aboutfsop-img"></div>
        <div class="text-absolute-fsop paddingcontainernew">
          <h4 class="fsop---text-abs container">
            ФИЗКУЛЬТУРНО-СПОРТИВНОЕ ОБЩЕСТВО <br>
            ПРОФСОЮЗОВ «РОССИЯ»
          </h4>
        </div>
        
      </div> -->

      <div class="container paddingcontainernew">

        <div class="block--fsop-team">
          <div class="footer-menu--block">
              <h3 class="contact">Деятельность</h3>
          </div>

          <div class="row activity-block__main" v-for="item in activityList">

            <div class="col-lg-6 marg-mob__activity" v-if="item.place == 0">
              <div>
                  <img :src="item.get_image" :alt="item.name" class="img-fluid" style="width: 100%;">
              </div>
            </div>
            
            <div class="col-lg-6 marg-mob__activity">
              <div>
                <h3 class="main-team-h3">{{ item.name }}</h3>
                <p class="text--fluid--teammain font-middle__text" v-html="item.description"></p>
                <a class="activity-button__main" :href="item.link" target="_blank">
                  Подробнее
                </a>
              </div>
            </div>

            <div class="col-lg-6 marg-mob__activity" v-if="item.place == 1">
              <div>
                  <img :src="item.get_image" :alt="item.name" class="img-fluid" style="width: 100%;">
              </div>
            </div>

          </div> 

        </div>
      </div>
  <FooterFsop />

</template>

<style type="text/css">

.activity-button__main:hover {
  color: #fff !important;
  background: #ccc !important;
}

.activity-block__main {
  padding: 0 0 120px;
}
@media (max-width: 1024px) {
  .marg-mob__activity h3 {
    padding: 0 0 10px;
  }

  .activity-block__main {
    padding: 0 0 20px;
  }

  .font-middle__text p {
    font-weight: 500;
    font-size: 15px !important;
  }

  .marg-mob__activity {
    padding: 0 0 40px;
  }
}

.activity-button__main {
    transition: 0.3s;
    display: block;
    width: 200px;
    background-color: #EB3624 !important;
    color: #fff;
    text-align: center;
    font-size: 14px;
    line-height: 40px;
    border-radius: 6px;
    font-weight: 600;
    align-items: center;
    padding: 4px 6px;
}

.font-middle__text {
  padding: 0 0 30px;
}

.font-middle__text p {
  font-weight: 500;
  font-size: 18px;
}

</style>

<script>
  import HeaderFsop from '@/components/Header/HeaderFsop'
  import FooterFsop from '@/components/Footer/FooterFsop'  
  import axios from 'axios'
  import { toast } from 'bulma-toast'

  export default {
    name: 'Activity',
    data() {
      return {
        activityList: [],
      }
    },
    components: {
      HeaderFsop,
      FooterFsop
    },
    mounted() {
      this.GetActivitys();
    },
    methods: {
        GoTo(url) {
          this.$router.push(url)
        },
        async GetActivitys() {

          await axios
          .get('/api/v1/get/activity/list/')
          .then(response => {
            this.activityList = response.data
            console.log(response.data)
          })
          .catch(error => {
            console.log(error)
          })

        },
    }
  }
</script>