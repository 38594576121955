<template>
<Header />

    <div class="full--blue">

        <div class="container paddingcontainernew">
            <div class="parners--block">
                <div class="d-flex justify-content-between mb-6">
                    <div class="">
                        <h4 class="my-account--name d-flex">
                            <router-link to="/my-account" class="fff svgfill">
                                <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="20"
                                height="20" viewBox="0 0 448 512"><path d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l105.4 105.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H416C433.7 224 447.1 238.3 447.1 256z"/></svg>
                                Назад
                            </router-link>
                        </h4>
                    </div>
                </div>

                <div class="block-form--edit">
                    <h4 class="user--h4-edit">Личные данные</h4>
                    <form @submit.prevent="submitForm">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="edit--form-div">
                                    <label
                                        class="edit-form--label"
                                        :class="{'valid--edit-form': vcity}"
                                    >Город</label>
                                    <input type="text" v-model="user_me.city" class="edit--input-form">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="edit--form-div">
                                    <label
                                        class="edit-form--label"
                                        :class="{'valid--edit-form': vsport}"
                                    >
                                        Любимый вид спорта
                                    </label>
                                    <select  class="edit--input-form" v-model="user_me.favoriteSport">
                                        <option value="">Выберите вид спорта:</option>
                                        <option v-for="list in sports" :value="list.id">{{ list.name }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
<!-- 
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="edit--form-div">
                                    <label class="edit-form--label"
                                    :class="{'valid--edit-form': validate_city}"
                                     >Город</label>
                                    <input type="text" v-model="user_me.city" class="edit--input-form">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="edit--form-div">
                                    <label 
                                        class="edit-form--label"
                                        :class="{'valid--edit-form': validate_adress}"
                                    >Улица</label>
                                    <input type="text" v-model="user_me.adress" class="edit--input-form">
                                </div>
                            </div>
                        </div> -->

                        <div class="row">
                            <div class="col-lg-6">
                                <button class="btn-edit-ready">
                                    Сохранить
                                </button>                                 
                            </div>
                            <div class="col-lg-6 d-flex justify-content-end">
                                <router-link to="/my-account" class="a-edit-back">
                                    Назад
                                </router-link>                                
                            </div>
                        </div>


                    </form>
                </div>                

            </div>
        </div>
        
    </div>


    <Footer :blue="true" :search_on="false"/>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

import Header from '@/components/Header/Header'
import Footer from '@/components/Footer/Footer'

export default {
    name: 'EditProfile',
    components: {
        Header,
        Footer
    },
    data() {
        return {
            orders: [],
            user_me: {},
            user_id: "",
            token: "",
            current_id: "",
            sports: [],
        }
    },
    beforeCreate() {
      this.$store.commit('initializeStore')

      const user_id = this.$store.state.user_id

      this.user_id = user_id
    },
    mounted() {
        document.title = 'Редактировать профиль'

        this.user_id = this.$store.state.user_id

        this.getMyAccount()
        this.GetSports()
    },
    methods: {
        async getMyAccount() {
            this.$store.commit('initializeStore')

            this.$store.commit('setIsLoading', true)

            await axios
                .get(`/api/v1/me/${this.user_id}/`)
                .then(response => {
                    this.user_me = response.data
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        },
        async submitForm() {
            const formData = {
                name: this.user_me.name,
                surname: this.user_me.surname,
                city: this.user_me.city,
                email: this.user_me.email,
                adress: this.user_me.adress
            }

            await axios
                .put(`/api/v1/me/${this.user_id}/`, formData)
                .then(response => {
                    toast({
                        message: 'Данные успешно обновленны',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })

                    this.$router.push('/my-account')
                })                
                .catch(error => {
                    console.log(error)
                    toast({
                        message: 'Произошла ошибка',
                        type: 'is-danger',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })                    
                })           
        },        
        async GetSports() {
             await axios
                .get('/api/v1/all_sports/')
                .then(response => {this.sports = response.data})
                .catch(error => {console.log(error)})
        },    
    },
    computed: {
      validate_name() {
        if(this.user_me.name) {
          return true;
        } else {
          return false;
        }
      },
      vcity() {
        return this.user_me.city ? true : false;
      },
      vsport() {
        return this.user_me.favoriteSport ? true : false;
      },      
      validate_adress() {
        if(this.user_me.adress) {
          return true;
        } else {
          return false;
        }
      },
    }        
}
</script>