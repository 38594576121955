<template>
  <Header />

  <div class="home">
    <CarouselMain :baners="bannerss" />
    <div class="container paddingcontainernew">
      <div class="product-block">
        <TheBestProduct title="лучшие предложения" />
      </div>
    </div>

    <div class="product-block back--red">
      <div class="container paddingcontainernew">
        <TheBestProduct title="Товары спортобщества" :black="true"/>
      </div>
    </div>

    <div class="container paddingcontainernew">
      <div class="product-block">
        <ProductListHome :title_on="false" />

        <div class="divHomeCenter">
          <router-link to="/all_product">
            <div class="btnHome">
              Все товары
            </div>
          </router-link>          
        </div>

      </div>

    </div>
        
  </div>

  <Footer />

</template>

<script>
  import axios from 'axios'

  import ProductBox from '@/components/ProductBox'
  // import Carousel from '@/components/Carousel'
  import TheBestProduct from '@/components/TheBestProduct'
  import CarouselMain from '@/components/CarouselMain'
  import ProductListHome from '@/components/ProductListHome'
  import Header from '@/components/Header/Header'
  import Footer from '@/components/Footer/Footer'
  export default {
    name: 'Home',
    data() {
      return {
        items: [],
        productsCategory: [],
        bannerss: [],
        page: 1,
        total: 1
      }
    },
    components: {
      ProductBox,
      TheBestProduct,
      CarouselMain,
      ProductListHome,
      Header,
      Footer
      // Carousel,
    },
    mounted() {
      this.getProductsCategory()

      this.getBannerss()

      document.title = 'Интернет-магазин'
    },
    methods: {
      async getProductsCategory() {
        this.$store.commit('setIsLoading', true)

        await axios
        .get('/api/v1/catall/')
        .then(response => {
          this.productsCategory = response.data
        })
        .catch(error => {
          console.log(error)
        })

        this.$store.commit('setIsLoading', false)
      },
      async getBannerss() {
        this.$store.commit('setIsLoading', true)

        await axios
        .get('/api/v1/banners/')
        .then(response => {
          this.bannerss = response.data
        })
        .catch(error => {
          console.log(error)
        })

        this.$store.commit('setIsLoading', false)
      }      
    }
   
  }
</script>
