<template>

  <div class="container paddingcontainernew">
      <div class="footer-menu--block" style="padding-bottom: 30px !important;">
        <h3 class="contact">Последние новости</h3>
      </div>
<!-- 
      <div class="row marginbottomsize-news">
        
        <div class="col-lg-12">
          <router-link :to="'/news/' + firstbanner.id">
          <div class="carousel-inner banner-first">
            <img :src="firstbanner.get_image" alt="" class="banner--news hover-scale rounded">
              <div class="z-back--slide"></div>
              <div class="z-back-positionabsolute">
                  <h3 class="bannerH3">{{ firstbanner.name }}</h3>
                  <div class="row">
                    <div class="col-lg-8">
                      <router-link :to="'/news/' + firstbanner.id" class="bannerH3-disk">{{ firstbanner.description }}</router-link>
                      <router-link :to="'/news/' + firstbanner.id" class="bannerH3-disk">{{ datanews(firstbanner.date_added) }}</router-link>
                    </div>
                  </div>
                  
              </div>
          </div>
          </router-link>
        </div>
        
      </div>

      <div class="row marginbottomsize-news">
        
          <div class="col-lg-6">
            <router-link :to="'/news/' + secondbanner.id">
            <div class="carousel-inner banner-first">
              <img :src="secondbanner.get_image" alt="" class="banner--news hover-scale rounded">
                <div class="z-back-positionabsolute">
                    <h3 class="bannerH3">{{ secondbanner.name }}</h3>
                    <router-link :to="'/news/' + secondbanner.id" class="bannerH3-disk">{{ secondbanner.description }}</router-link>
                    <router-link :to="'/news/' + secondbanner.id" class="bannerH3-disk">{{ datanews(secondbanner.date_added) }}</router-link>
                </div>
            </div>
            </router-link>
          </div>
        
        <div class="col-lg-6">
            <router-link :to="'/news/' + thirtybanner.id">
            <div class="carousel-inner banner-first">
              <img :src="thirtybanner.get_image" alt="" class="banner--news hover-scale rounded">
                <div class="z-back-positionabsolute">
                    <h3 class="bannerH3">{{ thirtybanner.name }}</h3>
                    <router-link :to="'/news/' + thirtybanner.id" class="bannerH3-disk">{{ thirtybanner.description }}</router-link>
                    <router-link :to="'/news/' + thirtybanner.id" class="bannerH3-disk">{{ datanews(thirtybanner.date_added) }}</router-link>
                </div>
            </div>
            </router-link>
        </div>
      </div> -->

      <div class="row">
        <div v-for="(list, index) in news.slice(0, 6)" class="col-lg-4 news_listing" :key="index">
            <router-link :to="'/news/' + list.id">
                <div class="news--fluid">
                    <img class="img-fluid hover-scale rounded" :src="list.get_image">
                </div>            
            </router-link>
            
            <div class="div-block--news-list">
              <router-link :to="'/news/' + list.id" class="athelets-name" style="margin-bottom: 10px;">{{ list.name }}</router-link>

              <router-link :to="'/news/' + list.id" class="athelets-disk" style="margin-bottom: 10px;" v-html="list.description"></router-link>

              <router-link :to="'/news/' + list.id" class="athelets-disk" style="margin-bottom: 10px;">{{ datanews(list.date_added) }}</router-link>

            </div>
        </div>
      </div>

  </div>

</template>

<style type="text/css">
  .banner-first {
    z-index: 1;
    max-height: 350px;
    width: 100%;
    background-color: transparent !important;
    font-size: 20px;
    display: flex;
    border-radius: 0.25rem;
    justify-content: center;
    align-items: center;
  }

  .news_listing {
    margin-bottom: 20px;
  }

  .marginbottomsize-news {
    margin-bottom: 30px;
  }

  .athelets-disk {
    margin-right: 5px;
    -webkit-line-clamp: 3; /* Число отображаемых строк */
    display: -webkit-box; /* Включаем флексбоксы */
    -webkit-box-orient: vertical; /* Вертикальная ориентация */
    overflow: hidden !important; /* Обрезаем всё за пределами блока */
    font-weight: 500;
    color: #111;
    font-size: 14px;  
  }

  .athelets-disk p {
    display: inline !important;
  }

  .z-back--newsbanner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 9999;
  }

  .bannerH3 {
    font-weight: 600;
    color: #fff;
    font-size: 18px;
  }

  .z-back-positionabsolute {
    position: absolute;
    bottom: 30px;
    left: 30px;
    z-index: 9999;
  }

  .news--fluid {
      max-width: 100%;
      position: relative;
      overflow: hidden;
      margin-bottom: 20px;
      margin-right: 30px;
  }

  .banner--news {
    width: 100%!important;
    z-index: 9999;
    height: 100%!important;
    object-fit: cover!important;
  }

  .text--fsop-news {
    font-weight: 600;
    color: #333;
    text-align: right;
    font-size: 16px;    
  }

  .bannerH3-disk {
    margin-right: 20px;
    margin-top: 10px;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 600;
    color: #fff;
    font-size: 14px;    
  }
</style>

<script>
  import axios from 'axios'
  import { toast } from 'bulma-toast'
  import moment from 'moment';

  export default {
    name: 'NewsList',
    data() {
      return {
        page: 1,
        total: 1,
        news: [],
        firstbanner: {},
        secondbanner: {},
        thirtybanner: {},
      }
    },
    mounted() {
      this.GetNewsList(this.page)

    },
    methods: {
         async GetNewsList(pageNumber) {

            await axios
            .get(`/api/v1/news/?page=${pageNumber}`)
            .then(response => {
                this.total = response.data.count
                this.news = response.data.results
            })
            .catch(error => {
              console.log(error)
            })

        },    
        datanews(date) {
          moment.locale('ru');
          const datasort = moment(String(date)).format('DD MMMM YYYY')
          return datasort
        },  
    }
  }
</script>