<template>

  <HeaderFsop />
<!-- 

      <div class="container paddingcontainernew">
          <div class="footer-menu--block" style="padding-bottom: 30px !important;">
              <h3 class="contact">О Спортобществе «Россия»</h3>
          </div>
      </div>

      <div class="position-relative max-h--aboutfsop mb-5">
        <img src="@/assets/aboutfsop.png" class="img-fluid w-100">
        <div class="back--aboutfsop-img"></div>
        <div class="text-absolute-fsop paddingcontainernew">
          <h4 class="fsop---text-abs container">
            ФИЗКУЛЬТУРНО-СПОРТИВНОЕ ОБЩЕСТВО <br>
            ПРОФСОЮЗОВ «РОССИЯ»
          </h4>
        </div>
        
      </div> -->

      <div class="container paddingcontainernew">

        <div class="block--fsop-team">
          <div class="footer-menu--block">
              <h3 class="contact">{{ history.name }}</h3>
          </div>

          <div class="row marginb-6">
            <p v-html="history.description">
            </p>
          </div> 
        </div>
      </div>
  <FooterFsop />

</template>

<style type="text/css">
</style>

<script>
  import HeaderFsop from '@/components/Header/HeaderFsop'
  import FooterFsop from '@/components/Footer/FooterFsop'  
  import axios from 'axios'
  import { toast } from 'bulma-toast'

  export default {
    name: 'History',
    data() {
      return {
        history: {},
      }
    },
    components: {
      HeaderFsop,
      FooterFsop
    },
    mounted() {
      this.GetHistory()
    },
    methods: {
        async GetHistory() {

          await axios
          .get('/api/v1/get/history/object/')
          .then(response => {
            this.history = response.data
            console.log(response.data)
          })
          .catch(error => {
            console.log(error)
          })

        },

    }
  }
</script>