<template>

  <div class="parth__block_home">

      <div class="container paddingcontainernew">
          <div class="footer-menu--block part__main_menu">
              <h3 class="contact">ПАРТНЕРЫ</h3>
          </div>
      </div>

    <div class="parth--scroll" data-aos="fade-up">
        <div class="marquee-0">
            <div class="marquee">
                <div class="marquee__group" 
                >
                    <img class="l-img"
                        v-for="(list,index) in parthnersList" 
                        :src="list.get_image"
                    >
                </div>
                <div class="marquee__group" 
                >
                    <img class="l-img"
                        v-for="(list,index) in parthnersList" 
                        :src="list.get_image"
                    >
                </div>
            </div>


        </div>
    </div>

      <div class="divHomeCenter">
        <router-link to="/partners">
          <div class="btnHomeParthners">
            Стать партнером
          </div>
        </router-link>          
      </div>
  </div>


</template>

<style type="text/css">

.btnHomeParthners {
    display: block;
    width: 305px;
    background-color: #EB3624;
    color: #fff;
    text-align: center;
    font-size: 14px;
    line-height: 45px;
    border-radius: 10px;
    font-weight: 600;
    align-items: center;
    transition: 0.2s ease;  
}

.btnHomeParthners:hover {
  background-color: #D63727 !important;
  color: #fff !important;
}

.marquee-0 {
    --space: 0rem;
    display: grid;
    align-content: center;
}

.marquee {
    --duration: 20s;
    --gap: 0rem;
    display: flex;
/*    overflow: hidden;*/
    user-select: none;
    position: relative;
    max-width: 100vw;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    /* overflow-x: hidden; */
}

.marquee:hover {
    z-index: 99;
}

.marquee__group {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 100%;
    animation: scroll var(--duration) linear infinite;
}

.marquee__group img {
    width: 180px;
/*    background: #FEF9F3;*/
    padding: 0px 10px;    
    box-sizing: border-box;
    position: relative;
}

@media (max-width: 1024px) {
    .marquee__group img {
        width: 140px !important;
        padding: 0px 10px;
    }
}

.marquee--reverse .marquee__group {
    animation-direction: reverse;
    animation-delay: calc(var(--duration) / -2);
}


@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-100% - var(--gap)));
    }
}

.parth--scroll {
    margin-bottom: 50px;
}

.part__main_menu {
	padding-bottom: 50px;
}

</style>

<script>
  import axios from 'axios'
  import { toast } from 'bulma-toast'

  export default {
    name: 'Parthners',
    data() {
      return {
        page: 1,
        total: 1,
        parthnersList: [],
      }
    },
    mounted() {
      this.getPartners();
    },
    methods: {
        async getPartners() {

            await axios
                .get(`/api/v1/partners/main/?limit=6`)
                .then(response => {
                    this.parthnersList = response.data.results
                    console.log(response.data)
                })
                .catch(error => {
                    console.log(error)

                    toast({
                        message: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз.',
                        type: 'is-danger',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })
                })

        },      
    }
  }
</script>