<template>

  <div class="container paddingcontainernew">
      <div class="footer-menu--block" style="padding-bottom: 50px !important;">
        <h3 class="contact">Региональные отделения</h3>
      </div>

      <div class="row regionmb">
        <div v-for="(list, index) in regions" class="col-lg-3 col-sm-6 mb-6 pb-5" :class="{'nonemobile': index > 2}">
          <div class="d-in--regions">
            <div class="position-relative sm-w-h-regions">
              <router-link :to="'/region/' + list.id">
                <img :src="list.get_image" class="img-fluid">
              </router-link>
            </div>
            <router-link class="list_name-regions" :to="'/region/' + list.id">{{ list.name }}</router-link>
          </div>
        </div>
      </div>

  </div>

</template>

<style type="text/css">
  .sm-w-h-regions {
    max-height: 50px;
    max-width: 50px;
    margin-right: 20px;
  }
  .list_name-regions {
    font-size: 16px;
    font-weight: 600;
    color: #111;
    line-height: 50px;
    margin: 0;
  }
  .d-in--regions {
    display: inline-flex;
  }
</style>

<script>
  import axios from 'axios'
  import { toast } from 'bulma-toast'

  export default {
    name: 'RegionsListMain',
    data() {
      return {
        page: 1,
        total: 1,
        regions: [],
        regions_listing: [],
      }
    },
    mounted() {
      // this.GetRegionIndex()
      this.GetRegionsList()
    },
    methods: {
          async GetRegionIndex() {

            await axios
            .get(`/api/v1/regions_index/`)
            .then(response => {
              for (let q = 0; q < response.data.length; q++) {
                    const item = response.data[q]
                    this.regions.push(item)
              }   

            })
            .catch(error => {
              console.log(error)
            })

         }, 
         async GetRegionsList() {

            await axios
            .get(`/api/v1/regions_index/`)
            .then(response => {
                this.total = response.data.count
                this.regions_listing = response.data.results

                for (let q = 0; q < this.regions_listing.length; q++) {
                      const item = this.regions_listing[q]
                      this.regions.push(item)
                }   

            })
            .catch(error => {
              console.log(error)
            })

        },      
    }
  }
</script>