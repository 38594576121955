import { createStore } from 'vuex'

export default createStore({
  state: {
    cart: {
        items: [],
    },
    isAuthenticated: false,
    token: '',
    user_id: '',
    sub_id: '',
    id_pay: '',
    user: {},
    status_pay: false,
    isLoading: false
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('cart')) {
        state.cart = JSON.parse(localStorage.getItem('cart'))
      } else {
        localStorage.setItem('cart', JSON.stringify(state.cart))
      }

      if (localStorage.getItem('token')) {
          state.token = localStorage.getItem('token')
          state.isAuthenticated = true
      } else {
          state.token = ''
          state.isAuthenticated = false
      }

      if (localStorage.getItem('user_id')) {
        state.user_id = localStorage.getItem('user_id')
      } else {
        state.user_id = ''      
      }

      if (localStorage.getItem('user')) {
        state.user = JSON.parse(localStorage.getItem('user'))
      } else {
        localStorage.setItem('user', JSON.stringify(state.user))
      }

      if (localStorage.getItem('sub_id')) {
        state.sub_id = localStorage.getItem('sub_id')
      } else {
        state.sub_id = ''
      }

      if (localStorage.getItem('id_pay')) {
        state.id_pay = localStorage.getItem('id_pay')
      } else {
        state.id_pay = ''
      }

      if (localStorage.getItem('status_pay')) {
        state.status_pay = localStorage.getItem('status_pay')
      } else {
        state.status_pay = false
      }
    },
    addToCart(state, item) {
      const exists = state.cart.items.filter(i => i.product.id === item.product.id)
      if (exists.length) {
        exists[0].quantity = parseInt(exists[0].quantity) + parseInt(item.quantity)
      } else {
        state.cart.items.push(item)
      }

      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
    setIsLoading(state, status) {
      state.isLoading = status
    },
    removeSub(state) {
        state.sub_id = ""
    },  
    removeUser(state) {
        state.user_id = ""
    },  
    removePayID(state) {
        state.id_pay = ""
    },
    removePayStatus(state) {
        state.status_pay = false
    },
    removeUserInfo(state) {
        state.user = {}
    },
    setUserInfo(state, user) {
        state.user = user
        localStorage.setItem('user', JSON.stringify(state.user))
    },
    setUser(state, user_id) {
        state.user_id = user_id
    },  
    setSub(state, sub_id) {
        state.sub_id = sub_id
    },  
    setPayID(state, id_pay) {
        state.id_pay = id_pay
    },  
    setPayStatus(state, status_pay) {
        state.status_pay = status_pay
    },
    setToken(state, token) {
        state.token = token
        state.isAuthenticated = true
    },  
    removeToken(state) {
        state.token = ''
        state.isAuthenticated = false
    },
    clearCart(state) {
      state.cart = { items: [] }

      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
  },
  actions: {
  },
  modules: {
  }
})
