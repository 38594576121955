<template>

<Header />

    <div class="container paddingcontainernew">
        <div class="cart--container">
            <div class="d-flex justify-content-between mbp-cart">
                <h5 class="product--cart-title">Товары</h5>
                <router-link class="all--product-link" to="/cart">
                    Назад
                </router-link>
            </div>

            <div class="row">
                <div class="col-lg-8">
                    <div class="row"
                    v-for="(item, index) in cart.items.slice().reverse()"
                    v-bind:key="item.product.id"
                    >
                    <div class="col-lg-3">
                        <div class="cart--item-img">
                         <router-link :to="item.product.get_absolute_url"><img class="img-fluid" :src="item.product.get_image"></router-link>

                     </div>

                 </div>
                 <div class="col-lg-9">
                    <div class="verical--center-text">
                        <div class="d-flex justify-content-between">
                            <div class="ml-4">
                              <router-link :to="item.product.get_absolute_url" class="name--cart-item">{{ item.product.name }}</router-link>
                              <h4 v-if="item.product.discount" class="price--cart-item color-discount">{{ item.product.discount }} Руб.</h4>
                              <h4 v-else class="price--cart-item">{{ item.product.price }} Руб.</h4>
                          </div>
                          <div class="mr-4">
                            <div style="margin-bottom: 15px;">
                                <h4 class="itog--price-item">{{ getItemTotal(item).toFixed(2) }} Руб.</h4>
                            </div> 
                        </div>                    
                    </div>

                </div>                
            </div>
            <div class="line-cart--items" v-if="index + 1 != cart.items.length"></div>
            <div class="line-cart--items h0--line" v-else></div>
        </div>

    </div>
    <div class="col-lg-4">
        <div class="cart--itog-box">
            <div class="column is-12 cart--box">

                <div class="w-100 d-flex justify-content-between pb-4 mb-4 border-bottom">
                    <h4 class="cart--total--amount">
                        Товаров:
                    </h4> 

                    <h4 class="cart--total--amount">
                        {{ cartTotalLength }} Шт.
                    </h4>    
                </div>

                <div class="w-100 d-flex justify-content-between">
                    <h4 class="cart--total--price">
                        Итого:
                    </h4> 

                    <h4 class="cart--total--price">
                        {{ cartTotalPrice.toFixed(2) }} Руб.
                    </h4>    
                </div>


            </div>                        

        </div>
        <div>
            <div class="cart--etap cart--box p-5">

                <h1 class="itap">2 ЭТАП</h1>

                <h3 class="dostavka_h3">Заполните данные</h3>

                <p class="help_text mb-5">* Все поля обязательны для заполнения</p>

                    <div class="row">
                        <div class="col-lg-12" v-if="user_me.name == '' ">
                            <div class="field">
                                <label 
                                    class="label--checkout"
                                    :class="{'valid--checkout': CheckName}"
                                >Имя</label>
                                <input 
                                    type="text" class="input--checkout" 
                                    v-model="first_name"
                                >
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="user_me.surname == '' ">

                            <div class="field">
                                <label class="label--checkout" :class="{'valid--checkout': CheckSurname}">Фамилия</label>
                                <input type="text" class="input--checkout" v-model="last_name">
                            </div>

                        </div>

                        <div class="col-lg-12" v-if="user_me.email == '' ">
                            <div class="field">
                                <label class="label--checkout" :class="{'valid--checkout': CheckEmail}">E-mail</label>
                                <input type="email" class="input--checkout" v-model="email">
                            </div>
                        </div>
                        

                        <div class="col-lg-12">
                            <div class="field">
                                <label class="label--checkout" :class="{'valid--checkout': CheckPhone}">Телефон</label>
                                <input type="text" class="input--checkout" v-model="phone">
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="user_me.adress == '' ">
                            <div class="field">
                                <label class="label--checkout" :class="{'valid--checkout': CheckAddress}">Адрес</label>
                                <input type="text" class="input--checkout" v-model="address">
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="user_me.city == '' ">
                            <div class="field">
                                <label class="label--checkout" :class="{'valid--checkout': CheckPlace}">Город</label>
                                <input type="text" class="input--checkout" v-model="place">
                            </div>
                        </div>

                        <input type="hidden" class="input" v-model="dostavka">
                    </div>

                    <div class="notification is-danger mt-4" v-if="errors.length">
                        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                    </div>

                    <div class="line-cart--items" style="margin-top: 0.25rem !important;"></div>

                    <template v-if="cartTotalLength">
                        <h2 class="itap mb-4">3 ЭТАП</h2>

                        <p class="help_text mb-3">Доставка до вашего адресса будет стоить 300 рублей</p>

                        <button class="cart--button" @click="submitForm">Оплатить</button>
                    </template>
                    <template v-else>
                        <p style="color: red; font-weight: 600;">Для начала положите товары, в корзину</p>
                    </template>

            </div>        




        </div>

    </div>
</div>


</div>

</div>

<Footer />
</template>
<script>
    import axios from 'axios'
    import Header from '@/components/Header/Header'
    import Footer from '@/components/Footer/Footer'

    export default {
        name: 'Checkout',
        data() {
            return {
                cart: {
                    items: []
                },
                user_me: {},
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                address: '',
                place: '',
                dostavka: 'Доставка',
                errors: [],
                number: 1,
                user_id: "",
                product_name: 0,
                product_price: 0,
                product_quantity: 0,
                getitog: 0,
                price_all: 0,
                dostavka_price: 0               
            }
        },
        mounted() {
            document.title = 'Оформление заказа'

            this.cart = this.$store.state.cart

            this.user_id = this.$store.state.user_id

            this.getMyAccount();
        },
        components: {
            Header,
            Footer            
        },
        methods: {
            getItemTotal(item) {
                return item.quantity * item.product.price
            },
            async getMyAccount() {
                this.$store.commit('initializeStore')

                this.$store.commit('setIsLoading', true)

                await axios
                    .get(`/api/v1/me/${this.user_id}/`)
                    .then(response => {
                        this.user_me = response.data
                        this.first_name = response.data.name
                        this.last_name = response.data.surname
                        this.email = response.data.email
                        this.address = response.data.adress
                        this.place = response.data.city
                    })
                    .catch(error => {
                        console.log(error)
                    })

                this.$store.commit('setIsLoading', false)
            },   
            async submitForm() {
                this.errors = []

                if (this.first_name === '') {
                    this.errors.push('Имя не заполнено')
                }

                if (this.last_name === '') {
                    this.errors.push('Фамилия не заполнена')
                }

                if (this.email === '') {
                    this.errors.push('E-mail не заполнен')
                }

                if (this.phone === '') {
                    this.errors.push('Телефон не заполнен')
                }

                if (this.address === '') {
                    this.errors.push('Адрес не заполнен')
                }

                if (this.place === '') {
                    this.errors.push('Город не заполнел')
                }

                const itemss = []

                for (let i = 0; i < this.cart.items.length; i++) {
                    const item = this.cart.items[i]
                    const obj = {
                        product: item.product.id,
                        quantity: item.quantity,
                        price: item.product.price * item.quantity
                    }

                    itemss.push(obj)
                }

                const data = {
                    'first_name': this.first_name,
                    'last_name': this.last_name,
                    'email': this.email,
                    'address': this.address,
                    'place': this.place,
                    'phone': this.phone,
                    'dostavka': this.dostavka,
                    'items': itemss
                }

                await axios
                .post('/api/v1/checkout/', data)
                .then(response => {
                    this.number = response.data.id
                    this.$store.commit('clearCart')
                    window.location.href = response.data.confirm_link
                })
                .catch(error => {
                    this.errors.push('Что то пошло не так...')

                    console.log(error)
                })

                const products = []

                for (let j = 0; j < this.cart.items.length; j++) {
                    const item = this.cart.items[j]           
                    const objecct = {
                        name: item.product.name,
                        quantity: item.quantity,
                        price: item.product.price,
                        getitog: item.product.price * item.quantity
                    }
                    this.price_all += item.product.price * item.quantity

                    products.push(objecct)     
                }

                if (this.dostavka === 'Самовывоз') {
                    this.dostavka_price = 0
                } else {
                    this.dostavka_price = 350
                }

                const data2 = {
                    'number': this.number,
                    'products': products,
                    'price_all': this.price_all,
                    'dostavka_price': this.dostavka_price,
                    'dostavka': this.dostavka,
                    'first_name': this.first_name,
                    'last_name': this.last_name,
                    'phone': this.phone,
                    'email': this.email,
                    'address': this.address,
                    'place': this.place
                }

                await axios
                .post('/api/v1/send_email/', data2)
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })

                this.$store.commit('setIsLoading', false)    
            }
    },
    computed: {
        cartTotalPrice() {
            return this.cart.items.reduce((acc, curVal) => {
                if (curVal.product.discount) {
                    return acc += curVal.product.discount * curVal.quantity
                } else {
                    return acc += curVal.product.price * curVal.quantity
                }
            }, 0)
        },      
        cartTotalLength() {
            return this.cart.items.reduce((acc, curVal) => {
                return acc += curVal.quantity
            }, 0)
        },
        CheckName() {
            if(this.first_name) {
              return true;
            } else {
              return false;
            }
        },
        CheckSurname() {
            if(this.last_name) {
              return true;
            } else {
              return false;
            }
        },
        CheckEmail() {
            if(this.email) {
              return true;
            } else {
              return false;
            }
        },
        CheckPhone() {
            if(this.phone) {
              return true;
            } else {
              return false;
            }
        },
        CheckAddress() {
            if(this.address) {
              return true;
            } else {
              return false;
            }
        },
        CheckPlace() {
            if(this.place) {
              return true;
            } else {
              return false;
            }
        },        
    }
}
</script>