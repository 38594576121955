<template>

  <HeaderFsop />
<!-- 

      <div class="container paddingcontainernew">
          <div class="footer-menu--block" style="padding-bottom: 30px !important;">
              <h3 class="contact">О Спортобществе «Россия»</h3>
          </div>
      </div>

      <div class="position-relative max-h--aboutfsop mb-5">
        <img src="@/assets/aboutfsop.png" class="img-fluid w-100">
        <div class="back--aboutfsop-img"></div>
        <div class="text-absolute-fsop paddingcontainernew">
          <h4 class="fsop---text-abs container">
            ФИЗКУЛЬТУРНО-СПОРТИВНОЕ ОБЩЕСТВО <br>
            ПРОФСОЮЗОВ «РОССИЯ»
          </h4>
        </div>
        
      </div> -->

      <div class="container paddingcontainernew">

        <div class="block--fsop-team">
          <div class="footer-menu--block">
              <h3 class="contact">Команда</h3>
          </div>

          <div class="row marginb-6">
            
            <div class="col-lg-6 aboutmargin-bottomteam">
              <router-link :to="'/team/' + team_first.id">
                <div class="position-relative" style="overflow: hidden !important; max-width: 570px;">
                  <img :src="team_first.get_image" class="img-fluid hover-scale">
                </div>
              </router-link>
            </div>
            

            <div class="col-lg-6">
              <div class="px-4">
                <h3 class="main-team-h3">{{ team_first.name }}</h3>
                <h4 class="main-team-h4" v-if="team_first.work">{{ team_first.work.name }}</h4>
                <p class="text--fluid--teammain" v-html="team_first.description"></p>
              </div>
            </div>
          </div> 

          <div class="row marginb-5">
            <div class="col-lg-4 aboutmargin-bottomteam" v-for="(item, index) in newteam" v-show="index > 0">
              <router-link :to="'/team/' + item.id">
                <div class="position-relative max-h--teamfsopabout">
                  <img :src="item.get_image" :alt="item.name" class="img-fluid hover-scale" style="width: 100%;">
                </div>
                
                <h4 class="team--h4-fsopabout" v-if="item.work">{{ item.work.name }}</h4>
                <p class="team--p-fsopabout">{{ item.name }}</p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
  <FooterFsop />

</template>

<style type="text/css">
/*  .img-fluid {
    width: auto;
  }*/
  .tabs--fsopabout {
    display: flex;
    justify-content: space-between;
    max-width: 400px;
  }

  .max-h--teamfsopabout {
    max-height: 330px;
    overflow: hidden;
    margin-bottom: 20px;
  }

  .tabs--fsop--text {
    font-size: 17px;
    font-weight: 600;
  }

  .team--h4-fsopabout {
    font-weight: 600;
    color: #111;
    margin-bottom: 15px;
  }

  .team--p-fsopabout {
    font-weight: 500;
    color: #111;    
  }

  .noneactive--tabs {
    color: #8B8B8B;
  }

  .main-team-h3 {
    color: #111;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .main-team-h4 {
    color: #111;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 30px;
  }

  .text--fluid--teammain {
    font-weight: 500;
    color: #111;
    margin-bottom: 20px;
  }

  .block--p--aboutfsop {
    font-weight: 500;
    color: #111;
  }

  .active--tabs-fsop {
    color: #333333;
  }

  .back--aboutfsop-img {
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 10;
    height: 100%;
    background: rgba(214, 55, 39, 0.6);
  }

  .text-absolute-fsop {
    position: absolute;
    z-index: 999;
    left: 50px;
    bottom: 50%;
    width: 100%;
    padding-left: 25px !important;
  }

  .block--fsop-team {

  }

  .fsop---text-abs {
    color: #fff;
    line-height: 24px;
    font-weight: 700;
  }

  .max-h--aboutfsop {
    max-height: 400px;
    overflow: hidden;
  }

  .fsop--textss {
    color: #D63727;
    font-size: 18px;
    text-align: center;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 30px;
  }


</style>

<script>
  import HeaderFsop from '@/components/Header/HeaderFsop'
  import FooterFsop from '@/components/Footer/FooterFsop'  
  import axios from 'axios'
  import { toast } from 'bulma-toast'

  export default {
    name: 'Team',
    data() {
      return {
        page: 1,
        total: 1,
        news: [],
        blockactive: true,
        team_first: {},
        teams: [],
        newteam: [],
        jobs_team: [],
        documents: [],
        id_button: "",
        name__tags: "О нас",
        }
    },
    components: {
      HeaderFsop,
      FooterFsop
    },
    mounted() {
      this.GetJobs();
      this.GetDocuments()
    },
    methods: {
        GetNewsBlock(index, name) {
          this.id_button = index;
          this.name__tags = name;
        },      
        async GetJobs() {

          await axios
          .get('/api/v1/team/')
          .then(response => {
            this.newteam = response.data.results
            console.log(response.data.results)

            // for (let j = 0; j < this.jobs_team.length; j++) {
            //     const item = this.jobs_team[j]
            //     console.log(item.job.length)
            //     for (let i = 0; i < item.job.length; i++) {
            //       console.log()
            //       const massive = {
            //         id: item.job[i].id,
            //         index: item.job[i].index,
            //         job_name: item.name,
            //         name: item.job[i].name,
            //         image: item.job[i].get_image,
            //         description: item.job[i].description
            //       }

            //       if (item.job[i].index == 0) {
            //           this.newteam.push(massive)
            //       } else {
            //           this.newteam.splice(item.job[i].index, 0, massive)
            //       }

            //       console.log(this.newteam)
                  
            //     }
            // }
            this.team_first = this.newteam[0]
          })
          .catch(error => {
            console.log(error)
          })

        },
        async GetDocuments() {

          await axios
          .get('/api/v1/get/documents/')
          .then(response => {
            this.documents = response.data
            console.log(response.data)
          })
          .catch(error => {
            console.log(error)
          })

        },

    }
  }
</script>