<template>
   <div class="row">
      <div class="col-lg-3">
         <div class="cart--item-img">
            <router-link :to="item.product.get_absolute_url"><img class="img-fluid"
                  :src="item.product.get_image"></router-link>

         </div>

      </div>
      <div class="col-lg-9">
         <div class="verical--center-text">
            <div class="d-flex justify-content-between">
               <div class="ml-4">
                  <router-link :to="item.product.get_absolute_url" class="name--cart-item">{{ item.product.name
                  }}</router-link>
                  <h4 v-if="item.product.discount" class="price--cart-item color-discount">{{ item.product.discount }} Руб.
                  </h4>
                  <h4 v-else class="price--cart-item">{{ item.product.price }} Руб.</h4>
                  <a class="delete--from-cart" @click="removeFromCart(item)">Убрать</a>
               </div>
               <div class="mr-4">
                  <div style="margin-bottom: 15px;">
                     <h4 class="itog--price-item">{{ getItemTotal(item).toFixed(2) }} Руб.</h4>
                  </div>
                  <div class="d-flex" style="max-height: 35px;">
                     <a class="quantity-cart--ins" @click="decrementQuantity(item)">-</a>
                     <h4 class="cart--item-ins">{{ item.quantity }}</h4>
                     <a class="quantity-cart--ins" @click="incrementQuantity(item)"
                        v-if="item.product.amount > item.quantity">+</a>
                     <a class="quantity-cart--ins red--error" disabled="disabled" v-else>+</a>
                  </div>
               </div>
            </div>

         </div>
      </div>
   </div>
   <div class="line-cart--items"></div>
   <!--     <tr>
        <td></td>
        <td v-if="item.product.discount" style="font-weight: 500;">
            <span style="color: red !important;">{{ item.product.discount }}₽ (Со скидкой)</span>
        </td>
        <td v-else style="font-weight: 500;">
            {{ item.product.price }}₽
        </td>
        <td style="font-weight: 500;">
            <a class="mr-1" @click="decrementQuantity(item)">-</a>
            {{ item.quantity }}
            <a class="mr-1" @click="incrementQuantity(item)" v-if="item.product.amount > item.quantity">+</a>
        </td>
        <td style="font-weight: 700;">{{ getItemTotal(item).toFixed(2) }}₽</td>
        <td><button class="delete" @click="removeFromCart(item)"></button></td>
    </tr> -->
</template>

<style scoped lang="scss">
.cart--item-img {
   a {
      display: flex;
      justify-content: center;
   }
}
</style>

<script>
export default {
   name: 'CartItem',
   props: {
      initialItem: Object
   },
   data() {
      return {
         item: this.initialItem
      }
   },
   methods: {
      getItemTotal(item) {
         if (item.product.discount) {
            return item.quantity * item.product.discount
         } else {
            return item.quantity * item.product.price
         }
      },
      decrementQuantity(item) {
         item.quantity -= 1

         if (item.quantity === 0) {
            this.$emit('removeFromCart', item)
         }

         this.updateCart()
      },
      incrementQuantity(item) {
         item.quantity += 1

         this.updateCart()

         if (item.quantity == this.item.product.amount) {
            alert('"' + this.item.product.name + '"' + " " + "больше нет в наличии.")
         }

      },
      updateCart() {
         localStorage.setItem('cart', JSON.stringify(this.$store.state.cart))
      },
      removeFromCart(item) {
         this.$emit('removeFromCart', item)

         this.updateCart()
      },
   },
}
</script>