<template>
    <div class="full--auth--back">
        <HeaderSmall />
        <div class="position--auth-form">
            <div class="max--form" v-if="nextTo == 0">
                <h5 class="auth--title">Войти в аккаунт</h5>
                <form @submit.prevent="submitPhone">
                    <div class="field--auth">
                        <input 
                            v-model="phone"
                            class="input--auth"
                            type="tel" 
                            v-mask="'+# (###) ###-##-##'"
                        >
                        <label 
                            class="auth--label" 
                            :class="{'valid--auth': vphone}"
                        >
                            Телефон
                        </label>
                    </div>
                    <div class="mb-5" v-if="exist && phone.length == 18">
                        <button class="button--auth">Продолжить</button>
                    </div>
                    <div class="mb-5" v-else>
                        <button class="button--auth backTo" style="width: 100% !important;" disabled>Продолжить</button>
                    </div>
                    <span class="auth--text">Еще нет аккаунта? 
                        <router-link class="auth--text--1" to="/sign-up">Создать</router-link> 
                    </span>
                </form> 
            </div>

            <div v-if="nextTo == 1" 
                class="modal-reg modal-reg__active" 
                :class="{'modal-reg__wait_wrapper': loading, 'modal-reg__error': error_code}">
                <div class="modal-reg__wrapper">
                    <h5 class="auth--title mbNs">Подтвердите номер телефона</h5>
                    <div class="mobile__reg">
                        <p>Для подтверждения номера телефона вам необходимо позвонить на этот системный номер <br> <a :href="'tel: +' + callPhone">+{{ callPhone }}</a>
                        </p>
    <!--                     <form class="modal-reg__input">
                            <input ref="code_1" v-model="code_1" type="number" v-mask="'#'" maxlength="1" placeholder="__">
                            <input ref="code_2" v-model="code_2" type="number" v-mask="'#'" maxlength="1" placeholder="__">
                            <input ref="code_3" v-model="code_3" type="number" v-mask="'#'" maxlength="1" placeholder="__">
                            <input ref="code_4" v-model="code_4" type="number" v-mask="'#'" maxlength="1" placeholder="__">
                        </form>
                        <p class="modal-reg__text_error">неверный код</p>
                        <div class="modal-reg__wait">
                            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                        </div> -->
                    </div>
                    <div class="center-flex__block">
                        <button class="button--auth backTo" @click="nextTo = 0">Назад</button>
                    </div>
                </div>
            </div>
        </div>
        <Footersmall />
    </div>
</template>

<script>
    import axios from 'axios'
    import { mask } from 'vue-the-mask'
    import { toast } from 'bulma-toast'
    import HeaderSmall from '@/components/Header/HeaderSmall'
    import Footersmall from '@/components/Footer/Footersmall'

    export default {
        name: 'LogIn',
        directives: { mask },
        data() {
            return {
                username: '',
                password: 'DQawE|2Q',
                type: "password",
                user_id: "",
                errors: [],
                all_users: [],
                color: '#fff',
                status_payment: null,
                status: [],
                user_account: {},

                phone: "",
                callPhone: "",

                nextTo: 0,
                inInterval: null,
                exist: false,
            }
        },
        components: {
            HeaderSmall,
            Footersmall
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {
            document.title = 'Войти'
        },
        methods: {
            errorAlert() {
                for (let i = 0; i < this.errors.length; i++) {
                    const message_error = this.errors[i]
                    toast({
                        message: message_error,
                        type: 'is-danger',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 10000,
                        position: 'bottom-right',
                    })
                }
            },
            async paymentStatus(sub) {
                await axios
                    .get(`/api/v1/paymentStatus/${sub}/`)
                    .then(response => {
                        this.status = response.data

                        for (let i = 0; i < this.status.length; i++) {
                            const item = this.status[i]

                            if (item[1] == 'succeeded') {
                                this.status_payment = true
                            }
                        }

                    })
                    .catch(error => {
                        console.log(error)
                    })                
            },
            alertWarning() {
                toast({
                    message: 'Пожалуйста подождите....',
                    type: 'is-warning',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 1000,
                    position: 'bottom-right',
                })
            },
            StatusSuccess() {
                if (this.status_payment == true) {
                    this.$store.commit('setPayStatus', this.status_payment)

                    localStorage.setItem("status_pay", this.status_payment)
                }
            },
            async submitForm() {
                axios.defaults.headers.common["Authorization"] = ""

                localStorage.removeItem("token")

                const formData = {
                    username: this.phone.replace(/[^0-9]/g, ''),
                    password: this.password,
                }

                await axios
                    .get(`/api/v1/me/check/${this.phone.replace(/[^0-9]/g, '')}/`)
                    .then(response => {
                        this.user_account = response.data
                        console.log(response.data)
                    })
                    .catch(error => {
                        console.log(error)
                    })

                await axios
                .post("/api/v1/token/login/", formData)
                .then(response => {
                    const token = response.data.auth_token

                    this.$store.commit('setToken', token)
                    
                    axios.defaults.headers.common["Authorization"] = "Token " + token

                    localStorage.setItem("token", token)

                    this.$store.commit('setUser', this.user_account.id)

                    localStorage.setItem("user_id", this.user_account.id)

                    localStorage.setItem("user", this.user_account)

                    this.$store.commit('setUserInfo', this.user_account)

                    let sub_id = this.$store.state.user.sub;

                    if (sub_id) {
                        this.paymentStatus(sub_id)
                        this.alertWarning()
                        setTimeout(() => {
                            this.StatusSuccess()
                            this.$router.push('/my-account')
                        }, 1000)
                        
                    } else {
                        this.$router.push('/')
                    }
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${error.response.data[property]}`)
                        }
                        this.errorAlert();
                    } else {
                        this.errors.push('Что-то пошло не так...')

                        this.errorAlert();
                        
                        console.log(JSON.stringify(error))
                    }
                })
            },
            async checkPhone(key) {
                await axios
                .get(`/api/v1/phone/status-check/${key}/`)
                .then(response => { 
                    console.log(response.data) 
                    if (String(response.data.status) == "success") {
                        clearInterval(this.inInterval)
                        this.submitForm();
                    }
                })
                .catch(error => { console.log(error) })
            },
            async submitPhone() {
                const formData = {
                    phone: this.phone,
                }

                await axios
                .post("/api/v1/phone/confirm/", formData)
                .then(response => { 
                    console.log(response.data) 
                    this.nextTo = 1
                    this.callPhone = response.data.data.phone
                    this.inInterval = setInterval(() => { this.checkPhone(response.data.data.key)  }, 2000)
                })
                .catch(error => { console.log(error) })
            },
            async checkUser(value) {
                await axios
                    .get(`/api/v1/me/check/${value.replace(/[^0-9]/g, '')}/`)
                    .then(response => {
                        this.exist = true
                    })
                    .catch(error => {
                        this.exist = false
                        toast({
                            message: 'Ваша учетная запись не найдена. Вам нужно зарегистрироваться',
                            type: 'is-danger',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 10000,
                            position: 'bottom-right',
                        })
                        console.log(error)
                    })
            },
        },
        computed: {
          vphone() {
            return this.phone.length > 0 ? true : false
          },      
          validate_username() {
            if(this.username.length > 0) {
              return true;
            } else {
              return false;
            }
          },
          validate_password() {
            if(this.password.length > 0) {
              return true;
            } else {
              return false;
            }
          }          
        },
        watch: {
            phone(value) {
                if (value.length == 18) this.checkUser(value)
            },
        },
    }
</script>