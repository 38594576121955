<template>
   <div :class="{ 'blue-back': blue }">


      <div class="container paddingcontainernew block--last-footer" v-if="search_on">
         <div class="search--block--main margin-bottom-last">
            <form class="form--search-flex" method="get" action="/search_fsop">
               <div class="search--block">
                  <div class="search--label--icon" v-if="validate_query">
                     <span class="icon--search">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17.483" height="21.291" viewBox="0 0 17.483 21.291">
                           <g id="Сгруппировать_1151" data-name="Сгруппировать 1151" transform="translate(-434 -2857.354)">
                              <g id="Сгруппировать_799" data-name="Сгруппировать 799"
                                 transform="translate(1297 -6544.646)">
                                 <g id="Сгруппировать_798" data-name="Сгруппировать 798">
                                    <g id="Эллипс_77" data-name="Эллипс 77" transform="translate(-863 9402)" fill="none"
                                       stroke="#ccc" stroke-width="1">
                                       <circle cx="8" cy="8" r="8" stroke="none" />
                                       <circle cx="8" cy="8" r="7.5" fill="none" />
                                    </g>
                                    <g id="Прямоугольник_182" data-name="Прямоугольник 182"
                                       transform="translate(-850.659 9416.52) rotate(50)" fill="#ccc" stroke="#ccc"
                                       stroke-width="1">
                                       <rect width="8" height="1" stroke="none" />
                                       <rect x="0.5" y="0.5" width="7" fill="none" />
                                    </g>
                                 </g>
                              </g>
                           </g>
                        </svg>
                     </span>
                     <label class="search--label">Найти новость</label>
                  </div>

                  <input class="search--input" v-model="query" type="text" name="query">
               </div>

               <button class="search--button-find">Найти</button>
            </form>
         </div>
         <!--   <div v-if="!$store.state.isAuthenticated">
    <h4 class="log-in-reg-free" :class="{'fff-color': blue}">
      ЗАРЕГИСТРИРУЙСЯ И БЕСПЛАТНО<br>
      УЧАСТВУЙ В СПОРТИВНЫХ СОРЕВНОВАНИЯХ          
    </h4>
    <div class="divHomeCenter">
      <router-link to="/log-in">
        <div class="btnHome">
          Создать аккаунт
        </div>
      </router-link>          
    </div>
  </div> -->
      </div>

      <div v-if="showmodal" class="modal-footer--open">
         <div class="modal-wrapper">

            <a @click="showmodal = false" class="close--modal--footer"></a>

            <div class="modal-container--footer">
               <div class="modal-body--foter">
                  <div class="row margin-bottom--footer-modal">
                     <h4 class="footer--h4">Заполните данные</h4>
                     <div class="col-lg-12">
                        <div class="field mb-0">
                           <label v-show="focused" class="label--checkout">E-mail</label>
                           <input type="text" class="input--checkout" v-model="email" @focus="focused = false"
                              style="border-radius: 4px !important;">
                        </div>
                     </div>
                  </div>
<!--                   <div class="d-flex margin-bottom--footer-modal">
                     <input class="checkbox--info" type="checkbox" value="1" v-model="info">
                     <p class="footer--p">Нажимая на галочку вы подверждаете согласие на обработку
                        <router-link class="modal-footer--link" to="/personal_info">персональных данных</router-link>
                     </p>
                  </div> -->
                  <button v-if="info == 1 && email.length > 0" class="detail--button" @click="sendForm">
                     Готово
                  </button>
                  <button v-else disabled="disabled" class="detail--button disablebutton">Готово</button>
               </div>
            </div>
         </div>
      </div>

      <footer class="bg-footer">
         <div class="container paddingcontainernew">
            <div class="d-flex center-mobile-576 mb30 wrap">
               <div class="modalbtns">
                  <button class="btn-modal-send" @click="showmodal = true">
                     Подпишитесь на рассылку
                  </button>
               </div>
               <div class="brands">
                  <a aria-label="Вконтакте" rel="noopener" target="_blank" href="https://vk.com/fsop_russia"
                     class="btn_brands mr-lg-2 mr-4">
                     <font-awesome-icon class="iconsize" :icon="['fab', 'vk']" />
                  </a>
                  <a aria-label="Одноклассники" rel="noopener" target="_blank" href="https://ok.ru/group/59594535665747"
                     class="btn_brands mr-lg-2 mr-4">
                     <font-awesome-icon class="iconsize" :icon="['fab', 'odnoklassniki']" />
                  </a>
                  <a aria-label="Youtube" rel="noopener" target="_blank"
                     href="https://www.youtube.com/channel/UCgZtWt_iwEBcQ_l8ThS8sCw" class="btn_brands">
                     <font-awesome-icon class="iconsize" :icon="['fab', 'youtube']" />
                  </a>
               </div>

            </div>
            <div class="header-row mb40">
               <div class="d-flex justify-content-between w-100 close-576">

                  <router-link class="rd-nav-link" to="/help">помощь</router-link>

                  <router-link class="rd-nav-link" to="/my-account">мой аккаунт</router-link>

                  <router-link class="rd-nav-link" to="/regions">регионы</router-link>

                  <router-link class="rd-nav-link" to="/activity">деятельность</router-link>

                  <router-link class="rd-nav-link" to="/news">новости</router-link>

                  <router-link class="rd-nav-link" to="/contact">контакты</router-link>

                  <router-link class="rd-nav-link" to="/partners">партнерам</router-link>

                  <!-- <router-link class="rd-nav-link" to="/shop-fsop">интернет-магазин</router-link> -->
               </div>
            </div>
            <div class="footer-menu-sub open-flex-992">
               <a href="" class="rd-nav-sub">
                  @ 2024 Fsop-Russia. All rights reserved.</a>
               <a href="" class="studio_by_dmitriy_markin">Разработанно студией Дмитрия Маркина</a>
            </div>
            <div class="footer-menu-sub close-flex-992 mb40">
               <a href="" class="rd-nav-sub">@ 2024 Fsop-Russia. All rights reserved.</a>
               <!-- <a href="" class="rd-nav-sub">Пользовательское соглашение</a> -->
               <a href="mailto:info@fsop-russia.ru" class="rd-nav-sub">info@fsop-russia.ru</a>
               <a href="" class="studio_by_dmitriy_markin">Разработанно студией Дмитрия Маркина</a>
            </div>
            <div class="footer-menu-sub close-flex-992">
               <p class="rd-nav-sub" style="color: #a0a0a0 !important;">Информация о товарном знаке: "Свидетельство №
                  862692 (Заявка №2021774935, зарегистрировано в Государственном реестре товарных знаков и знаков
                  обслуживания Российской Федерации 06 апреля 2022 г., приоритет товарного знака 16 ноября 2021 г., срок
                  действия регистрации истекает 16 ноября 2031г.)"</p>
            </div>

         </div>
      </footer>

   </div>
</template>

<style type="text/css"></style>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
export default {
   name: 'FooterFsop',
   data() {
      return {
         showmodal: false,
         email: "",
         focused: true,
         info: 0,
         query: "",
         validate_query: true,
      }
   },
   props: {
      blue: {
         type: Boolean,
         default: false
      },
      search_on: {
         type: Boolean,
         default: true
      },
   },
   mounted() {

   },
   methods: {
       async sendForm() {
         const data = {
             email: this.email,
         }

         await axios
            .post('/api/v1/save/email-sub/', data)
            .then(response => {
               toast({
                  message: 'Подписка успешно оформлена',
                  type: 'is-success',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 2000,
                  position: 'bottom-right',
               })

               console.log(response)
               this.showmodal = false;
            })
            .catch(error => { console.log(error) })
      },
   },
   computed: {

   },
   watch: {
      query(value) {
         this.query = value
         if (this.query) {
            this.validate_query = false
         } else {
            this.validate_query = true
         }
      },
   },
}
</script>