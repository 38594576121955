<template>
   <HeaderFsop />
   <div class="home">

      <CarouselFsopMain :baners="bannerss" />

      <div class="new--bottom">
         <NewsList />
      </div>

      <div class="new--bottom">
         <div class="divHomeCenter">
            <router-link to="/news">
               <div class="btnHome">
                  Посмотреть все новости
               </div>
            </router-link>
         </div>
      </div>

      <div class="new--bottom">

         <!-- <HomeFsopEvents /> -->
         <appEvents firstIndex="0" secondIndex="3" />

      </div>

      <div class="py-6">

         <div class="container paddingcontainernew">
            <div class="search--block--main">
               <form class="form--search-flex" method="get" action="/search_fsop">
                  <div class="search--block">
                     <div class="search--label--icon" v-if="validate_query">
                        <span class="icon--search">
                           <svg xmlns="http://www.w3.org/2000/svg" width="17.483" height="21.291"
                              viewBox="0 0 17.483 21.291">
                              <g id="Сгруппировать_1151" data-name="Сгруппировать 1151"
                                 transform="translate(-434 -2857.354)">
                                 <g id="Сгруппировать_799" data-name="Сгруппировать 799"
                                    transform="translate(1297 -6544.646)">
                                    <g id="Сгруппировать_798" data-name="Сгруппировать 798">
                                       <g id="Эллипс_77" data-name="Эллипс 77" transform="translate(-863 9402)" fill="none"
                                          stroke="#ccc" stroke-width="1">
                                          <circle cx="8" cy="8" r="8" stroke="none" />
                                          <circle cx="8" cy="8" r="7.5" fill="none" />
                                       </g>
                                       <g id="Прямоугольник_182" data-name="Прямоугольник 182"
                                          transform="translate(-850.659 9416.52) rotate(50)" fill="#ccc" stroke="#ccc"
                                          stroke-width="1">
                                          <rect width="8" height="1" stroke="none" />
                                          <rect x="0.5" y="0.5" width="7" fill="none" />
                                       </g>
                                    </g>
                                 </g>
                              </g>
                           </svg>
                        </span>
                        <label class="search--label">Найти новость</label>
                     </div>

                     <input class="search--input" v-model="query" type="text" name="query">
                  </div>

                  <button class="search--button-find">Найти</button>
               </form>
            </div>
         </div>

      </div>

      <div class="new--bottom">

         <ParthnersList />

      </div>

      <div class="new--bottom">

         <RegionsListMain />

         <div class="divHomeCenter">
            <router-link to="/regions">
               <div class="btnHome">
                  Посмотреть все отделения
               </div>
            </router-link>
         </div>

      </div>

      <!--         <div class="my-6 py-6" v-if="!$store.state.isAuthenticated">

          <div>
            <h4 class="log-in-reg-free" :class="{'fff-color': blue}">
              ЗАРЕГИСТРИРУЙСЯ И БЕСПЛАТНО<br>
              УЧАСТВУЙ В СПОРТИВНЫХ СОРЕВНОВАНИЯХ          
            </h4>
            <div class="divHomeCenter">
              <router-link to="/log-in">
                <div class="btnHome">
                  Создать аккаунт
                </div>
              </router-link>          
            </div>            
          </div>          

        </div> -->


   </div>
   <FooterFsop :search_on="false" />
</template>

<script>
import axios from 'axios'

import HeaderFsop from '@/components/Header/HeaderFsop'
import FooterFsop from '@/components/Footer/FooterFsop'

import NewsList from '@/components/News/NewsList'
import HomeFsopEvents from '@/components/Events/HomeFsopEvents'
import appEvents from '@/components/Events/AppEvents'
import ParthnersList from '@/components/Parthners/Parthners'
// import Carousel from '@/components/Carousel'
import TheBestProduct from '@/components/TheBestProduct'
import CarouselFsopMain from '@/components/CarouselFsop/CarouselFsopMain'
import ProductListHome from '@/components/ProductListHome'
import RegionsListMain from '@/components/Regions/RegionsListMain'

export default {
   name: 'HomeFsop',
   data() {
      return {
         items: [],
         productsCategory: [],
         bannerss: [],
         page: 1,
         total: 1,
         validate_query: true,
         query: "",
      }
   },
   components: {
      NewsList,
      TheBestProduct,
      CarouselFsopMain,
      ProductListHome,
      HeaderFsop,
      ParthnersList,
      FooterFsop,
      HomeFsopEvents,
      RegionsListMain,
      appEvents
      // Carousel,
   },
   beforeCreate() {
      this.$store.commit('initializeStore')
   },
   mounted() {
      this.getProductsCategory()

      this.getBannerss()

      document.title = 'Общероссийская общественная организации – физкультурно-спортивного общества профсоюзов «Россия»'
   },
   methods: {
      async getProductsCategory() {
         this.$store.commit('setIsLoading', true)

         await axios
            .get('/api/v1/catall/')
            .then(response => {
               this.productsCategory = response.data
            })
            .catch(error => {
               console.log(error)
            })

         this.$store.commit('setIsLoading', false)
      },
      async getBannerss() {
         this.$store.commit('setIsLoading', true)

         await axios
            .get('/api/v1/banners/')
            .then(response => {
               this.bannerss = response.data
            })
            .catch(error => {
               console.log(error)
            })

         this.$store.commit('setIsLoading', false)
      }
   },
   watch: {
      query(value) {
         this.query = value
         if (this.query) {
            this.validate_query = false
         } else {
            this.validate_query = true
         }
      },
   },

}
</script>
