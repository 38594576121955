<template>
   <Header />

   <div class="full--blue">

      <div class="container paddingcontainernew">
         <div class="parners--block">
            <div class="d-flex justify-content-between mb-2">
               <div class="">
                  <h4 class="my-account--name d-flex">
                     Мои мероприятия:
                  </h4>
               </div>
            </div>

         </div>

         <div class="box--blue mb-4 p-lg-4 mb-6" style="overflow: auto !important;">
            <h4 class="is-size-5 pb-3 fff">Вы записаны:</h4>

            <!-- <div v-if="events_info.length > 0">
               <table class="table is-fullwidth">
                  <thead>
                     <tr>
                        <th>Название</th>
                        <th>Дата проведения мероприятия</th>
                     </tr>
                  </thead>

                  <tbody>
                     <tr v-for="item in events_info">
                        <td>{{ item.name }}</td>
                        <td>
                           <span>{{ data_event(item.datetime) }}</span>
                        </td>
                     </tr>
                  </tbody>
               </table>
            </div> -->
            <div v-if="events.length">
               <table class="table is-fullwidth">
                  <thead>
                     <tr>
                        <th class="city">Город</th>
                        <th class="sport">Вид спорта</th>
                        <th class="name-table">Название</th>
                        <th class="date">Дата проведения мероприятия</th>
                     </tr>
                  </thead>

                  <tbody>
                     <tr v-for="event in events" @click="OpenModal(event)" style="cursor:pointer">
                        <td class="city">{{ event.event.city.name }}</td>
                        <td class="sport">{{ event.event.sport.name }}</td>
                        <td class="name-table">{{ event.event.name }}</td>
                        <td class="date">
                           <span>{{ data_event(event.date_added) }}</span>
                        </td>
                     </tr>
                  </tbody>
               </table>
            </div>
            <div v-else>
               <p class="p-error-order">Вы еще не записывались на мероприятия, вы можете записаться <router-link
                     to="/all_events" class="cart--red-link">тут</router-link></p>

            </div>

         </div>
      </div>

      <div v-if="events_info.length == 0" style="padding-bottom: 100px;"></div>

   </div>

   <div v-if="showmodal" class="modal-footer--open">
      <div class="modal-wrapper">

         <a @click="closeModal()" class="close--modal--footer"></a>


         <div class="modal-container--footer" v-if="!loading">

            <div class="modal-body--foter" style="overflow-y: auto;" v-if="event_detail.id != undefined">
               <!-- <a v-if="event_details.length > 1" @click="backModal()" class="back--modal--footer">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                     <path
                        d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l105.4 105.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H416C433.7 224 447.1 238.3 447.1 256z"
                        fill="#fff" />
                  </svg>
               </a> -->
               <div class="row margin-bottom--footer-modal">
                  <h4 class="footer--h4"
                     style="text-align: center !important; font-weight: 600; margin-bottom: 10px !important;">{{
                        event_detail.name }}</h4>
               </div>

               <div v-if="event_detail.eventsImgs.length" class="position-relative mb-4"
                  style="max-height: 270px; overflow: hidden;">
                  <swiper :modules="modules" navigation :pagination="{ clickable: true }"
                     :breakpoints="swiperOptions.breakpoints" @swiper="onSwiper" @slideChange="onSlideChange"
                     class="events-modal__swiper">
                     <swiper-slide v-for="(list, index) in event_detail.eventsImgs" :key="index">
                        <div class="events-modal__swiper_div">
                           <img :src="list.get_image" alt="" class="fluid-image">
                        </div>
                     </swiper-slide>
                  </swiper>
               </div>
               <h4 class="modal-event--text" v-html="event_detail.description">
               </h4>
               <div class="d-flex margin-bottom--footer-modal" v-if="event_detail.get_document">
                  <a target="_blank" class="link--document" :href="event_detail.get_document">Документ</a>
               </div>



               <!-- <router-link :to='{
                  path: "/anketa/" + this.event_detail.id + "/" + this.event_detail_date, params: {
                     id: this.event_detail.id,
                     date: this.event_detail_date
                  },
               }' class="disablebutton pointerclick">
                  Заполнить форму
               </router-link> -->
            </div>

            <div class="modal-body--foter" style="overflow-y: auto;" v-else>

               <div class="row margin-bottom--footer-modal">
                  <h4 class="footer--h4"
                     style="text-align: left !important; font-weight: 600; margin-bottom: 10px !important;">Выберите
                     мероприятие:</h4>
               </div>

               <!-- <ul>
                  <li class="li_event mb-5" v-for="(item, index) in event_details" @click="OpenModal(item)">
                     <a @click="GetInfoEvent(index)" class="event_name">{{ item.name }}</a>
                  </li>
               </ul> -->

            </div>

         </div>
         <div class="modal-container--footer" v-else>
            <div class="modal-body--foter" style="overflow-y: auto;">

               <div class="outer">
                  <div class="inner">
                     <Loader />
                  </div>
               </div>

            </div>
         </div>


      </div>
   </div>


   <Footer :blue="true" :search_on="false" />
</template>
<style scoped lang="scss">
.table.is-fullwidth {
   @media(max-width: 768px) {
      .sport {
         display: none;
      }
   }

   @media(max-width: 450px) {
      .date {
         display: none;
      }
   }
}
</style>
<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

import Header from '@/components/Header/Header'
import Footer from '@/components/Footer/Footer'

import moment from 'moment';

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
export default {
   name: 'MyEvents',
   components: {
      Header,
      Footer,
      Swiper,
      SwiperSlide
   },
   data() {
      return {
         events: [],
         events_info: [],
         user_me: {},
         user_id: "",
         token: "",
         current_id: "",
         showmodal: false,
         event_detail: {},
         event_detail_date: "",
         swiperOptions: {
            breakpoints: {
               320: {
                  slidesPerView: 1,
                  spaceBetween: 30
               },
            }
         },
      }
   },
   setup() {
      const onSwiper = (swiper) => {
         console.log();
      };
      const onSlideChange = () => {
         console.log();
      };
      return {
         onSwiper,
         onSlideChange,
         modules: [Navigation, Pagination, Scrollbar, A11y],
      };
   },
   beforeCreate() {
      this.$store.commit('initializeStore')

      const user_id = this.$store.state.user_id

      this.user_id = user_id
   },
   mounted() {
      document.title = 'Мои мероприятия'

      this.user_id = this.$store.state.user_id

      this.getMyAccount()
      this.getMyEvents()
   },
   methods: {
      OpenModal(event) {
         this.event_detail_date = event.date_added
         this.event_detail = event.event
         this.showmodal = true
      },
      closeModal() {
         this.showmodal = false;
         this.event_detail = {};
         this.event_detail_date = ""
      },
      async getMyAccount() {
         this.$store.commit('initializeStore')

         await axios
            .get(`/api/v1/me/${this.user_id}/`)
            .then(response => {
               this.user_me = response.data
            })
            .catch(error => {
               console.log(error)
            })
      },
      // getInfoEvents() {
      //    for (let m = 0; m < this.events.length; m++) {
      //       const item = this.events[m]
      //       this.getEventInfo(item.event_id, item.date_added)
      //    }
      // },
      async getMyEvents() {
         this.$store.commit('initializeStore')

         await axios
            .get(`/api/v1/event_order/me/`)
            .then(response => {
               this.events = response.data
               // this.getInfoEvents()
            })
            .catch(error => {
               console.log(error)
            })
      },
      // async getEventInfo(event, date) {
      //    this.$store.commit('initializeStore')

      //    await axios
      //       .get(`/api/v1/event/${event}/`)
      //       .then(response => {
      //          const data = { name: response.data.name, datetime: date }
      //          this.events_info.push(data)
      //       })
      //       .catch(error => {
      //          console.log(error)
      //       })
      // },
      data_event(date) {
         moment.locale('ru');
         const datasort = moment(String(date)).format('DD MMMM YYYY')
         return datasort
      },
   },
   computed: {

   }
}
</script>