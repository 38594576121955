<template>

    <Header />

    <div class="container paddingcontainernew">
        <div class="category--block">
            <nav class="mb-lg-5 mt-3" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/" class="navbar-item">Главная</router-link></li>
                <li class="breadcrumb-item"><router-link to="/all_product" class="navbar-item">Все товары</router-link></li>
                <li class="breadcrumb-item">{{ category.name }}</li>
              </ol>
            </nav>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <ProductBoxCategory
                 :categorys="categorys"
                 :products="productss"
                 :current_tag="current_tag" />
            </div>
        </div>

    </div>

    <Footer />

</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

import ProductBoxCategory from '@/components/ProductBoxCategory'

import Scroll from '@/components/Scroll'

import Header from '@/components/Header/Header'
import Footer from '@/components/Footer/Footer'

export default {
    name: 'Category',
    components: {
        ProductBoxCategory,
        Scroll,
        Header,
        Footer,
    },
    data() {
        return {
            category: {
                products: [],
                cats: this.$route.params.category_slug
            },
            current_tag: 0,
            categorys: [],
            parent: [],
            pageNumber: 0,
            productss: [],
            errorcheck: '',
        }
    },
    mounted() {
        this.getCategorysAll()
        this.getCategory()
    },
    watch: {
        $route(to, from) {
            if (to.name === 'Category') {
                this.getCategory()
            }
        }
    },
    methods: {
        async getCategory() {
            const categorySlug = this.$route.params.category_slug

            this.$store.commit('setIsLoading', true)

            await axios
                .get(`/api/v1/products/${categorySlug}/`)
                .then(response => {
                    
                    this.category = response.data
                    this.productss = response.data.products

                    this.current_tag = this.category.id

                    document.title = this.category.name
                })
                .catch(error => {
                    this.errorcheck = error

                    console.log(error)

                    toast({
                        message: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз.',
                        type: 'is-danger',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })

                    this.$router.push('/404')
                })

            this.$store.commit('setIsLoading', false)
        },
        async getCategorysAll() {
            await axios
            .get('/api/v1/catall/')
            .then(response => {
                this.categorys = response.data.results
            })
            .catch(error => {
                console.log(error)
            })
        },
        scroll () {
            this.$refs.element.scrollTo();
        },
    }      
 
}
</script>