import { createRouter, createWebHistory } from 'vue-router'

import store from '../store'

// Main

import Home from '../views/Main/Home.vue'
import HomeFsop from '../views/Main/HomeFsop.vue'

// Auth

import SignUp from '../views/Auth/SignUp.vue'
import LogIn from '../views/Auth/LogIn.vue'
import NextStep from '../views/Auth/NextStep.vue'

// Others

import NotFound from '../views/404/NotFound.vue'
import Contacts from '../views/Contact/Contacts.vue'
import Category from '../views/Category/Category.vue'
import Search from '../views/Search/Search.vue'
import SearchFsop from '../views/Search/SearchFsop.vue'

import Help from '../views/FooterMenu/Help.vue'

// News
import News from '../views/FooterMenu/News.vue'
import NewsDetail from '../views/FooterMenu/NewsDetail.vue'

import Deyatelnost from '../views/FooterMenu/Deyatelnost.vue'
import AtheletDetail from '../views/FooterMenu/AtheletDetail.vue'

// Partners

import Partners from '../views/Parthners/Partners.vue'

// Cart

import Cart from '../views/Cart/Cart.vue'
import CheckoutNal from '../views/Cart/CheckoutNal.vue'
import Checkout from '../views/Cart/Checkout.vue'
import Success from '../views/Cart/Success.vue'

// Events

import AllEvents from '../views/Fsop/AllEvents.vue'
import Regions from '../views/Fsop/Regions.vue'
import RegionDetail from '../views/Fsop/RegionDetail.vue'

// Team

import AboutFsop from '../views/Fsop/AboutFsop.vue'
import Team from '../views/Fsop/TeamList.vue'
import History from '../views/Fsop/History.vue'
import TeamDetail from '../views/Fsop/TeamDetail.vue'
import Documents from '../views/Fsop/Documents.vue'
import Activity from '../views/Activity/Activity.vue'

// Trustees

import Trustees from '../views/Trustees/Trustees.vue'
import TrusteesDetail from '../views/Trustees/TrusteesDetail.vue'

// Product

import AllProduct from '../views/Products/AllProduct.vue'
import ProductView from '../views/Products/Product.vue'

// Profile

import MyAccount from '../views/UserProfile/MyAccount.vue'
import EditProfile from '../views/UserProfile/EditProfile.vue'
import MyEvents from '../views/UserProfile/MyEvents.vue'

// Subs 

import Subs from '../views/Subs/MainSubs.vue'
import SuccessSubs from '../views/Subs/SuccessSubs.vue'
import ErorrSubs from '../views/Subs/ErrorSubs.vue'
import StatusSubs from '../views/Subs/StatusSubs.vue'
import Anketa from '../views/Subs/Anketa.vue'
import AnketaSuccess from '../views/Subs/AnketaSuccess.vue'

const routes = [
   {
      path: '/',
      name: 'HomeFsop',
      component: HomeFsop
   },
   {
      path: '/shop-fsop',
      name: 'Home',
      component: Home
   },
   {
      path: '/sign-up',
      name: 'SignUp',
      component: SignUp,
      meta: {
         loginHave: true,
      }
   },
   {
      path: '/log-in',
      name: 'LogIn',
      component: LogIn,
      meta: {
         loginHave: true,
      }
   },
   {
      path: '/sub_main',
      name: 'Subs',
      component: Subs,
      meta: {
         requireLogin: true,
      }
   },
   {
      path: '/sub_success',
      name: 'SuccessSubs',
      component: SuccessSubs,
      meta: {
         requireLogin: true,
      }
   },
   {
      path: '/sub_status',
      name: 'StatusSubs',
      component: StatusSubs,
      meta: {
         requireLogin: true,
      }
   },
   {
      path: '/about_fsop',
      name: 'AboutFsop',
      component: AboutFsop
   },
   {
      path: '/all_events',
      name: 'AllEvents',
      props: true,
      component: AllEvents
   },
   {
      path: '/sub_error',
      name: 'ErorrSubs',
      component: ErorrSubs
   },
   {
      path: '/my-account',
      name: 'MyAccount',
      component: MyAccount,
      meta: {
         requireLogin: true,
         requireSubs: true,
      }
   },
   {
      path: '/edit_profile',
      name: 'EditProfile',
      component: EditProfile,
      meta: {
         requireLogin: true,
         requireSubs: true,
      }
   },
   {
      path: '/my_events',
      name: 'MyEvents',
      component: MyEvents,
      meta: {
         requireLogin: true,
         requireSubs: true,
      }
   },
   {
      path: '/search',
      name: 'Search',
      component: Search
   },
   {
      path: '/search_fsop',
      name: 'SearchFsop',
      component: SearchFsop
   },
   {
      path: '/cart',
      name: 'Cart',
      component: Cart
   },
   {
      path: '/cart/success',
      name: 'Success',
      component: Success,
      meta: {
         requireLogin: true,
         requireSubs: true,
      }
   },
   {
      path: '/cart/checkout',
      name: 'Checkout',
      component: Checkout,
      meta: {
         requireLogin: true,
         requireSubs: true,
      }
   },
   {
      path: '/:category_slug',
      name: 'Category',
      component: Category
   },
   {
      path: '/:category_slug/:product_slug',
      name: 'ProductView',
      component: ProductView
   },
   {
      path: '/all_product',
      name: 'AllProduct',
      component: AllProduct
   },
   {
      path: '/contact',
      name: 'Contacts',
      component: Contacts
   },
   {
      path: '/history',
      name: 'History',
      component: History
   },
   {
      path: '/documents',
      name: 'Documents',
      component: Documents
   },
   {
      path: '/trustees',
      name: 'Trustees',
      component: Trustees
   },
   {
      path: '/activity',
      name: 'Activity',
      component: Activity
   },
   {
      path: '/trustees/:int_pk',
      name: 'TrusteesDetail',
      component: TrusteesDetail
   },
   {
      path: '/partners',
      name: 'Partners',
      component: Partners
   },
   {
      path: '/cart/checkout_nal',
      name: 'CheckoutNal',
      component: CheckoutNal,
      meta: {
         requireLogin: true,
         requireSubs: true,
      }
   },
   {
      path: '/404',
      name: 'NotFound',
      component: NotFound
   },
   {
      path: '/help',
      name: 'Help',
      component: Help
   },
   {
      path: '/team/:int_pk',
      name: 'TeamDetail',
      component: TeamDetail
   },
   {
      path: '/news',
      name: 'News',
      component: News
   },
   {
      path: '/regions',
      name: 'Regions',
      component: Regions
   },
   {
      path: '/team',
      name: 'Team',
      component: Team
   },   
   {
      path: '/region/:int_pk',
      name: 'RegionDetail',
      component: RegionDetail
   },
   {
      path: '/anketa/:id/:date',
      name: 'Anketa',
      component: Anketa,
      meta: {
         userActive: true,
      },
   },
   {
      path: '/deyatelnost',
      name: 'Deyatelnost',
      component: Deyatelnost
   },
   // {
   //    path: '/anketa_success',
   //    name: 'AnketaSuccess',
   //    component: AnketaSuccess,
   //    meta: {
   //       requireLogin: true,
   //    }
   // },
   {
      path: '/next-step',
      name: 'NextStep',
      component: NextStep,
      meta: {
         requireLogin: true,
      }
   },
   {
      path: '/deyatelnost/:int_pk',
      name: 'AtheletDetail',
      component: AtheletDetail
   },
   {
      path: '/news/:int_pk',
      name: 'NewsDetail',
      component: NewsDetail
   },
   {
      path: '/:catchAll(.*)',
      redirect: '404'
   }
]

const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
   routes,
   scrollBehavior() {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
   }
})

router.beforeEach((to, from, next) => {
   if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
      next({ name: 'LogIn', query: { to: to.path } });
   } else {
      next()
   }
})

// router.beforeEach((to, from, next) => {
//    if (to.matched.some(record => record.meta.requireSubs) && !store.state.status_pay) {
//       next({ name: 'Anketa', params: true });
//    } else {
//       next()
//    }
// })

router.beforeEach((to, from, next) => {
   if (to.matched.some(record => record.meta.loginHave) && store.state.isAuthenticated) {
      next({ name: 'HomeFsop' });
   } else {
      next()
   }
})

// router.beforeEach((to, from, next) => {
//    if (to.matched.some(record => record.meta.userDraft) && store.state.user.status_user == 0) {
//       next({ name: 'Anketa', params: true });
//    } else {
//       next()
//    }
// })

// router.beforeEach((to, from, next) => {
//    if (to.matched.some(record => record.meta.userActive) && store.state.user.status_user == 1) {
//       next({ name: 'Subs' });
//    } else {
//       next()
//    }
// })


export default router
