<template>

  <Header />

<div class="parth__main paddingBottom60">
  <div class="container paddingcontainernew">
    <div class="parners--block">
        <h3>НАШИ ПАРТНЕРЫ</h3>
    </div>
    <div class="row">
      <div v-for="(list,index) in partenrsList" 
        v-show="list.draft == 1"
        class="col-lg-2 col-6 marginBottom50 parth_col text-center"
      >
        <img class="img-fluid fone__img" :src="list.get_image">
        <p>{{ list.name }}</p>
      </div>      
    </div>

    <div class="d-flex justify-content-center w-100">
      <div class="mxw-main--partners">
<!--         <h4 class="textMainPartners textCenter mb-6">
          Тесттекст Тесттекст  Тесттекст  Тесттекст  Тесттекст  Тесттекст  Тесттекст  Тесттекст  Тесттекст  Тесттекст Тесттекст  Тесттекст  Тесттекст  Тесттекст  Тесттекст  Тесттекст  Тесттекст Тесттекст  Тесттекст  Тесттекст   Тесттекст  Тесттекст         
        </h4> -->

        <div class="divHomeCenter">
          <a @click="OpenModalPartners()">
            <div class="btnHome">
              Стать партнером
            </div>
          </a>          
        </div>

        <div v-if="showModal" class="modal-footer--open">
          <div class="modal-wrapper">

            <a @click="showModal = false" class="close--modal--footer"></a>

            <div class="modal-container--footer">
              <div class="modal-body--foter">
                <form @submit.prevent="submitForm">
                <div class="row margin-bottom--footer-modal">
                  <h4 class="footer--h4">Заполните данные</h4>

                      <div class="col-lg-12 margin-bottom--footer-modal">
                        <div class="field mb-0">
                          <label 
                          class="label--checkout"
                          :class="{'check-label--modalparth': valide_name}"
                          >Наименование компании</label>
                          <input 
                          type="text" class="input--checkout" 
                          v-model="name"
                          @focus="focused_name = true"
                          style="border-radius: 4px !important;" 
                          >
                        </div>
                      </div> 
                  
                      <div class="col-lg-12">
                        <div class="field mb-0">
                          <label 
                          class="label--checkout"
                          :class="{'check-label--modalparth': validate_email }"
                          >E-mail</label>
                          <input 
                          type="text" class="input--checkout" 
                          v-model="email"
                          @focus="focused = true"
                          style="border-radius: 4px !important;" 
                          >
                        </div>
                      </div> 

                    </div>                    

                    <div class="d-flex margin-bottom--footer-modal">
                      <input class="checkbox--info" 
                        type="checkbox" 
                        value="1" 
                        v-model="info"
                      >
                      <p class="footer--p">Нажимая на галочку вы подверждаете согласие на обработку 
                        <router-link class="modal-footer--link" to="/personal_info">персональных данных</router-link></p>
                    </div>
                    <button v-if="info == 1 && email.length > 0 && name.length > 0" class="detail--button" @click="sendForm">
                      Готово
                    </button>
                    <button v-else disabled="disabled" class="detail--button disablebutton">Готово</button>

                    <div class="notification is-danger mt-4" v-if="errors.length > 0">
                        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                    </div>

                </form>
              </div>
            </div>
          </div>
        </div>


      </div>      
    </div>


  </div>  
</div>

<FooterFsop :search_on="false" />

</template>

<style>
  .check-label--modalparth {
    display: none !important;
    visibility: hidden !important;
    background: transparent !important;    
  }
  .fone__img {
/*    background: #FEF9F3;
    padding: 10px;*/
    margin-bottom: 10px;
  }

  .parth__main {
    margin-bottom: 30px;
  }

  .parth_col p {
    font-weight: 600;
    color: #111;
  }
</style>

<script>
  import axios from 'axios'
  import { toast } from 'bulma-toast'

  import FooterFsop from '@/components/Footer/FooterFsop'
  import Header from '@/components/Header/HeaderFsop'

  export default {
    name: 'Partners',
    components: {
      Header,
      FooterFsop
    },
    data() {
      return {
        focused: false,
        focused_name: false,
        showModal: false,
        partenrsList: [],
        errors: [],
        draft: 0,
        name: "",
        email: "",
        info: "",
      }
    },
    mounted() {
      this.getPartners()
    },
    methods: {
        OpenModalPartners() {
            this.showModal = true;
        },
        async getPartners() {

            await axios
                .get(`/api/v1/partners/`)
                .then(response => {
                    this.partenrsList = response.data
                })
                .catch(error => {
                    console.log(error)

                    toast({
                        message: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз.',
                        type: 'is-danger',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })
                })

        },      
        validEmail(email) {
          var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(email);
        },
        async submitForm() {
          this.errors = []

          if (this.email === '') {
              this.errors.push('Укажите электронную почту.');

          } else if (!this.validEmail(this.email)) {
              this.errors.push('Укажите корректный адрес электронной почты.')
          }

          if (!this.errors.length) {
              const formData = {
                email: this.email,
                name: this.name,
                draft: this.draft
              }

              await axios
              .post(`/api/v1/partners/`, formData)
              .then(response => {
                toast({
                  message: 'Данные успешно отправлены',
                  type: 'is-success',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 2000,
                  position: 'bottom-right',
                })

                this.email = "";
                this.name = "";
                this.showModal = false;
              })                
              .catch(error => {
                console.log(error)
                toast({
                  message: 'Произошла ошибка',
                  type: 'is-danger',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 2000,
                  position: 'bottom-right',
                })                    
              })           
            }    
        }  
    },
    computed: {
      validate_email() {
        if(this.email) {
          return true;
        } else {
          return false;
        }
      },
      valide_name() {
        if(this.name) {
          return true;
        } else {
          return false;
        }
      },
    }        
  }
</script>