<template>

  <HeaderFsop />

 

      <div class="container paddingcontainernew">
          <div class="footer-menu--block" style="padding-bottom: 50px !important;">
              <h3 class="contact">Региональные отделения</h3>
          </div>

        <div class="row mb-5">
          <div class="col-lg-3 mb-6 pb-5" v-for="list in regions_listing">
            <div class="d-in--regions">
              <div class="position-relative sm-w-h-regions">
                <router-link :to="'/region/' + list.id">
                  <img :src="list.get_image" class="img-fluid">
                </router-link>
              </div>
              <router-link class="list_name-regions" :to="'/region/' + list.id">{{ list.name }}</router-link>
            </div>
          </div>
        </div>

          <div class="d-flex w-100 justify-content-center">
            <div class="notMoreBlock">
              <h3 class="notMoreProducts">{{ regions_listing.length }} / {{ total }} 
              Регионов</h3>
            </div>
          </div>

      </div>


  <FooterFsop />

</template>

<style>
.list_name-regions {
  line-height: 30px !important;
}
</style>

<script>
  import HeaderFsop from '@/components/Header/HeaderFsop'
  import FooterFsop from '@/components/Footer/FooterFsop'  
  import axios from 'axios'
  import { toast } from 'bulma-toast'

  export default {
    name: 'Regions',
    data() {
      return {
        page: 1,
        total: 1,
        page_max: 1,
        blockactive: true,
        regions: [],
        regions_listing: [],
        last_listing: [],

        offset: 0,
      }
    },
    components: {
      HeaderFsop,
      FooterFsop
    },
    mounted() {

      this.GetRegions(this.offset);

      window.addEventListener("scroll", this.handleScroll);
    },
    methods: {
      // async GetRegionIndex() {

      //   await axios
      //   .get(`/api/v1/regions_index/?limit=12&offset=0`)
      //   .then(response => {
      //     for (let q = 0; q < response.data.length; q++) {
      //           const item = response.data[q]
      //           this.regions_listing.push(item)
      //           console.log(this.regions_listing)
      //     }   

      //   })
      //   .catch(error => {
      //     console.log(error)
      //   })

      // }, 
      // async GetRegions(offset) {

      //   console.log(offset)

      //   await axios
      //   .get(`/api/v1/regions_index/?limit=12&offset=${offset}`)
      //   .then(response => {
      //     this.regions = response.data.results
      //     console.log(this.regions)
          

      //     for (let q = 0; q < this.regions.length; q++) {
      //           const item = this.regions[q]

      //           console.log(item)

      //           if (item.index == 0) {
      //               if (this.page == 1) {
      //                   if (this.regions_listing.length % 12 == 0) {
      //                       this.last_listing.push(item)
      //                   } else {
      //                       this.regions_listing.push(item)
      //                   }                      
      //                 } else {
      //                   this.regions_listing.push(item)
      //                 }

      //           }
      //     }   

      //     console.log(this.regions_listing)

      //     this.page_max = this.page + 1
      //     this.total = response.data.count
      //     this.page_last = Math.ceil(this.total / 12)
      //   })
      //   .catch(error => {
      //     console.log(error)
      //   })

      //   if (this.page == this.page_last) {
      //     for (let q = 0; q < this.last_listing.length; q++) {
      //           const item = this.last_listing[q]
      //           this.regions_listing.push(item)
      //     }             
      //   }

      // }, 
      async GetRegions(offset) {

        console.log(offset)

        await axios
        .get(`/api/v1/regions_index/?limit=12&offset=${offset}`)
        .then(response => {
          this.regions = response.data.results

          for (let q = 0; q < this.regions.length; q++) {
                const item = this.regions[q]
                this.regions_listing.push(item)
          }   

          this.page_max = this.page + 1
          this.total = response.data.count
          this.page_last = Math.ceil(this.total / 12)
        })
        .catch(error => {
          console.log(error)
        })

        if (this.page == this.page_last) {
          for (let q = 0; q < this.last_listing.length; q++) {
                const item = this.last_listing[q]
                this.regions_listing.push(item)
          }             
        }

      }, 
      handleScroll() {
        if 
          (
            window.scrollY + window.innerHeight >=
            document.body.scrollHeight - 400
            &&
            this.page <= this.page_max 
            ) {
            for (var i = 0; i < this.total; i++) {
              if (this.page < this.page_max) {
                  if (i % 12 == 0) {
                      this.page++ 
                      
                      this.offset = this.offset + 12
                      this.GetRegions(this.offset)
                  }
              }
            }
          }
      },
      created() {

      },
    }
  }
</script>