<template>
  <div class="maincarousel">
    <swiper
      :modules="modules"

      :slidesPerView="1"
      :spaceBetween="40"
      :autoplay="autoplay"
      :pagination="{ clickable: true }"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide v-for="baner in BannersNews" :key="baner">
        <div class="position-relative d-flex justify-content-center">
<!--               <a :href="link(baner.link)"> -->
                <img :src="baner.get_image" class="carousel__item">
<!--               </a>        -->      
<!--             <div class="col-lg-6">
              <div class="block-carousel">
                <div class="new-banner-block-main">
                  <h4 class="bannerh4main">{{ baner.name }}</h4>
                  <p class="date--main_banner_p">{{ datanews(baner.date_added) }}</p>
                  <p class="baner-textmain">{{ baner.description }}</p>
                  <div class="mt-carousel_main">
                    <router-link :to="'/news/' + baner.id" class="link-main-banner">
                      <span class="link-bordermain">Подробнее</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div> -->

        </div>
      </swiper-slide>

    </swiper>
  </div>
</template>

<style type="text/css">
  .mx-hcarousel__item {
    height: 450px;
  }
  .link-main-banner {
    font-weight: 600;
    color: #E33B2A !important;
    border-radius: 2px;
    border-bottom: 2px solid #E33B2A;
  }
  .mt-carousel_main {
    position: absolute;
    bottom: 100px;
  }
  .link-bordermain {
    width: 100%;

  }
  .link-main-banner:hover {
    font-size: 15px !important;
    border-bottom: 0px !important;
  }
  .baner-textmain {
    font-weight: 500;
    color: #111;
    font-size: 14px;    
    -webkit-line-clamp: 8;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .date--main_banner_p {
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
  }
  .bannerh4main {
    font-weight: 600;
    color: #111;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .new-banner-block-main {
    position: relative;
    background-color: #f6f7f7;
    height: 100%;
    height: 450px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .block-carousel {
     
  }
</style>
<script>
  import axios from 'axios'
  import { toast } from 'bulma-toast'
  // import Swiper core and required modules
  import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';

  // Import Swiper styles
  import 'swiper/css';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  import 'swiper/css/scrollbar';

  import moment from 'moment';

  // Import Swiper styles
  export default {
    name: 'CarouselMainFsop',
    data() {
      return {
        BannersNews: [],
        News_id: [],
        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },
      }
    },
    mounted() {
      this.GetNewBanner();
    },
    components: {
      Swiper,
      SwiperSlide,
    },
    props: {
      baners: Object,
    },
    setup() {
      const onSwiper = (swiper) => {

      };
      const onSlideChange = () => {

      };
      return {
        onSwiper,
        onSlideChange,
        modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
      };
    },
    methods: {
      async GetBannersID() {

        await axios
        .get('/api/v1/banners_fsop/')
        .then(response => {
          this.News_id = response.data

          for (let j = 0; j < this.News_id.length; j++) {
              const item = this.News_id[j]  
              this.GetNewBanner(item.new);
          }

        })
        .catch(error => {
          console.log(error)
        })

      },
      async GetNewBanner() {
        await axios
        .get(`/api/v1/banners_fsop/`)
        .then(response => {
          this.BannersNews = response.data
          console.log(this.BannersNews)
        })
        .catch(error => {
          console.log(error)
        })
      },
      datanews(date) {
        moment.locale('ru');
        const datasort = moment(String(date)).format('DD MMMM YYYY')
        return datasort
      },
      link(linka) {
        return String(linka)
      }, 
    },
  };
</script>