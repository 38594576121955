<template>
   <HeaderFsop />

   <div class="container paddingcontainernew">
      <div class="footer-menu--block" style="padding-bottom: 30px !important;">
         <h3 class="contact">Новости</h3>
      </div>

      <!--       <div class="row marginbottomsize-news">
        
        <div class="col-lg-12">
          <router-link :to="'/news/' + firstbanner.id">
          <div class="carousel-inner banner-first">
            <img :src="firstbanner.get_image" alt="" class="banner--news hover-scale rounded">
              <div class="z-back-positionabsolute">
                  <h3 class="bannerH3">{{ firstbanner.name }}</h3>
                  <div class="row">
                    <div class="col-lg-8">
                      <router-link :to="'/news/' + firstbanner.id" class="bannerH3-disk">{{ firstbanner.description }}</router-link>
                      <router-link :to="'/news/' + firstbanner.id" class="bannerH3-disk">{{ datanews(firstbanner.date_added) }}</router-link>
                    </div>
                  </div>
                  
              </div>
          </div>
          </router-link>
        </div>
        
      </div>

      <div class="row marginbottomsize-news">
        
          <div class="col-lg-6">
            <router-link :to="'/news/' + secondbanner.id">
            <div class="carousel-inner banner-first">
              <img :src="secondbanner.get_image" alt="" class="banner--news hover-scale rounded">
                <div class="z-back-positionabsolute">
                    <h3 class="bannerH3">{{ secondbanner.name }}</h3>
                    <router-link :to="'/news/' + secondbanner.id" class="bannerH3-disk">{{ secondbanner.description }}</router-link>
                    <router-link :to="'/news/' + secondbanner.id" class="bannerH3-disk">{{ datanews(secondbanner.date_added) }}</router-link>
                </div>
            </div>
            </router-link>
          </div>
        
        <div class="col-lg-6">
            <router-link :to="'/news/' + thirtybanner.id">
            <div class="carousel-inner banner-first">
              <img :src="thirtybanner.get_image" alt="" class="banner--news hover-scale rounded">
                <div class="z-back-positionabsolute">
                    <h3 class="bannerH3">{{ thirtybanner.name }}</h3>
                    <router-link :to="'/news/' + thirtybanner.id" class="bannerH3-disk">{{ thirtybanner.description }}</router-link>
                    <router-link :to="'/news/' + thirtybanner.id" class="bannerH3-disk">{{ datanews(thirtybanner.date_added) }}</router-link>
                </div>
            </div>
            </router-link>
        </div>
      </div> -->

      <div class="row mb-6">
         <div v-for="(list, index) in news" class="col-lg-4  mb-6" :key="index">
            <router-link :to="'/news/' + list.id">
               <div class="news--fluid">
                  <img class="img-fluid hover-scale rounded" :src="list.get_image">
               </div>
            </router-link>

            <div style="display: inline-flex; flex-direction: column;">
               <router-link :to="'/news/' + list.id" class="athelets-name" style="margin-bottom: 10px;">{{ list.name
               }}</router-link>

               <router-link :to="'/news/' + list.id" class="athelets-disk" style="margin-bottom: 10px;"
                  v-html="list.description"></router-link>

               <router-link :to="'/news/' + list.id" class="athelets-disk" style="margin-bottom: 10px;">{{
                  datanews(list.date_added) }}</router-link>

            </div>
         </div>
      </div>

      <div class="d-flex w-100 justify-content-center">
         <div class="notMoreBlock">
            <h3 class="notMoreProducts">{{ news.length }} / {{ news_count }}
               Новостей</h3>
         </div>
      </div>

   </div>

   <FooterFsop />
</template>

<style scoped lang="scss">
.row.mb-6 {
   @media(max-width: 992px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .img-fluid {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }

   @media(max-width: 568px) {
      grid-template-columns: 1fr;
   }
}
</style>

<script>
import HeaderFsop from '@/components/Header/HeaderFsop'
import FooterFsop from '@/components/Footer/FooterFsop'
import axios from 'axios'
import { toast } from 'bulma-toast'
import moment from 'moment';

export default {
   name: 'News',
   data() {
      return {
         page: 1,
         total: 1,
         news_count: 1,
         news: [],
         firstbanner: {},
         secondbanner: {},
         thirtybanner: {}
      }
   },
   components: {
      HeaderFsop,
      FooterFsop
   },
   mounted() {
      this.GetNewsList(this.page)
      window.addEventListener("scroll", this.handleScroll);
   },
   methods: {
      async GetNewsList(pageNumber) {

         await axios
            .get(`/api/v1/news/?page=${pageNumber}`)
            .then(response => {
               console.log("asdsaddsdasda: ", response);
               var total_item = response.data.count
               this.news_count = response.data.count
               this.total = Math.ceil(total_item / 6);
               for (var i = 0; i < response.data.results.length; i++) {
                  const item = response.data.results[i]
                  this.news.push(item)
               }
            })
            .catch(error => {
               console.log(error)
            })

      },
      handleScroll() {
         if
            (
            window.scrollY + window.innerHeight >=
            document.body.scrollHeight - 300
            &&
            this.page <= this.total
         ) {
            console.log("ГРУЗИМ");
            this.page++
            this.GetNewsList(this.page)

            // for (var i = 0; i < 6; i++) {
            //    // this.page++
            //    // if (this.page <= 2) {
            //    //    this.GetNewsList(this.page)
            //    // }
            //    this.GetNewsList(this.page)

            // }
         }
      },
      datanews(date) {
         moment.locale('ru');
         const datasort = moment(String(date)).format('DD MMMM YYYY')
         return datasort
      },
   }
}
</script>