<template>
   <Header />

   <div class="container paddingcontainernew">
      <div class="cart--container">
         <div class="d-flex justify-content-between mbp-cart">
            <h5 class="product--cart-title">Моя корзина</h5>
            <router-link class="all--product-link" to="/all_product">
               Все товары
            </router-link>
         </div>

         <div class="row">
            <div class="col-lg-8">
               <CartItem v-for="item in cart.items.slice().reverse()" v-bind:key="item.product.id"
                  v-bind:initialItem="item" v-on:removeFromCart="removeFromCart" />
            </div>
            <div class="col-lg-4">
               <div class="cart--itog-box">
                  <div class="column is-12 cart--box">

                     <div class="w-100 d-flex justify-content-between pb-4 mb-4 border-bottom">
                        <h4 class="cart--total--amount">
                           Товаров:
                        </h4>

                        <h4 class="cart--total--amount">
                           {{ cartTotalLength }} Шт.
                        </h4>
                     </div>

                     <div class="w-100 d-flex justify-content-between">
                        <h4 class="cart--total--price">
                           Итого:
                        </h4>

                        <h4 class="cart--total--price">
                           {{ cartTotalPrice.toFixed(2) }} Руб.
                        </h4>
                     </div>


                  </div>

               </div>
               <div v-if="cartTotalPrice.toFixed(2) > 500">

                  <div class="my-5">
                     <button v-if="zakaz == 0" @click="zakaz_itap()" class="cart--button">Перейти к оформлению
                        заказа</button>
                  </div>
                  <div class="cart--etap cart--box p-5" v-if="zakaz >= 1">

                     <h1 class="itap">1 ЭТАП</h1>

                     <h3 class="dostavka_h3">Выберете способ доставки</h3>

                     <div class="p-3 row mb-5">
                        <div class="col-lg-6 d-flex justify-content-center marginbottom-mobile-cart">
                           <label class="radio">
                              <div class="box--pick-zakaz dostavka--box" :class="{ 'active--box': cartCheck }">
                                 <input class="radio--pick" type="radio" v-model="pick" v-bind:value="1">Доставка
                              </div>
                           </label>
                        </div>

                        <div class="col-lg-6 d-flex justify-content-center">
                           <label class="radio">
                              <div class="box--pick-zakaz samovivoz--box" :class="{ 'active--box': cartCheckSamo }">
                                 <input class="radio--pick" type="radio" v-model="pick" v-bind:value="2">Самовывоз
                              </div>
                           </label>
                        </div>
                     </div>



                     <div v-if="pick === 1 || pick === 2">

                        <h3 class="dostavka_h3">Выберете способ оплаты</h3>

                        <div class="pb-3 mb-5">


                           <div v-if="pick === 1">
                              <div class="row">
                                 <div class="col-lg-12">
                                    <a @click="showModal()" class="button--cart-product-s online-bg">Перейти к онлайн
                                       оплате</a>
                                 </div>
                              </div>

                           </div>
                           <div v-if="pick === 2">
                              <div>
                                 <a @click="showModal()" class="button--cart-product-s bg-samovivoz">Оплатить на месте
                                    наличными</a>
                              </div>
                           </div>


                        </div>

                     </div>
                     <div v-else>
                        <p class="help_text">*Для продолжения оформления заказа, выберете способ доставки</p>
                     </div>


                  </div>




               </div>
               <div v-else>
                  <strong>Сумма товаров должна быть больше 500 рублей, сейчас {{ cartTotalPrice.toFixed(2) }}/500₽</strong>
               </div>

            </div>
         </div>

      </div>
   </div>
   <div class="product--list-detail">
      <div class="container paddingcontainernew">
         <div class="product-block">
            <TheBestProduct :products="items" title="Эти товары могут вам понравится" />
         </div>
      </div>
   </div>

   <div v-if="showmodal" class="modal-footer--open">
      <div class="modal-wrapper">

         <a @click="showmodal = false" class="close--modal--footer"></a>

         <div class="modal-container--footer" v-if="!$store.state.isAuthenticated">
            <div class="modal-body--foter">
               <div class="row margin-bottom--footer-modal">
                  <h4 class="logout-modal--h4">Чтобы оформить заказ необходимо войти в личный кабинет</h4>
               </div>
               <div class="d-flex justify-content-between">
                  <button @click="showmodal = false" class="detail--button disablebutton"
                     style="margin-right: 20px;">Отмена</button>
                  <router-link to="/log-in" class="detail--button" style="display: inline-block;">
                     Войти
                  </router-link>
               </div>

            </div>
         </div>

         <div class="modal-container--footer" v-if="$store.state.isAuthenticated && !status">
            <div class="modal-body--foter">
               <div class="row margin-bottom--footer-modal">
                  <h4 class="logout-modal--h4">Услуги оказываются только участникам Спортобщества "Россия"</h4>
               </div>
               <div class="d-flex justify-content-between">
                  <button @click="showmodal = false" class="detail--button disablebutton"
                     style="margin-right: 20px;">Отмена</button>
                  <router-link to="/anketa" class="detail--button" style="display: inline-block;">
                     Заполнить анкету
                  </router-link>
               </div>

            </div>
         </div>


      </div>
   </div>

   <Footer />
</template>

<style lang="scss">
.detail--button:hover {
   color: #fff !important;
}


.cart--box {
   .p-3.row.mb-5 {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;

      &>* {
         width: unset;
      }
   }
}
</style>

<script>
import Header from '@/components/Header/Header'
import Footer from '@/components/Footer/Footer'
import axios from 'axios'
import CartItem from '@/components/CartItem.vue'
import TheBestProduct from '@/components/TheBestProduct'
export default {
   name: 'Cart',
   components: {
      CartItem,
      TheBestProduct,
      Header,
      Footer
   },
   data() {
      return {
         cart: {
            items: [],
         },
         pick: '',
         acc_check: 0,
         zakaz: 0,
         status: false,
         showmodal: false,
      }
   },
   mounted() {
      this.status = this.$store.state.status_pay
      this.cart = this.$store.state.cart
   },
   methods: {
      removeFromCart(item) {
         this.cart.items = this.cart.items.filter(i => i.product.id !== item.product.id)
      },
      zakaz_itap() {
         return this.cart.items.reduce((acc, curVal) => {
            acc = curVal.product.category_id
            this.zakaz++
            if (curVal.product.category_id == 2 || acc == 2) {
               this.acc_check = 1
            } else {
               this.zakaz++
            }
            this.zakaz++
         }, 0)
      },
      showModal() {
         this.showmodal = true;

         if (this.$store.state.isAuthenticated && this.status) {
            this.$router.push('/cart/checkout')
         }
      },
   },
   computed: {
      cartTotalLength() {
         return this.cart.items.reduce((acc, curVal) => {
            return acc += curVal.quantity
         }, 0)
      },
      cartTotalPrice() {
         return this.cart.items.reduce((acc, curVal) => {
            if (curVal.product.discount) {
               return acc += curVal.product.discount * curVal.quantity
            } else {
               return acc += curVal.product.price * curVal.quantity
            }
         }, 0)
      },
      cartCheck() {
         if (this.pick == 1) {
            return true;
         } else {
            return false;
         }
      },
      cartCheckSamo() {
         if (this.pick == 2) {
            return true;
         } else {
            return false;
         }
      }
   }
}
</script>