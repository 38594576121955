<template>
    <div class="container paddingcontainernew position-relative">
      <div class="header-row close-992">
          <router-link to="/" class="header-logo">
              <img src="@/assets/logo_main.svg">
          </router-link>
</div>

<div class="header-row open-992">
        <router-link to="/" class="header-logo">
            <img src="@/assets/logo_main.svg">
        </router-link>
</div>
</div>
</template>

<script>
    export default {
        name: 'HeaderSmall',
    }
</script>