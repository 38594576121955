<template>

  <HeaderFsop />


  <div class="container paddingcontainernew">
    <div class="category--block">
      <nav class="mb-lg-5 mt-3" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/" class="navbar-item">Главная</router-link></li>
          <li class="breadcrumb-item"><router-link to="/news" class="navbar-item">Новости</router-link></li>
          <li class="breadcrumb-item"><span style="font-weight: 500;">{{ newinfo.name }}</span></li>
        </ol>
      </nav>
    </div>

    <div class="row">
      <div class="new_detail__div">
        <swiper :modules="modules" navigation :pagination="{ clickable: true }"
          :breakpoints="swiperOptions.breakpoints" @swiper="onSwiper" @slideChange="onSlideChange" class="position-relative">
          <swiper-slide v-for="(list, index) in imageArray" :key="index" >
            <img :src="list.image" class="img-fluid">
          </swiper-slide>
        </swiper>
      </div>
      <div class="col-lg-3"></div>
      <div class="col-lg-6">
        <h5 class="athelet--name">{{ newinfo.name }}</h5>
        <h5 class="athelet--date">{{ datanews(newinfo.date_added) }}</h5>
        <div class="news-topmargin">
          <p class="description-athlet" v-html="newinfo.description"></p>
        </div>

      </div>
      <div class="col-lg-3"></div>
    </div>
<!-- 
               <div v-if="event_detail.eventsImgs.length" class="position-relative mb-4"
                  style="max-height: 270px; overflow: hidden;">
                  <swiper :modules="modules" navigation :pagination="{ clickable: true }"
                     :breakpoints="swiperOptions.breakpoints" @swiper="onSwiper" @slideChange="onSlideChange"
                     class="events-modal__swiper">
                     <swiper-slide v-for="(list, index) in event_detail.eventsImgs" :key="index">
                        <div class="events-modal__swiper_div" v-if="showbutton">
                           <img :src="list.get_image" alt="" class="fluid-image">
                        </div>
                        <div class="events-modal__swiper_div" v-else>
                           <router-link to="/my-account">
                              <img :src="list.get_image" alt="" class="fluid-image">
                           </router-link>
                        </div>
                     </swiper-slide>
                  </swiper>
               </div>
 -->

  </div>

  <div class="d-flex justify-content-center">
    <router-link to="/news" class="back--athlet"><span class="border--back">Назад</span></router-link>
  </div>
  
  <FooterFsop />

</template>

<style type="text/css">
.athelet--name {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 20px;
}

.new_detail__div {
  display: flex;
  width: 100%;
  flex: 0 0 auto;
  margin: 0px 0px 30px;
  justify-content: center;
}

.new_detail__div .position-relative {
    flex: 0 0 auto;
    width: 70%;
    overflow: hidden;
    border-radius: 6px;
}

@media (max-width: 1024px) {
  .new_detail__div .position-relative {
      width: 85%;
  }  
}

@media (max-width: 576px) {
  .new_detail__div .position-relative {
      width: 100% !important;
  }  
}


.border--back {
  border-bottom: 1px solid #111;
}

.back--athlet {
  text-align: center;
  width: 100%;
  font-weight: 600;
  color: #111;
  
  padding-bottom: 2px;
  margin-bottom: 40px;
  margin-top: 40px;
  transition: 0.2s ease;
}

.back--athlet:hover {
  transform: scale(1.05);
}

.description-athlet {
  line-height: 30px;
  font-weight: 500;
}
.news-topmargin {
  margin-top: 20px !important;
}

.athelet--date {
    overflow: hidden;
    font-weight: 500;
    color: #111;
    font-size: 15px;  
}

</style>

<script>
  // import Swiper core and required modules
  import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';

  // Import Swiper styles
  import 'swiper/css';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  import 'swiper/css/scrollbar';

  import HeaderFsop from '@/components/Header/HeaderFsop'
  import FooterFsop from '@/components/Footer/FooterFsop'    
  import axios from 'axios'
  import { toast } from 'bulma-toast'
  import moment from 'moment';

  export default {
    name: 'NewsDetail',
    data() {
      return {
        newinfo: {},
        imageArray: [],

        swiperOptions: {
            breakpoints: {
               320: {
                  slidesPerView: 1,
                  spaceBetween: 30
               },
               770: {
                  slidesPerView: 1,
                  spaceBetween: 30
               },

               1024: {
                  slidesPerView: 1,
                  spaceBetween: 30
               }
            }
        },
      }
    },
    setup() {
        const onSwiper = (swiper) => {
           console.log();
        };
        const onSlideChange = () => {
           console.log();
        };
        return {
           onSwiper,
           onSlideChange,
           modules: [Navigation, Pagination, Scrollbar, A11y],
        };
    },    
    components: {
      Swiper,
      SwiperSlide,
      HeaderFsop,
      FooterFsop
    },
    mounted() {
      this.getAthelet()
    },
    methods: {
      async getAthelet() {
        this.$store.commit('setIsLoading', true)

        const pk = this.$route.params.int_pk

        await axios
        .get(`/api/v1/new/${pk}/`)
        .then(response => {
          this.newinfo = response.data

          this.imageArray = response.data.imageArray
          this.imageArray.unshift({"image": response.data.get_image})

          document.title = this.new.name
        })
        .catch(error => {
          this.errorcheck = error
          console.log(error)
        })

        this.$store.commit('setIsLoading', false)
      },     
      datanews(date) {
        moment.locale('ru');
        const datasort = moment(String(date)).format('DD MMMM YYYY')
        return datasort
      },  
    }
  }
</script>