<template>
   <div class="full-color--events">

      <div class="container paddingcontainernew">

            <div class="footer-menu--block" style="padding-bottom: 30px !important;">
               <h3 class="contact fff">Мероприятия</h3>
               <!-- <h4 class="events--h4"><span class="iconred"></span>Мероприятия</h4> -->
            </div>

            <div class="filter--block--events">
               <select class="select-form--events" v-model="city_id">
                  <option class="option-events-select" value="">Выберите город:</option>
                  <option class="option-events-select" v-for="list in cities" :value="list.id">
                     {{ list.name }}
                  </option>
               </select>
               <select class="select-form--events" v-model="sport_id">
                  <option class="option-events-select" value="">Выберите вид спорта:</option>
                  <option class="option-events-select" v-for="list in sports" :value="list.id">
                     {{ list.name }}
                  </option>
               </select>
               <button class="btnHome" @click="loadFilterEvents">Применить</button>
            </div>

            <div class="filter--block--events filter--block--events__search">
               <div style="position: relative; width: 100%; display: flex;">
               <input class="filter--block--events__input select-form--events mrzeroL" type="text" v-model="eventsSearch"
                  @keyup.enter="loadSearchEvents" id="eventsSearch">
               <label class="filter--block--events__search_label" for="eventsSearch" ref="searchLabel">
                  <span class="icon--search">
                     <svg xmlns="http://www.w3.org/2000/svg" width="17.483" height="21.291" viewBox="0 0 17.483 21.291">
                        <g id="Сгруппировать_1151" data-name="Сгруппировать 1151" transform="translate(-434 -2857.354)">
                           <g id="Сгруппировать_799" data-name="Сгруппировать 799" transform="translate(1297 -6544.646)">
                              <g id="Сгруппировать_798" data-name="Сгруппировать 798">
                                 <g id="Эллипс_77" data-name="Эллипс 77" transform="translate(-863 9402)" fill="none"
                                    stroke="#ccc" stroke-width="1">
                                    <circle cx="8" cy="8" r="8" stroke="none" />
                                    <circle cx="8" cy="8" r="7.5" fill="none" />
                                 </g>
                                 <g id="Прямоугольник_182" data-name="Прямоугольник 182"
                                    transform="translate(-850.659 9416.52) rotate(50)" fill="#ccc" stroke="#ccc"
                                    stroke-width="1">
                                    <rect width="8" height="1" stroke="none" />
                                    <rect x="0.5" y="0.5" width="7" fill="none" />
                                 </g>
                              </g>
                           </g>
                        </g>
                     </svg>
                  </span>
                  <span>
                     Найти мероприятие
                  </span>
               </label>
               </div>
               <button class="filter--block--events__btn btnHome" @click="loadSearchEvents">Найти</button>
            </div>
         <div class="loader-events__in" v-if="!loader">
            <div class="filter--block--events">
               <a v-for="(tag, index) in years" @click="CurrentYear(tag, index)" class="tagbtn--category"
                  style="font-size: 14px; !important" :class="{ 'tagbtn--category-active': index == current_tag }">
                  {{ tag }}
               </a>
            </div>

            <div class="events-tabs" v-if="events.length">
               <ul class="events-tabs__list">
                  <li class="events-tabs__item">
                     <input type="radio" id="list" name="eventsView" value="list" v-model="eventsView">
                     <label for="list">Список</label>
                  </li>
                  <li class="events-tabs__item">
                     <input type="radio" id="cal" name="eventsView" value="cal" v-model="eventsView">
                     <label for="cal">Календарь</label>
                  </li>
               </ul>
            </div>

            <div class="events-nothing" v-if="!events.length">
               Ничего не найдено
            </div>
            <div class="events__list events-list" v-if="eventsView == 'list' && events.length">
               <div class="events-list__item">
                  <div class="events-list__category events-list__category_date">Дата</div>
                  <div class="events-list__category events-list__category_description">Описание</div>
                  <div class="events-list__category events-list__category_region">Регион</div>
                  <div class="events-list__category events-list__category_time">Время начала</div>
               </div>
               <div class="events-list__content">
                  <div class="events-list__item" v-for="(event, index) in events" @click="OpenModal(event)">
                     <div class="events-list__date">{{ formatedEventDate(event) }}</div>
                     <div class="events-list__description" v-html="event.description"></div>
                     <div class="events-list__region">
                        г. {{ event.city?.name }}
                     </div>
                     <div class="events-list__time">
                        {{ formatedEventTime(event) }}
                     </div>
                  </div>

               </div>
            </div>


            <div class="row mb-6" v-if="eventsView == 'cal' && events.length">
               <div class="col-lg-4 mb-6" v-for="list in CfilerMoth(tagsmoth)">
                  <h4 class="moth--h4-events">
                     {{ list.name }}
                  </h4>
                  <div class="row--date">
                     <span v-for="(listtags, index) in list.tag_scip" class="icon--events-span"></span>


                     <span v-for="(listtags, index) in list.tagsdays" class="icon--events-span"
                        :class="{ 'activeses--span-events': ValidateEvent(index + 1, list.name) == true, 'deactive--span-events': ValidateEvent(index + 1, list.name) == false }">

                        <a @click="OpenModalEvent(index + 1, list.name)" v-if="ValidateEvent(index + 1, list.name) == true"
                           class="active--togle-event">
                           {{ listtags }}
                        </a>
                        <span v-else>
                           {{ listtags }}
                        </span>

                     </span>


                  </div>
               </div>


            </div>


            <div class="divHomeCenter" v-if="!fullView">
               <router-link to="/all_events">
                  <div class="btnHome">
                     Посмотреть все события
                  </div>
               </router-link>
            </div>


         </div>
         <div v-else  class="outer">
            <div class="inner">
            <Loader />
            </div>
         </div>
      </div>
   </div>


   <div v-if="showmodal" class="modal-footer--open">
      <div class="modal-wrapper">

         <a @click="closeModal()" class="close--modal--footer"></a>


         <div class="modal-container--footer" v-if="!loading">

            <div class="modal-body--foter" style="overflow-y: auto;" v-if="event_detail.id != undefined">
               <a v-if="event_details.length > 1" @click="backModal()" class="back--modal--footer">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                     <path
                        d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l105.4 105.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H416C433.7 224 447.1 238.3 447.1 256z"
                        fill="#fff" />
                  </svg>
               </a>
               <div class="row margin-bottom--footer-modal">
                  <h4 class="footer--h4"
                     style="text-align: center !important; font-weight: 600; margin-bottom: 10px !important;">{{
                        event_detail.name }}</h4>
               </div>

               <div v-if="event_detail.eventsImgs.length" class="position-relative mb-4"
                  style="max-height: 270px; overflow: hidden;">
                  <swiper :modules="modules" navigation :pagination="{ clickable: true }"
                     :breakpoints="swiperOptions.breakpoints" @swiper="onSwiper" @slideChange="onSlideChange"
                     class="events-modal__swiper">
                     <swiper-slide v-for="(list, index) in event_detail.eventsImgs" :key="index">
                        <div class="events-modal__swiper_div" v-if="showbutton">
                           <img :src="list.get_image" alt="" class="fluid-image">
                        </div>
                        <div class="events-modal__swiper_div" v-else>
                           <router-link to="/my-account">
                              <img :src="list.get_image" alt="" class="fluid-image">
                           </router-link>
                        </div>
                     </swiper-slide>
                  </swiper>
               </div>
               <h4 class="modal-event--text" v-html="event_detail.description">
               </h4>

               <!-- <div class="d-flex margin-bottom--footer-modal">
                  <input class="custom-checkbox" type="checkbox" v-model="info" value="1" id="info">
                  <label class="field--checkbox-anketa" for="info">
                     Я ознакомлен с условиями мероприятия
                  </label>
               </div> -->

               <div class="d-flex margin-bottom--footer-modal" v-if="event_detail.get_document">
                  <a target="_blank" class="link--document" :href="event_detail.get_document">Документ</a>
               </div>



               <router-link :to='{
                  path: "/anketa/" + this.event_detail.id + "/" + this.event_detail_date, params: {
                     id: this.event_detail.id,
                     date: this.event_detail_date
                  },
               }' class="disablebutton pointerclick" v-if="this.isAuthenticated">
                  Заполнить форму
               </router-link>
               <router-link to='/log-in' class="disablebutton pointerclick" v-else>
                  Зарегистрируйтесь для участия
               </router-link>

               <!-- <div v-if="showbutton">
                  <div v-if="event_detail.check_order == 1">
                     <button disabled="disabled" class="disablebutton">Вы уже участвуете в мероприятии</button>
                  </div>
                  <div v-else>
                     <button v-if="info == 1" class="detail--button" @click="SendPUT(event_detail)">
                        Подтверждаю
                     </button>
                     <button v-else disabled="disabled" class="disablebutton">Подтверждаю</button>
                     <router-link :to='{
                        path: "/anketa/" + this.event_detail.id + "/" + this.event_detail_date, params: {
                           id: this.event_detail.id,
                           date: this.event_detail_date
                        },
                     }' class="disablebutton pointerclick" v-if="this.isAuthenticated">
                        Заполнить форму
                     </router-link>
                     <router-link to='/log-in' class="disablebutton pointerclick" v-else>
                        Вы не участник спортобщества
                     </router-link>
                  </div>
               </div> -->
               <!-- <div v-else>
                  <router-link :to='{
                     path: "/anketa/" + this.event_detail.id + "/" + this.event_detail_date, params: {
                        id: this.event_detail.id,
                        date: this.event_detail_date
                     },
                  }' class="disablebutton pointerclick" v-if="this.isAuthenticated">
                     Вы не участник Спортобщества
                  </router-link>
                  <button @click='this.$router.push("/my-account")' class="disablebutton pointerclick" v-else>
                     Вы не участник Спортобщества
                  </button>

               </div> -->

            </div>

            <div class="modal-body--foter" style="overflow-y: auto;" v-else>

               <div class="row margin-bottom--footer-modal">
                  <h4 class="footer--h4"
                     style="text-align: left !important; font-weight: 600; margin-bottom: 10px !important;">Выберите
                     мероприятие:</h4>
               </div>

               <ul>
                  <li class="li_event mb-5" v-for="(item, index) in event_details" @click="OpenModal(item)">
                     <a @click="GetInfoEvent(index)" class="event_name">{{ item.name }}</a>
                  </li>
               </ul>

            </div>

         </div>
         <div class="modal-container--footer" v-else>
            <div class="modal-body--foter" style="overflow-y: auto;">

               <div class="outer">
                  <div class="inner">
                     <Loader />
                  </div>
               </div>

            </div>
         </div>


      </div>
   </div>
</template>

<style lang="scss">
.option-events-select {
   background: #0F1D37 !important;
   color: #fff !important;
}

.icon--search {
   margin-top: 5px;
}

@media (max-width: 1024px) {

   .mrzeroL {
      margin-right: 0px !important;
   }

}


.filter--block--events__search {
   &_label {
      position: absolute;
      left: 1.1rem;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      white-space: nowrap;
      color: white;
      opacity: 0.6;

      path {
         fill: white
      }
   }
}

@media (max-width: 576px) {
   .modal-container--footer {
      max-width: 350px !important;
   }

   .close--modal--footer {
      z-index: 14;
   }
}

.filter--block--events {
   position: relative;

   &__search {

      @media(max-width: 568px) {
         display: flex;
         flex-direction: column;
         gap: 20px;
      }
   }

   &__btn {
      @media(max-width: 568px) {
         width: 100%;

      }
   }
}

.events-list {

   &__time,
   &__category_time {
      @media(max-width:568px) {
         display: none;
      }
   }

   &__region,
   &__category_region {
      @media(max-width: 568px) {
         justify-self: end;
      }

      @media(max-width: 450px) {
         display: none;
      }
   }

   &__item {
      @media(max-width: 568px) {
         grid-template-columns: minmax(auto, 100px) 1fr minmax(auto, 140px);
      }

      @media(max-width: 450px) {
         grid-template-columns: minmax(auto, 100px) 1fr
      }
   }
}

.events-nothing {
   color: white;
   font-weight: 600;
   font-size: 18px;
   opacity: 0.6;
   transition: 0.3s;
   cursor: pointer;
   text-align: center;
   margin-bottom: 20px;

}

.events-modal__swiper {
   height: 270px;

   .swiper-button-prev {
      display: flex !important;
   }

   .swiper-button-next {
      top: 50% !important;
   }

   &_div {
      height: 100%;
   }

   a {
      display: flex;
      height: 100%;
   }

   img {
      width: 100%;
      height: 100%;
      object-fit: cover;
   }
}

.disablebutton {
   display: flex;
   align-items: center;
   justify-content: center;
}
</style>

<script>
import moment from 'moment';
import HeaderFsop from '@/components/Header/HeaderFsop'
import FooterFsop from '@/components/Footer/FooterFsop'
import Loader from '@/components/Loader/Loader'
import axios from 'axios'
import { toast } from 'bulma-toast'

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
   name: 'app-events',

   props: {
      tagsmothP: {
         type: Array,
         default() {
            return []
         },
      },
      firstIndex: {
         type: Number,
         default: 0,
      },
      secondIndex: {
         type: Number,
         default: 3,
      },
      fullView: {
         type: Boolean,
         default: false,
      }
   },
   data() {
      return {
         // page: 3,
         // total: 1,
         // events: [],
         // all_events: [],
         years: [],
         // info: 0,
         // firstbanner: {},
         // secondbanner: {},
         user_info: {},

         showmodal: false,
         showbutton: false,
         loading: false,

         // modaldate: "",
         // modalmoth: "",

         auth: "",

         status: "",
         // images: [],

         checkitem: 0,

         event_details: [],
         // datetime_events: [],

         swiperOptions: {
            breakpoints: {
               320: {
                  slidesPerView: 1,
                  spaceBetween: 30
               },
               770: {
                  slidesPerView: 1,
                  spaceBetween: 30
               },

               1024: {
                  slidesPerView: 1,
                  spaceBetween: 30
               }
            }
         },

         current_year: 1999,
         current_tag: 0,
         current_day: 0,
         current_moth: 0,
         // data_now_detail: "",

         index_tags_1: 0,
         index_tags_2: 3,

         // date: [],
         // events_check: [],

         tagsmoth: [
            {
               name: "Май", tag_scip: 3,
               tagsactive: [], tagsdays: 31,
               id_event: [],
               index: 5,
            },
            {
               name: "Июнь", tag_scip: 5,
               tagsactive: [], tagsdays: 30,
               id_event: [],
               index: 6,

            },
            {
               name: "Июль", tag_scip: 8,
               tagsactive: [], tagsdays: 31,
               id_event: [],
               index: 7,
            },
            {
               name: "Август", tag_scip: 4,
               tagsactive: [], tagsdays: 31,
               id_event: [],
               index: 8,
            },
            {
               name: "Сентября", tag_scip: 6,
               tagsactive: [], tagsdays: 30,
               id_event: [],
               index: 9,
            },
            {
               name: "Октябрь", tag_scip: 1,
               tagsactive: [], tagsdays: 31,
               id_event: [],
               index: 10,
            },
            {
               name: "Ноябрь", tag_scip: 7,
               tagsactive: [], tagsdays: 30,
               id_event: [],
               index: 11,
            },
            {
               name: "Декабрь", tag_scip: 6,
               tagsactive: [], tagsdays: 31,
               id_event: [],
               index: 12,
            },
            {
               name: "Январь", tag_scip: 3,
               tagsactive: [], tagsdays: 31,
               id_event: [],
               index: 1,
            },

            {
               name: "Февраль", tag_scip: 5,
               tagsactive: [], tagsdays: 28,
               id_event: [],
               index: 2,
            },
            {
               name: "Март", tag_scip: 6,
               tagsactive: [], tagsdays: 31,
               index: 3,
            },
            {
               name: "Апрель", tag_scip: 8,
               tagsactive: [], tagsdays: 30,
               id_event: [],
               index: 4,
            },
         ],






         eventsView: 'cal',


         events: [],
         cities: [],
         sports: [],


         eventsSearch: '',
         city_id: '',
         sport_id: '',
         region: "",


         index_tags: [],

         event_detail: {},
         event_detail_date: "",
         isAuthenticated: false,

         loader: true,
      }
   },
   setup() {
      const onSwiper = (swiper) => {
         console.log();
      };
      const onSlideChange = () => {
         console.log();
      };
      return {
         onSwiper,
         onSlideChange,
         modules: [Navigation, Pagination, Scrollbar, A11y],
      };
   },
   components: {
      Swiper,
      SwiperSlide,
      HeaderFsop,
      FooterFsop,
      Loader
   },
   mounted() {
      if (this.$route.params.region) {
         this.region = this.$route.params.region;
      } else {
         this.region = ""
      }
      this.isAuthenticated = this.$store.state.isAuthenticated
      if (this.tagsmothP.length) {
         this.tagsmoth = this.tagsmothP
      }
      this.index_tags_1 = this.firstIndex
      this.index_tags_2 = this.secondIndex


      this.current_year = new Date().getFullYear()

      // Надо
      this.user_info = this.$store.state.user
      this.status = this.$store.state.status_pay
      this.auth = this.$store.state.isAuthenticated
      this.current_moth = moment(new Date()).format('MM')

      // this.CheckOrderEvents()
      // this.GetEventsList()
      // this.GetEventsAll()

      this.GetCities()
      this.GetSports()

      window.addEventListener("scroll", this.handleScroll);
      this.getEvents()
      if (!this.fullView) {
         this.GetViewEvents(this.current_moth)
      }

      setTimeout(() => {
         this.UpdateEvents();
         this.loader = false
      }, 1500)

   },
   methods: {
      arrayUnique(arr) {
         const f = arr.filter((e, i, a) => a.indexOf(e) == i)
         this.years = f.sort((a, b) => b - a);
         this.current_tag = this.years.indexOf(String(this.current_year))
      },
      OpenModalEvent(id, moth) {
         this.current_day = id; // День, на который тыкнули

         let check = 0; // ??
         let index_massive = 1; // индекс месяца в нашем массива

         for (let q = 0; q < this.tagsmoth.length; q++) {
            const itemnew = this.tagsmoth[q]

            if (itemnew.name == moth) {
               index_massive = q;
            }

         }
         // тут получили индекс




         let id_current = 0;  // день на который тыкнули     ?? зачем, если уже есть
         let index_ar = 0; // индекс в массиве дней месяца

         const items = this.tagsmoth[index_massive].tagsactive // получили все дни мероприятий в данном месяце 

         for (let i = 0; i < items.length; i++) {
            const item = items[i]

            if (id == item) {
               id_current = id
               index_ar = i;
            }
         }

         // я пишу
         this.events.forEach(event => {
            event.eventsData.forEach(date => {
               const day = moment(String(date.date_added)).format('DD')
               if (day == this.current_day) {
                  this.event_details.push(event)
               }
            })
         })


         const items_ar2 = this.tagsmoth[index_massive].id_event // получили все id мероприятий в данном месяце 

         this.checkitem = items_ar2.length // кол-во мероприятий в данном месяце

         let mainid = items_ar2[index_ar] // id нашего главного элемента
         items_ar2.forEach(item => {

         })


         // я пишу
         this.events.forEach(item => {
            if (item.id === mainid)
               this.event_detail = item
         })

         // я пишу
         let event_detail_month = this.tagsmoth[index_massive].index
         this.event_detail.eventsData.forEach(item => {
            let date = new Date(item.date_added)
            let YY = date.getFullYear()
            let MM = date.getMonth() + 1
            let DD = date.getDate()
            if (YY == this.current_year && MM == event_detail_month && DD == this.current_day) {
               this.event_detail_date = item.date_added
            }
         })





         if (items_ar2.length > 1) {

            for (let i = 0; i < items_ar2.length; i++) {
               const item = items_ar2[i]
               // this.GetEventDetail(item, id)
            }

            this.loading = true;

            setTimeout(() => {
               if (this.event_details.length == 1) {
                  // this.GetInfoEvent(0)
                  this.checkitem = 1
               }
               this.loading = false;
            }, 1000)


         } else {
            this.loading = true;

            setTimeout(() => {
               this.loading = false;
            }, 1000)
            // this.GetEventDetailOne(mainid)
         }

         if (this.$store.state.isAuthenticated == false) {
            // toast({
            //    message: 'Вы не авторизованы, пожалуйста войдите',
            //    type: 'is-danger',
            //    dismissible: true,
            //    pauseOnHover: true,
            //    duration: 10000,
            //    position: 'bottom-right',
            // })
            this.showmodal = true;
         } else {
            if (this.status == false) {
               // toast({
               //    message: 'Вы не являетесь членом Спортобщества',
               //    type: 'is-danger',
               //    dismissible: true,
               //    pauseOnHover: true,
               //    duration: 10000,
               //    position: 'bottom-right',
               // })
               this.showmodal = true;
            } else {
               this.showmodal = true;
               this.showbutton = true;
            }
         }

      },
      OpenModal(event) {
         // let index_massive = 1; // индекс месяца в нашем массива

         // for (let q = 0; q < this.tagsmoth.length; q++) {
         //    const itemnew = this.tagsmoth[q]

         //    if (itemnew.name == moth) {
         //       index_massive = q;
         //    }

         // }
         // let event_detail_month = this.tagsmoth[index_massive].index
         // this.event_detail.eventsData.forEach(item => {
         //    let date = new Date(item.date_added)
         //    let YY = date.getFullYear()
         //    let MM = date.getMonth() + 1
         //    let DD = date.getDate()
         //    if (YY == this.current_year && MM == event_detail_month && DD == this.current_day) {
         //       this.event_detail_date = item.date_added
         //    }
         // })
         this.event_detail_date = event.eventsData[0].date_added
         this.event_detail = event
         this.showmodal = true
      },
      async getEvents() {
         await axios
            .get("/api/v1/get/events/list")
            .then(res => {
               this.events = res.data.results
               this.setEventsInCalendar()
            })
      },
      setEventsInCalendar() {
         this.setYears()
         console.log(this.events)
         this.events.forEach(event => {
            let id = event.id
            event.eventsData.forEach(item => {
               const eventYear = moment(String(item.date_added)).format('YYYY')
               const eventMonth = moment(String(item.date_added)).format('MM')
               const eventDay = moment(String(item.date_added)).format('DD')
               this.tagsmoth.forEach(month => {
                  if (month.index == eventMonth && this.current_year == eventYear) {
                     month.tagsactive.push(eventDay)
                     month.id_event.push(id)
                  }
               })

            })
         })
      },
      setYears() {
         let arr = []
         this.events.forEach(event => {
            event.eventsData.forEach(item => {
               const year = moment(String(item.date_added)).format('YYYY')
               arr.push(year)
               this.arrayUnique(arr)
            })
         })
      },
      // formatedEventDate(item) {
      //    let date = item.eventsData[0].date_added
      //    return moment(String(date)).format('DD') + '.' + moment(String(date)).format('MM') + '.' + moment(String(date)).format('YY')
      // },
      // formatedEventTime(item) {
      //    let date = item.eventsData[0].date_added
      //    return moment(String(date)).format('HH') + ':' + moment(String(date)).format('MM')
      // },
      formatedEventDate(item) {
         let date = item.eventsData[0]?.date_added
         if (date) {
            return moment(String(date)).format('DD') + '.' + moment(String(date)).format('MM') + '.' + moment(String(date)).format('YY')
         } else {
            return "-"
         }
      },
      formatedEventTime(item) {
         let date = item.eventsData[0]?.date_added
         if (date) {
            return moment(String(date)).format('HH') + ':' + moment(String(date)).format('MM')
         } else {
            return "-"
         }
      },
      async loadSearchEvents() {
         this.events = [];

         for (let i = 0; i < this.tagsmoth.length; i++) {
            this.tagsmoth[i].tagsactive = [];
            this.tagsmoth[i].id_event = [];
         }

         await axios
            .get(`/api/v1/get/events/list?search=${this.eventsSearch}`)
            .then(res => {
               this.events = res.data.results
               console.log(res.data.results)
               this.setEventsInCalendar()
               // this.UpdateEvents(true);

            })
      },
      async loadFilterEvents() {
         await axios
            .get(`/api/v1/get/events/list?city=${this.city_id}&sport=${this.sport_id}&region=${this.region}`)
            .then(res => {
               console.log("events", res.data.results);
               this.events = res.data.results

            })
      },
      CfilerMoth(massive) {
         if (!this.fullView) {
            const arr = []
            let ind = 0

            massive.forEach(item => {
               if (this.curMoths.includes(ind)) {
                  console.log('nindex:', this.index_tags[arr.length])
                  arr.splice(this.index_tags[arr.length], 0, item);
               }
               ind++;
            })         
            return arr
         }
         else {
            return massive
         }
      },
      GetViewEvents(moth) {
         console.log(this.tagsmoth)
         if (String(moth) == '01') {
            this.curMoths = [8, 9, 10]
            this.index_tags = [0, 1, 2]
         }

         if (String(moth) == '02') {
            this.curMoths = [9, 10, 11]
            this.index_tags = [0, 1, 2]
         }

         if (String(moth) == '03') {
            this.curMoths = [0, 10, 11]
            this.index_tags = [2, 0, 1]
         }

         if (String(moth) == '04') {
            this.curMoths = [11, 0, 1]
            this.index_tags = [2, 1, 0]
         }

         if (String(moth) == '05') {
            this.curMoths = [0, 1, 2]
            this.index_tags = [0, 1, 2]
         }

         if (String(moth) == '06') {
            this.curMoths = [1, 2, 3]
            this.index_tags = [0, 1, 2]
         }

         if (String(moth) == '07') {
            this.curMoths = [2, 3, 4]
            this.index_tags = [0, 1, 2]
         }

         if (String(moth) == '08') {
            this.curMoths = [3, 4, 5]
            this.index_tags = [0, 1, 2]
         }

         if (String(moth) == '09') {
            this.curMoths = [4, 5, 6]
            this.index_tags = [0, 1, 2]
         }

         if (String(moth) == '10') {
            this.curMoths = [5, 6, 7]
            this.index_tags = [0, 1, 2]
         }

         if (String(moth) == '11') {
            this.curMoths = [6, 7, 8]
            this.index_tags = [0, 1, 2]
         }

         if (String(moth) == '12') {
            this.curMoths = [7, 8, 9]
            this.index_tags = [0, 1, 2]
         }
      },
      ValidateEvent(index, moth) {
         let check = 0;
         let index_massive = 1;

         for (let q = 0; q < this.tagsmoth.length; q++) {
            const itemnew = this.tagsmoth[q]

            if (itemnew.name == moth) {
               index_massive = q;
               // console.log('our event month is ', q);
            }

         }



         const items = this.tagsmoth[index_massive].tagsactive
         for (let i = 0; i < items.length; i++) {
            const item = items[i]

            if (index == item) {
               check = 1;
            }
         }

         if (check == 1) {
            return true
         } else {
            return false
         }

      },

      CurrentYear(year, id) {
         this.current_year = year;
         this.current_tag = id;

         this.UpdateEvents();
      },
      UpdateEvents(search=false) {
         this.events = [];

         for (let i = 0; i < this.tagsmoth.length; i++) {
            this.tagsmoth[i].tagsactive = [];
            this.tagsmoth[i].id_event = [];
         }

         // this.GetEventsList();
         if(!search) {
            this.getEvents();
         }
      },


      async GetCities() {

         await axios
            .get('/api/v1/all_cities/')
            .then(response => {
               this.cities = response.data
            })
            .catch(error => {
               console.log(error)
            })

      },
      async GetSports() {

         await axios
            .get('/api/v1/all_sports/')
            .then(response => {
               this.sports = response.data
            })
            .catch(error => {
               console.log(error)
            })

      },










      // async CheckOrderEvents() {

      //    await axios
      //       .get(`/api/v1/event_order/me/`)
      //       .then(response => {
      //          this.events_check = response.data
      //       })
      //       .catch(error => {
      //          console.log(error)
      //       })

      // },
      // async SendPUT(item) {
      //    const date_array_events = []

      //    if (item.date_added_1) {
      //       date_array_events.push(item.date_added_1)
      //    }

      //    if (item.date_added_2) {
      //       date_array_events.push(item.date_added_2)
      //    }

      //    if (item.date_added_3) {
      //       date_array_events.push(item.date_added_3)
      //    }

      //    if (item.date_added_4) {
      //       date_array_events.push(item.date_added_4)
      //    }

      //    if (item.date_added_5) {
      //       date_array_events.push(item.date_added_5)
      //    }

      //    if (item.date_added_6) {
      //       date_array_events.push(item.date_added_6)
      //    }

      //    if (item.date_added_7) {
      //       date_array_events.push(item.date_added_7)
      //    }

      //    if (item.date_added_8) {
      //       date_array_events.push(item.date_added_8)
      //    }

      //    if (item.date_added_9) {
      //       date_array_events.push(item.date_added_9)
      //    }

      //    if (item.date_added_10) {
      //       date_array_events.push(item.date_added_10)
      //    }

      //    for (let m = 0; m < date_array_events.length; m++) {
      //       const datarespose = date_array_events[m]
      //       const d1 = Date(datarespose);
      //       const day = moment(String(datarespose)).format('DD')
      //       const year = moment(String(datarespose)).format('YYYY')

      //       if ((Number(day) == Number(this.current_day) && (Number(year) == Number(this.current_year)))) {
      //          this.data_now_detail = datarespose
      //       }

      //    }

      //    const date = this.data_now_detail

      //    const year = moment(String(date)).format('YYYY')
      //    const day = moment(String(date)).format('DD')
      //    const moth = moment(String(date)).format('MM')

      //    const currentData = year + '-' + moth + '-' + day

      //    const dataForm = {
      //       event_id: item.id,
      //       date_added: currentData,
      //    }

      //    await axios
      //       .post(`/api/v1/events_order/`, dataForm)
      //       .then(response => {
      //          toast({
      //             message: 'Вы успешно записались на мероприятие',
      //             type: 'is-success',
      //             dismissible: true,
      //             pauseOnHover: true,
      //             duration: 5000,
      //             position: 'bottom-right',
      //          })

      //          this.event_detail.check_order = 1

      //          // this.sendEmail();
      //       })
      //       .catch(error => {
      //          console.log(error)
      //          toast({
      //             message: 'Произошла ошибка',
      //             type: 'is-danger',
      //             dismissible: true,
      //             pauseOnHover: true,
      //             duration: 5000,
      //             position: 'bottom-right',
      //          })
      //       })

      // },
      // GetInfoEvent(uid) {
      //    this.event_detail = this.event_details[uid]

      //    let check = 0

      //    for (let q = 0; q < this.events_check.length; q++) {
      //       const item = this.events_check[q]

      //       if (item.event_id == this.event_detail.id) {
      //          check = 1
      //       }

      //    }

      //    if (check == 1) {
      //       this.event_detail.check_order = 1
      //    } else {
      //       this.event_detail.check_order = 0
      //    }

      //    if (this.event_detail.file) {
      //       const file_event = this.event_detail.file
      //       const newStr = file_event.split("/")[2];
      //       this.event_detail.file = newStr
      //    }

      //    if (this.event_detail.get_image) {
      //       const DataNew = {
      //          image: this.event_detail.get_image,
      //       }
      //       this.images.push(DataNew)
      //    }

      //    if (this.event_detail.get_image2) {
      //       const DataNew = {
      //          image: this.event_detail.get_image2,
      //       }
      //       this.images.push(DataNew)
      //    }

      //    if (this.event_detail.get_image3) {
      //       const DataNew = {
      //          image: this.event_detail.get_image3,
      //       }
      //       this.images.push(DataNew)
      //    }

      //    if ((this.event_detail.get_image2 == "") && (this.event_detail.get_image3 == "")) {
      //       this.images = [];
      //       if (this.event_detail.get_image) {
      //          const DataNew = {
      //             image: this.event_detail.get_image,
      //          }
      //          this.images.push(DataNew)
      //       }
      //    }
      // },
      // async GetEventDetail(id_event, id_date) {

      //    await axios
      //       .get(`/api/v1/event/${id_event}/`)
      //       .then(response => {
      //          const item = response.data

      //          const date_array_events = []

      //          date_array_events.push(item.date_added_1)

      //          if (item.date_added_2) {
      //             date_array_events.push(item.date_added_2)
      //          }

      //          if (item.date_added_3) {
      //             date_array_events.push(item.date_added_3)
      //          }

      //          if (item.date_added_4) {
      //             date_array_events.push(item.date_added_4)
      //          }

      //          if (item.date_added_5) {
      //             date_array_events.push(item.date_added_5)
      //          }

      //          if (item.date_added_6) {
      //             date_array_events.push(item.date_added_6)
      //          }

      //          if (item.date_added_7) {
      //             date_array_events.push(item.date_added_7)
      //          }

      //          if (item.date_added_8) {
      //             date_array_events.push(item.date_added_8)
      //          }

      //          if (item.date_added_9) {
      //             date_array_events.push(item.date_added_9)
      //          }

      //          if (item.date_added_10) {
      //             date_array_events.push(item.date_added_10)
      //          }

      //          for (let m = 0; m < date_array_events.length; m++) {
      //             const datarespose = date_array_events[m]
      //             const d1 = Date(datarespose);
      //             const day = moment(String(datarespose)).format('DD')
      //             const year = moment(String(datarespose)).format('YYYY')

      //             let check__ids_event = this.event_details.find(ids => ids.id === item.id)

      //             if (check__ids_event == undefined) {
      //                if ((Number(day) == Number(id_date) && (Number(year) == Number(this.current_year)))) {
      //                   this.event_details.push(item)
      //                }
      //             }
      //          }


      //       })
      //       .catch(error => {
      //          console.log(error)
      //       })

      // },
      // async GetEventDetailOne(id_event) {

      //    await axios
      //       .get(`/api/v1/event/${id_event}/`)
      //       .then(response => {
      //          const data_event = response.data
      //          this.event_detail = data_event

      //          let check = 0

      //          for (let q = 0; q < this.events_check.length; q++) {
      //             const item = this.events_check[q]

      //             if (item.event_id == this.event_detail.id) {
      //                check = 1
      //             }

      //          }

      //          if (check == 1) {
      //             this.event_detail.check_order = 1
      //          } else {
      //             this.event_detail.check_order = 0
      //          }

      //          if (this.event_detail.file) {
      //             const file_event = this.event_detail.file
      //             const newStr = file_event.split("/")[2];
      //             this.event_detail.file = newStr
      //          }

      //          if (this.event_detail.get_image) {
      //             const DataNew = {
      //                image: this.event_detail.get_image,
      //             }
      //             this.images.push(DataNew)
      //          }

      //          if (this.event_detail.get_image2) {
      //             const DataNew = {
      //                image: this.event_detail.get_image2,
      //             }
      //             this.images.push(DataNew)
      //          }

      //          if (this.event_detail.get_image3) {
      //             const DataNew = {
      //                image: this.event_detail.get_image3,
      //             }
      //             this.images.push(DataNew)
      //          }
      //       })
      //       .catch(error => {
      //          console.log(error)
      //       })

      // },


      // async GetEventsAll() {
      //    await axios
      //       .get('/api/v1/all_events/')
      //       .then(response => {
      //          this.all_events = response.data

      //          const massiveold = []

      //          for (let q = 0; q < this.all_events.length; q++) {
      //             const item = this.all_events[q]

      //             const date_array_events = []

      //             date_array_events.push(item.date_added_1)

      //             if (item.date_added_2) {
      //                date_array_events.push(item.date_added_2)
      //             }

      //             if (item.date_added_3) {
      //                date_array_events.push(item.date_added_3)
      //             }

      //             if (item.date_added_4) {
      //                date_array_events.push(item.date_added_4)
      //             }

      //             if (item.date_added_5) {
      //                date_array_events.push(item.date_added_5)
      //             }

      //             if (item.date_added_6) {
      //                date_array_events.push(item.date_added_6)
      //             }

      //             if (item.date_added_7) {
      //                date_array_events.push(item.date_added_7)
      //             }

      //             if (item.date_added_8) {
      //                date_array_events.push(item.date_added_8)
      //             }

      //             if (item.date_added_9) {
      //                date_array_events.push(item.date_added_9)
      //             }

      //             if (item.date_added_10) {
      //                date_array_events.push(item.date_added_10)
      //             }

      //             for (let m = 0; m < date_array_events.length; m++) {
      //                const datarespose = date_array_events[m]

      //                const d1 = Date(datarespose);

      //                const year = moment(String(datarespose)).format('YYYY')

      //                massiveold.push(year)
      //             }

      //          }
      //          this.arrayUnique(massiveold)

      //       })
      //       .catch(error => {
      //          console.log(error)
      //       })
      // },
      // async GetDateTimeEvents(id_date) {

      //    await axios
      //       .get(`/api/v1/datetime_events/${id_date}/`)
      //       .then(response => {
      //          const data_ar = response.data
      //          this.datetime_events.push(data_ar)
      //       })
      //       .catch(error => {
      //          console.log(error)
      //       })

      // },
      // async GetEventsList() {
      //    if (this.city_id == 0) {
      //       this.GetEventsListAll()
      //    } else {
      //       await axios
      //          .get(`/api/v1/event_by_city/${this.city_id}/`)
      //          .then(response => {
      //             this.events = response.data.cities

      //             for (let q = 0; q < this.events.length; q++) {
      //                const item = this.events[q]
      //                const idrespose = this.events[q].id
      //                const datarespose = this.events[q].date_added_1
      //                const sportresponse = this.events[q].sport

      //                const date_array_events = []

      //                date_array_events.push(item.date_added_1)

      //                if (item.date_added_2) {
      //                   date_array_events.push(item.date_added_2)
      //                }

      //                if (item.date_added_3) {
      //                   date_array_events.push(item.date_added_3)
      //                }

      //                if (item.date_added_4) {
      //                   date_array_events.push(item.date_added_4)
      //                }

      //                if (item.date_added_5) {
      //                   date_array_events.push(item.date_added_5)
      //                }

      //                if (item.date_added_6) {
      //                   date_array_events.push(item.date_added_6)
      //                }

      //                if (item.date_added_7) {
      //                   date_array_events.push(item.date_added_7)
      //                }

      //                if (item.date_added_8) {
      //                   date_array_events.push(item.date_added_8)
      //                }

      //                if (item.date_added_9) {
      //                   date_array_events.push(item.date_added_9)
      //                }

      //                if (item.date_added_10) {
      //                   date_array_events.push(item.date_added_10)
      //                }

      //                for (let m = 0; m < date_array_events.length; m++) {
      //                   const datarespose = date_array_events[m]

      //                   const d1 = Date(datarespose);
      //                   const year = moment(String(datarespose)).format('YYYY')
      //                   const day = moment(String(datarespose)).format('DD')
      //                   const moth = moment(String(datarespose)).format('MM')

      //                   if (Number(this.current_year) == Number(year)) {

      //                      if (Number(this.sport_id) != 0) {

      //                         if (Number(this.sport_id) == Number(sportresponse)) {

      //                            if (String(moth) == '05') {
      //                               this.tagsmoth[0].tagsactive.push(Number(day))
      //                               this.tagsmoth[0].id_event.push(Number(idrespose))
      //                            }

      //                            if (String(moth) == '06') {
      //                               this.tagsmoth[1].tagsactive.push(Number(day))
      //                               this.tagsmoth[1].id_event.push(Number(idrespose))
      //                            }

      //                            if (String(moth) == '07') {
      //                               this.tagsmoth[2].tagsactive.push(Number(day))
      //                               this.tagsmoth[2].id_event.push(Number(idrespose))
      //                            }

      //                            if (String(moth) == '08') {
      //                               this.tagsmoth[3].tagsactive.push(Number(day))
      //                               this.tagsmoth[3].id_event.push(Number(idrespose))
      //                            }

      //                            if (String(moth) == '09') {
      //                               this.tagsmoth[4].tagsactive.push(Number(day))
      //                               this.tagsmoth[4].id_event.push(Number(idrespose))
      //                            }

      //                            if (String(moth) == '10') {
      //                               this.tagsmoth[5].tagsactive.push(Number(day))
      //                               this.tagsmoth[5].id_event.push(Number(idrespose))
      //                            }

      //                            if (String(moth) == '11') {
      //                               this.tagsmoth[6].tagsactive.push(Number(day))
      //                               this.tagsmoth[6].id_event.push(Number(idrespose))
      //                            }

      //                            if (String(moth) == '12') {
      //                               this.tagsmoth[7].tagsactive.push(Number(day))
      //                               this.tagsmoth[7].id_event.push(Number(idrespose))
      //                            }

      //                            if (String(moth) == '01') {
      //                               this.tagsmoth[8].tagsactive.push(Number(day))
      //                               this.tagsmoth[8].id_event.push(Number(idrespose))
      //                            }

      //                            if (String(moth) == '02') {
      //                               this.tagsmoth[9].tagsactive.push(Number(day))
      //                               this.tagsmoth[9].id_event.push(Number(idrespose))
      //                            }

      //                            if (String(moth) == '03') {
      //                               this.tagsmoth[10].tagsactive.push(Number(day))
      //                               this.tagsmoth[10].id_event.push(Number(idrespose))
      //                            }

      //                            if (String(moth) == '04') {
      //                               this.tagsmoth[11].tagsactive.push(Number(day))
      //                               this.tagsmoth[11].id_event.push(Number(idrespose))
      //                            }
      //                         }
      //                      } else {
      //                         if (String(moth) == '05') {
      //                            this.tagsmoth[0].tagsactive.push(Number(day))
      //                            this.tagsmoth[0].id_event.push(Number(idrespose))
      //                         }

      //                         if (String(moth) == '06') {
      //                            this.tagsmoth[1].tagsactive.push(Number(day))
      //                            this.tagsmoth[1].id_event.push(Number(idrespose))
      //                         }

      //                         if (String(moth) == '07') {
      //                            this.tagsmoth[2].tagsactive.push(Number(day))
      //                            this.tagsmoth[2].id_event.push(Number(idrespose))
      //                         }

      //                         if (String(moth) == '08') {
      //                            this.tagsmoth[3].tagsactive.push(Number(day))
      //                            this.tagsmoth[3].id_event.push(Number(idrespose))
      //                         }

      //                         if (String(moth) == '09') {
      //                            this.tagsmoth[4].tagsactive.push(Number(day))
      //                            this.tagsmoth[4].id_event.push(Number(idrespose))
      //                         }

      //                         if (String(moth) == '10') {
      //                            this.tagsmoth[5].tagsactive.push(Number(day))
      //                            this.tagsmoth[5].id_event.push(Number(idrespose))
      //                         }

      //                         if (String(moth) == '11') {
      //                            this.tagsmoth[6].tagsactive.push(Number(day))
      //                            this.tagsmoth[6].id_event.push(Number(idrespose))
      //                         }

      //                         if (String(moth) == '12') {
      //                            this.tagsmoth[7].tagsactive.push(Number(day))
      //                            this.tagsmoth[7].id_event.push(Number(idrespose))
      //                         }

      //                         if (String(moth) == '01') {
      //                            this.tagsmoth[8].tagsactive.push(Number(day))
      //                            this.tagsmoth[8].id_event.push(Number(idrespose))
      //                         }

      //                         if (String(moth) == '02') {
      //                            this.tagsmoth[9].tagsactive.push(Number(day))
      //                            this.tagsmoth[9].id_event.push(Number(idrespose))
      //                         }

      //                         if (String(moth) == '03') {
      //                            this.tagsmoth[10].tagsactive.push(Number(day))
      //                            this.tagsmoth[10].id_event.push(Number(idrespose))
      //                         }

      //                         if (String(moth) == '04') {
      //                            this.tagsmoth[11].tagsactive.push(Number(day))
      //                            this.tagsmoth[11].id_event.push(Number(idrespose))
      //                         }
      //                      }
      //                   }
      //                }
      //             }

      //          })
      //          .catch(error => {
      //             console.log(error)
      //          })
      //    }
      // },
      // async GetEventsListAll() {

      //    await axios
      //       .get(`/api/v1/all_events/`)
      //       .then(response => {
      //          this.events = response.data

      //          for (let q = 0; q < this.events.length; q++) {
      //             const item = this.events[q]
      //             const idrespose = this.events[q].id
      //             const datarespose = this.events[q].date_added_1
      //             const sportresponse = this.events[q].sport

      //             const date_array_events = []

      //             date_array_events.push(item.date_added_1)

      //             if (item.date_added_2) {
      //                date_array_events.push(item.date_added_2)
      //             }

      //             if (item.date_added_3) {
      //                date_array_events.push(item.date_added_3)
      //             }

      //             if (item.date_added_4) {
      //                date_array_events.push(item.date_added_4)
      //             }

      //             if (item.date_added_5) {
      //                date_array_events.push(item.date_added_5)
      //             }

      //             if (item.date_added_6) {
      //                date_array_events.push(item.date_added_6)
      //             }

      //             if (item.date_added_7) {
      //                date_array_events.push(item.date_added_7)
      //             }

      //             if (item.date_added_8) {
      //                date_array_events.push(item.date_added_8)
      //             }

      //             if (item.date_added_9) {
      //                date_array_events.push(item.date_added_9)
      //             }

      //             if (item.date_added_10) {
      //                date_array_events.push(item.date_added_10)
      //             }

      //             for (let m = 0; m < date_array_events.length; m++) {
      //                const datarespose = date_array_events[m]

      //                const d1 = Date(datarespose);
      //                const year = moment(String(datarespose)).format('YYYY')
      //                const day = moment(String(datarespose)).format('DD')
      //                const moth = moment(String(datarespose)).format('MM')

      //                if (Number(this.current_year) == Number(year)) {

      //                   if (Number(this.sport_id) != 0) {

      //                      if (Number(this.sport_id) == Number(sportresponse)) {

      //                         if (String(moth) == '05') {
      //                            this.tagsmoth[0].tagsactive.push(Number(day))
      //                            this.tagsmoth[0].id_event.push(Number(idrespose))
      //                         }

      //                         if (String(moth) == '06') {
      //                            this.tagsmoth[1].tagsactive.push(Number(day))
      //                            this.tagsmoth[1].id_event.push(Number(idrespose))
      //                         }

      //                         if (String(moth) == '07') {
      //                            this.tagsmoth[2].tagsactive.push(Number(day))
      //                            this.tagsmoth[2].id_event.push(Number(idrespose))
      //                         }

      //                         if (String(moth) == '08') {
      //                            this.tagsmoth[3].tagsactive.push(Number(day))
      //                            this.tagsmoth[3].id_event.push(Number(idrespose))
      //                         }

      //                         if (String(moth) == '09') {
      //                            this.tagsmoth[4].tagsactive.push(Number(day))
      //                            this.tagsmoth[4].id_event.push(Number(idrespose))
      //                         }

      //                         if (String(moth) == '10') {
      //                            this.tagsmoth[5].tagsactive.push(Number(day))
      //                            this.tagsmoth[5].id_event.push(Number(idrespose))
      //                         }

      //                         if (String(moth) == '11') {
      //                            this.tagsmoth[6].tagsactive.push(Number(day))
      //                            this.tagsmoth[6].id_event.push(Number(idrespose))
      //                         }

      //                         if (String(moth) == '12') {
      //                            this.tagsmoth[7].tagsactive.push(Number(day))
      //                            this.tagsmoth[7].id_event.push(Number(idrespose))
      //                         }

      //                         if (String(moth) == '01') {
      //                            this.tagsmoth[8].tagsactive.push(Number(day))
      //                            this.tagsmoth[8].id_event.push(Number(idrespose))
      //                         }

      //                         if (String(moth) == '02') {
      //                            this.tagsmoth[9].tagsactive.push(Number(day))
      //                            this.tagsmoth[9].id_event.push(Number(idrespose))
      //                         }

      //                         if (String(moth) == '03') {
      //                            this.tagsmoth[10].tagsactive.push(Number(day))
      //                            this.tagsmoth[10].id_event.push(Number(idrespose))
      //                         }

      //                         if (String(moth) == '04') {
      //                            this.tagsmoth[11].tagsactive.push(Number(day))
      //                            this.tagsmoth[11].id_event.push(Number(idrespose))
      //                         }
      //                      }
      //                   } else {
      //                      if (String(moth) == '05') {
      //                         this.tagsmoth[0].tagsactive.push(Number(day))
      //                         this.tagsmoth[0].id_event.push(Number(idrespose))
      //                      }

      //                      if (String(moth) == '06') {
      //                         this.tagsmoth[1].tagsactive.push(Number(day))
      //                         this.tagsmoth[1].id_event.push(Number(idrespose))
      //                      }

      //                      if (String(moth) == '07') {
      //                         this.tagsmoth[2].tagsactive.push(Number(day))
      //                         this.tagsmoth[2].id_event.push(Number(idrespose))
      //                      }

      //                      if (String(moth) == '08') {
      //                         this.tagsmoth[3].tagsactive.push(Number(day))
      //                         this.tagsmoth[3].id_event.push(Number(idrespose))
      //                      }

      //                      if (String(moth) == '09') {
      //                         this.tagsmoth[4].tagsactive.push(Number(day))
      //                         this.tagsmoth[4].id_event.push(Number(idrespose))
      //                      }

      //                      if (String(moth) == '10') {
      //                         this.tagsmoth[5].tagsactive.push(Number(day))
      //                         this.tagsmoth[5].id_event.push(Number(idrespose))
      //                      }

      //                      if (String(moth) == '11') {
      //                         this.tagsmoth[6].tagsactive.push(Number(day))
      //                         this.tagsmoth[6].id_event.push(Number(idrespose))
      //                      }

      //                      if (String(moth) == '12') {
      //                         this.tagsmoth[7].tagsactive.push(Number(day))
      //                         this.tagsmoth[7].id_event.push(Number(idrespose))
      //                      }

      //                      if (String(moth) == '01') {
      //                         this.tagsmoth[8].tagsactive.push(Number(day))
      //                         this.tagsmoth[8].id_event.push(Number(idrespose))
      //                      }

      //                      if (String(moth) == '02') {
      //                         this.tagsmoth[9].tagsactive.push(Number(day))
      //                         this.tagsmoth[9].id_event.push(Number(idrespose))
      //                      }

      //                      if (String(moth) == '03') {
      //                         this.tagsmoth[10].tagsactive.push(Number(day))
      //                         this.tagsmoth[10].id_event.push(Number(idrespose))
      //                      }

      //                      if (String(moth) == '04') {
      //                         this.tagsmoth[11].tagsactive.push(Number(day))
      //                         this.tagsmoth[11].id_event.push(Number(idrespose))
      //                      }
      //                   }
      //                }
      //             }
      //          }

      //       })
      //       .catch(error => {
      //          console.log(error)
      //       })

      // },










      sendForm() {
         toast({
            message: 'Вы успешно записались на мероприятие',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
         })

         this.showmodal = false;
         this.info = 0;
      },
      closeModal() {
         this.showmodal = false;
         this.info = 0;
         this.current_day = 0;
         this.event_detail = {};
         this.event_details = [];
         this.images = [];
         this.data_now_detail = "";
      },
      backModal() {
         this.info = 0;
         this.event_detail = {};
         this.data_now_detail = "";
         this.images = [];
      },
      redirectTo(link) {
         this.$router.push(link)
      },
      handleScroll() {
         if
            (
            window.scrollY + window.innerHeight >=
            document.body.scrollHeight - 550
            &&
            this.page <= 12
         ) {
            for (var i = 0; i < 3; i++) {
               this.page++
            }
         }
      },

   },
   watch: {
      current_year() {
         let tmp = []
         this.events.forEach(event => {
            let id = event.id
            event.eventsData.forEach(item => {
               const eventYear = moment(String(item.date_added)).format('YYYY')
               if (eventYear == this.current_year) [
                  tmp.push(event)
               ]
            })
         })
         this.events = tmp
      },
      eventsSearch() {
         if (this.eventsSearch.length) {
            this.$refs.searchLabel.style.display = 'none'
         } else {
            this.$refs.searchLabel.style.display = ''

         }
      },
      region() {
         this.loadFilterEvents()
      }
   },
   computed: {

   }
}
</script>