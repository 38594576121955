<template>
  <div class="paddingzero">

    <!--     <Header :cart_count="cartTotalLength" v-if="($route.path != '/log-in') && ($route.path != '/sign-up') && ($route.path != '/agree') && ($route.path != '/sub_main') && ($route.path != '/sub_success') && ($route.path != '/sub_error') && ($route.path != '/sub_status')" /> -->

    <div class="is-loading-bar has-text-centered" v-bind:class="{'is-loading': $store.state.isLoading }">
      <div class="lds-dual-ring"></div>
    </div>

    <section>
      <router-view/>
      <vue-progress-bar />
    </section>

  </div>

  <!--   <Footer v-if="($route.path != '/log-in') && ($route.path != '/sign-up') && ($route.path != '/partners') && ($route.path != '/my-account') && ($route.path != '/agree') && ($route.path != '/edit_profile') && ($route.path != '/sub_main') && ($route.path != '/sub_success') && ($route.path != '/sub_error') && ($route.path != '/sub_status')"/> -->
</template>

<style type="text/css">
@import '@/assets/css/Main.css';

@keyframes scale {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
  45% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0.7; }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; } }

.ball-pulse > div:nth-child(1) {
  -webkit-animation: scale 0.75s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
          animation: scale 0.75s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08); }

.ball-pulse > div:nth-child(2) {
  -webkit-animation: scale 0.75s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
          animation: scale 0.75s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08); }

.ball-pulse > div:nth-child(3) {
  -webkit-animation: scale 0.75s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
          animation: scale 0.75s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08); }

.ball-pulse > div {
    background-color: #ccc !important;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin-right: 8px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    display: inline-block;
  }

.loader_loading {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.sfil:last-child {
    display: block !important;
}

.all--product-link {
    font-size: 16px;
    letter-spacing: -1px;
    font-weight: 500;
    color: #EB3624;    
}

.box--pick-zakaz {
    border-radius: 6px;
    color: #4a4a4a;
    display: block;
    padding: 1.5rem !important;
    border: 1px solid #eaeaea;
    transition: 0.4s;
}

.samov_active--box {
    background-color: #fffaf8 !important;
    color: #111 !important;
    transform: scale(1.075) !important;
}

.help_text {
    font-size: 14px;
    letter-spacing: -1px;
    font-weight: 500;
    color: #ccc;
    line-height: 18px;      
}

.active--box {
    background-color: #EB3624 !important;
    transform: scale(1) !important;
    color: #fff !important;
}

.dostavka--box {
    background-color: #fffaf8;
    color: #111;
    box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
    transform: scale(1.075);
}

.dostavka--box:hover {
    transform: scale(1);
    background-color: #eaeaea !important;
    box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%) !important;
}

.samovivoz--box {
    background-color: #fffaf8;
    color: #111;
    box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
    transform: scale(1.075);
}

.samovivoz--box:hover {
    transform: scale(1);
    background-color: #eaeaea !important;
    box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%) !important;

}

.product--cart-title {
    color: #000;
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
}


.sfil {
    display: none;
}

.mbp-cart {
    margin-bottom: 40px;
}

.cart--etap {
    border: 1px solid #eaeaea;
    border-radius: 6px;
}


.radio--pick {
    text-align: center;
    margin-left: -13px !important;    
}

.cart--box {
    background-color: transparent;
    border-radius: 6px;
    box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
    color: #4a4a4a;
    display: block;
    padding: 1.5rem !important;
    margin-bottom: 30px;
}

.bg--cartbox {
    background-color: transparent !important;
}

.cart--total--amount {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.5px !important;
}

.cart--total--price {
    color: #000;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: -0.5px !important;
}

.cart--container {
    padding-top: 30px;
}

.radio input, .checkbox input {
    opacity: 0 !important;
}

.button--cart-product-s:hover {
  color: #fafafa !important;
  transform: scale(1.01) !important;
}

.button--cart-product-s {
    display: block;
    width: 100%;
    letter-spacing: -1px;
    padding: 0rem 1.5rem;
    color: #fff;
    text-align: center;
    font-size: 15px;
    line-height: 40px;
    border-radius: 4px;
    font-weight: 500;
    align-items: center;     
}

.online-bg {
    background-color: #EB3624 !important;
}

.bg-samovivoz {
    background-color: #0F1D37 !important;
}

.cart--button {
    display: block;
    width: 100%;
    letter-spacing: -1px;
    padding: 0rem 1.5rem;
    background-color: #EB3624 !important;
    color: #fff;
    text-align: center;
    font-size: 15px;
    line-height: 40px;
    border-radius: 4px;
    font-weight: 500;
    align-items: center;    
}

.fsop--textss {
  color: #D63727;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 30px;
}

.back--modal--footer {
    position: absolute;
    left: 32px;
    top: 32px;
    width: 16px;
    height: 20px;
    opacity: 1;
}

.activeses--span-events {
  background-color: #EB3624 !important;
  transition: 0.2s ease;
}

.active--togle-event {
  color: #fff !important;
}

.filter--block--events {
  display: flex;
  margin-top: 20px;
  margin-bottom: 40px;
}

.event_name {
  color: #CCC;
  font-size: 16px;
  font-weight: 600;  
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

.link--document {
  color: #D63727;
  font-size: 14px;
  font-weight: 500;
}

.option-events-select {
  background-color: #0F1D37 !important;
  color: #fff !important;
}

.li_event {

}

.select-form--events {
  z-index: 999;
  border-radius: 10px;
  border: 1px solid #fff !important;
  color: #fff;
  margin-right: 30px;
  background-color: transparent !important;
  font-weight: 600;
  height: 45px;
  width: 100%;
  margin-right: 20px;
  padding: 0.3rem 1.1rem;
}

.full-color--events {
  width: 100%;
  height: 100%;
  background-color: #0F1D37;
  padding-bottom: 60px;
}

.row--date {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.icon--events-span {

  margin-bottom: 20px;
  text-align: center;
  width: 28px;
  height: 28px;
  vertical-align: middle;
  line-height: 28px;
  /*padding: 0rem 0.5rem;*/
  border-radius: 50%;
  font-weight: 600;
  
  margin-right: 10px;
}

.active--span-events {
  background: #D63727;
}

.deactive--span-events {
  background: #E8EFF9;
}

.moth--h4-events {
  color: #fff;
  font-weight: 600;
  margin-bottom: 30px;
  font-size: 18px;
}

.events--h4 {
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
}

.iconred {
  display: block;
  width: 14px;
  transform: translateY(1px);
  height: 14px;
  border-radius: 50%;
  background: #D63727;
  margin-right: 10px;
}

.modal-event--text {
    color: #fff;
    margin-bottom: 25px;
    font-weight: 500;
    font-size: 16px;  
}


.activeses--span-events:hover {
  transform: scale(1.05) !important;
}

.active--togle-event:hover {
  color: #fff !important;
}

</style>

<script>
  import axios from 'axios'
  // import Header from '@/components/Header'
  // import Footer from '@/components/Footer'
  import 'vue3-carousel/dist/carousel.css';
  import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

  export default {
    data() {
      return {
        showMobileMenu: false,
        showModal: false,
        cart: {
          items: []
        }
      }
    },
    // components: {
    //   Header,
    //   Footer
    // },
    beforeCreate() {
      this.$store.commit('initializeStore')

      const token = this.$store.state.token

      if (token) {
        axios.defaults.headers.common['Authorization'] = "Token " + token
      } else {
        axios.defaults.headers.common['Authorization'] = ""
      }
    },
    mounted() {
      this.$Progress.finish();
      this.cart = this.$store.state.cart
      document.title = 'Общероссийская общественная организации – физкультурно-спортивного общества профсоюзов «Россия»'
      // let cvs = document.createElement('script')
      // cvs.setAttribute('src', '//code-ya.jivosite.com/widget/yrJ2kH18ow')
      // document.head.appendChild(cvs)
    },
    created() {

      this.$Progress.start();

      this.$router.beforeEach((to, from, next) => {

        if (to.meta.progress !== undefined) {
          let meta = to.meta.progress;

          this.$Progress.parseMeta(meta);
        }

        this.$Progress.start();

        next();
      });

      this.$router.afterEach((to, from) => {
        this.$Progress.finish();
      });
    },  
    computed: {
      cartTotalLength() {
        let totalLength = 0

        for (let i = 0; i < this.cart.items.length; i++) {
          totalLength += this.cart.items[i].quantity
        }

        return totalLength
      }
    }
  }
</script>

<style lang="scss">
@import '../node_modules/bulma';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

body {
  font-family: "Montserrat", sans-serif;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #ccc;
  border-color: #ccc transparent #ccc transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.is-loading-bar {
  height: 0;
  overflow: hidden;

  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  &.is-loading {
    height: 80px;
  }
}
</style>
