<template>
  <div class="product-carousel-slider">
    <h3 class="product--main"
    v-bind:class="{ colorprodcuct: black }">{{ title }}</h3>
    <swiper
    :modules="modules"
    :breakpoints="swiperOptions.breakpoints"
    navigation
    @swiper="onSwiper"
    @slideChange="onSlideChange"
    >
    <swiper-slide v-for="(prod, index) in products" :key="prod">
      <router-link :to="prod.get_absolute_url">
        <div class="img--inner">
          <img class="img-fluid img-fluid--hovered" :src="prod.get_image">
        </div>
      </router-link>
      <div class="block-tittle--product">
       <router-link :to="prod.get_absolute_url" class="product--title" 
       v-bind:class="{ colorprodcuct: black }">{{ prod.name }}
     </router-link>

     <h4 class="product--price" v-bind:class="{ colorprodcuct: black }">
      <span v-if="prod.discount" class="product--discount">{{ prod.discount }} Руб.</span>
      <span v-if="prod.discount" class="text--product-s">
        {{ prod.price }} Руб.
      </span>
      <span v-else>
        {{ prod.price }} Руб.
      </span>
    </h4>            

  </div>

  <button v-if="prod.amount > 0" class="product--btn" @click="addToCart(index)">Добавить в корзину</button>         
  <button v-else class="product--btn-disabled" disabled="disabled">Нет в наличии</button>
</swiper-slide>

</swiper>

</div>
</template>

<script>
  import { toast } from 'bulma-toast'
  import axios from 'axios'

  // import Swiper core and required modules
  import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';

  // Import Swiper styles
  import 'swiper/css';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  import 'swiper/css/scrollbar';

  export default {
    name: 'TheBestProduct',
    components: {
      Swiper,
      SwiperSlide,
    },
    props: {
      title: String,
      black: {
        type: Boolean,
        default: false      
      },
    // products: Object
  },
  mounted() {
    this.getLatestProducts(this.page)
    this.cart = this.$store.state.cart
  },
  setup() {
    const onSwiper = (swiper) => {
    };
    const onSlideChange = () => {
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  data() {
    return {
      cart: {
        items: []
      },
      quantity: 1,
      products: [],
      page: 1,
      cart_items: [],
      total: 1,
      cart_product: [],    
        swiperOptions: {
          breakpoints: {       
              320: {       
                 slidesPerView: 2,
                 spaceBetween: 30     
              },          
              770: {       
                 slidesPerView: 3,       
                 spaceBetween: 30     
              },   
          
              1024: {       
                 slidesPerView: 5.5,       
                 spaceBetween: 30     
              } 
           }
      }   

    }
  },
  methods: {
   async getLatestProducts(pageNumber) {
    this.$store.commit('setIsLoading', true)

    await axios
    .get(`/api/v1/latest-products/?page=${pageNumber}`)
    .then(response => {
      this.total = response.data.count
      this.products = response.data.results
    })
    .catch(error => {
      console.log(error)
    })

    this.$store.commit('setIsLoading', false)
  },
  addToCart(index) {

    let check_amount = 0;

    if (this.cart.items.length > 0) {
      for (let q = 0; q < this.cart.items.length; q++) {
        const item = this.cart.items[q]           
        let id = item.product.id

        if (id == this.products[index].id) {
          if (item.quantity >= this.products[index].amount) {
            check_amount = 1
          }
        }

      }        
    }

    if (check_amount == 0) {

      const item = {
        product: this.products[index],
        quantity: this.quantity
      }

      this.$store.commit('addToCart', item)

      toast({
        message: 'Товар был добавлен в корзину',
        type: 'is-success',
        dismissible: true,
        pauseOnHover: true,
        duration: 2000,
        position: 'bottom-right',
      })
    } else {
      toast({
        message: 'Товар больше нет в наличии',
        type: 'is-danger',
        dismissible: true,
        pauseOnHover: true,
        duration: 2000,
        position: 'bottom-right',
      })
    }

  }, 
}  
}
</script>