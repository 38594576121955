<template>

  <HeaderFsop />
<!-- 

      <div class="container paddingcontainernew">
          <div class="footer-menu--block" style="padding-bottom: 30px !important;">
              <h3 class="contact">О Спортобществе «Россия»</h3>
          </div>
      </div>

      <div class="position-relative max-h--aboutfsop mb-5">
        <img src="@/assets/aboutfsop.png" class="img-fluid w-100">
        <div class="back--aboutfsop-img"></div>
        <div class="text-absolute-fsop paddingcontainernew">
          <h4 class="fsop---text-abs container">
            ФИЗКУЛЬТУРНО-СПОРТИВНОЕ ОБЩЕСТВО <br>
            ПРОФСОЮЗОВ «РОССИЯ»
          </h4>
        </div>
        
      </div> -->

      <div class="container paddingcontainernew">

        <div class="block--fsop-team">
          <div class="footer-menu--block">
              <h3 class="contact">Документы</h3>
          </div>

          <div class="row document-list__block" v-for="item in documents">
            <div class="col-lg-9">
              <a :href="item.get_document" target="_blank" rel="noopener" class="main-team-h3 document-list__block__text">{{ item.name }}</a>
            </div>
            <div class="col-lg-3 right-documents__date">
              <h4 class="main-team-h4 right-documents__date document-list__block__text">от {{ formatedDate(item.date_added)}}</h4>
            </div>
          </div> 
        </div>
      </div>
  <FooterFsop />

</template>

<style type="text/css">
  .document-list__block:nth-child(2n) {
    background-color: rgba(214,55,39,.2) !important;
  }

  .document-list__block__text  {
    margin: 0px !important;
    font-weight: 500 !important;
    letter-spacing: -0.5px !important;
  }

  .document-list__block {
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    align-content: center;
    width: 100%;
    padding: 1.4rem 1.75rem;
  }


  .right-documents__date {
    text-align: right !important;
  }
</style>

<script>
  import moment from 'moment';
  import HeaderFsop from '@/components/Header/HeaderFsop'
  import FooterFsop from '@/components/Footer/FooterFsop'  
  import axios from 'axios'
  import { toast } from 'bulma-toast'

  export default {
    name: 'Documents',
    data() {
      return {
        page: 1,
        total: 1,
        news: [],
        blockactive: true,
        team_first: {},
        teams: [],
        newteam: [],
        jobs_team: [],
        documents: [],
        id_button: "",
        name__tags: "О нас",
        }
    },
    components: {
      HeaderFsop,
      FooterFsop
    },
    mounted() {
      this.GetDocuments()
    },
    methods: {
        async GetDocuments() {

          await axios
          .get('/api/v1/get/documents/')
          .then(response => {
            this.documents = response.data
            console.log(response.data)
          })
          .catch(error => {
            console.log(error)
          })

        },
        formatedDate(date) {
           if (date) {
              return moment(String(date)).format('DD') + '.' + moment(String(date)).format('MM') + '.' + moment(String(date)).format('YY')
           } else {
              return "-"
           }
        },


    }
  }
</script>