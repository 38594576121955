<template>
    <div class="full--auth--back">

        <HeaderSmall />

        <div class="position--auth-form">
            <div class="max--form">
                <h4 class="pb-3 h4--sub-main">Пожалуйста подождите...</h4>
            </div>
        </div>

        <Footersmall />

    </div>
</template>

<script>
    import { toast } from 'bulma-toast'
    import axios from 'axios'

    import HeaderSmall from '@/components/Header/HeaderSmall'
    import Footersmall from '@/components/Footer/Footersmall'

    export default {
        name: 'StatusSubs',
        data() {
            return {
                sub_id: "",
                status: {},
                status_payment: "",
            }
        },
        components: {
            HeaderSmall,
            Footersmall
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {
            document.title = 'Статус подписки'
            this.alert();

            this.sub_id = this.$store.state.sub_id

            this.statusNotifacation();

            setTimeout(() => {
                this.redirectStatus();
            }, 1000)

            
        },
        methods: {
            async statusNotifacation() {
                await axios
                    .get(`/api/v1/paymentStatus/${this.sub_id}/`)
                    .then(response => {
                        this.status = response.data

                        for (let i = 0; i < this.status.length; i++) {
                            const item = this.status[i]

                            if (item[1] == 'succeeded') {
                                this.status_payment = true
                            }
                        }

                    })
                    .catch(error => {
                        console.log(error)
                    })                
            },
            redirectStatus() {
                if (this.status_payment == true) {
                    this.$store.commit('setPayStatus', this.status_payment)

                    localStorage.setItem("status_pay", this.status_payment)

                    this.$router.push('/sub_success')
                } else {
                    this.$router.push('/sub_error')
                }
            },
            alert() {
                toast({
                    message: 'Пожалуйста подождите....',
                    type: 'is-info',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'bottom-right',
                })
            },
        },
        computed: {
      
        },
    }
</script>