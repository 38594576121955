<template>
    <Header />

    <div class="container paddingcontainernew">
        <div class="product-block">
            <h1 class="title">Поиск</h1>
            <h2 class="is-size-5 has-text-grey pb-lg-4" style="font-weight: 500; color: #111;">Результат по запросу: "{{ query }}"</h2>
        </div>
        <div>
            <ProductBox 
                :product="products" />

            <div class="divHomeCenter">
              <router-link to="/all_product">
                <div class="btnHome">
                  Все товары
              </div>
              </router-link>          
            </div>
        </div>

    
</div>

<Footer />

</template>

<script>
    import axios from 'axios'
    import Header from '@/components/Header/Header'
    import Footer from '@/components/Footer/Footer'
    import ProductBox from '@/components/ProductBox'

    export default {
        name: 'Search',
        components: {
            Header,
            Footer,
            ProductBox
        },
        data() {
            return {
                products: [],
                query: ''
            }
        },
        mounted() {
            document.title = 'Поиск'

            let uri = window.location.search.substring(1)
            let params = new URLSearchParams(uri)

            if (params.get('query')) {
                this.query = params.get('query')

                this.performSearch()
            }
        },
        methods: {
            async performSearch() {
                this.$store.commit('setIsLoading', true)

                await axios
                .post('/api/v1/products/search/', {'query': this.query})
                .then(response => {
                    this.products = response.data
                })
                .catch(error => {
                    console.log(error)
                })

                this.$store.commit('setIsLoading', false)
            }
        }
    }
</script>