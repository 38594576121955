<template>

  <HeaderFsop />

      <div class="container paddingcontainernew">

        <div class="block--fsop-team">
          <div class="footer-menu--block" style="padding-bottom: 30px !important;">
              <h3 class="contact">Попечительский совет</h3>
          </div>

          <div class="row marginb-6" v-if="newteam.length != 0">
            
            <div class="col-lg-6 aboutmargin-bottomteam">
              <router-link :to="'/trustees/' + team_first.id">
                <div class="position-relative" style="overflow: hidden !important; max-width: 570px;">
                  <img :src="team_first.get_image" class="img-fluid hover-scale">
                </div>
              </router-link>
            </div>
            

            <div class="col-lg-6">
              <div class="px-4">
                <h3 class="main-team-h3">{{ team_first.name }}</h3>
                <h4 class="main-team-h4" v-if="team_first.work">{{ team_first.work.name }}</h4>
                <p class="text--fluid--teammain" v-html="team_first.description"></p>
              </div>
            </div>
          </div> 

          <div class="row marginb-5" v-if="newteam.length != 0">
            <div class="col-lg-4 aboutmargin-bottomteam" v-for="(item, index) in newteam" v-show="index > 0">
              <router-link :to="'/trustees/' + item.id">
                <div class="position-relative max-h--teamfsopabout">
                  <img :src="item.get_image" :alt="item.name" class="img-fluid hover-scale" style="width: 100%;">
                </div>
                
                <h4 class="team--h4-fsopabout" v-if="item.work">{{ item.work.name }}</h4>
                <p class="team--p-fsopabout">{{ item.name }}</p>
              </router-link>
            </div>

          </div>


        </div>


      </div>


  <FooterFsop />

</template>

<style type="text/css">

</style>

<script>
  import HeaderFsop from '@/components/Header/HeaderFsop'
  import FooterFsop from '@/components/Footer/FooterFsop'  
  import axios from 'axios'
  import { toast } from 'bulma-toast'

  export default {
    name: 'Trustees',
    data() {
      return {
        page: 1,
        total: 1,
        news: [],
        tags: [
          {name: "О нас"},
          {name: "История"},
          {name: "Документы"}
        ],
        blockactive: true,
        team_first: {},
        teams: [],
        newteam: [],
        jobs_team: [],
        documents: [],
        id_button: "",
        name__tags: "О нас",
        }
    },
    components: {
      HeaderFsop,
      FooterFsop
    },
    mounted() {
      this.GetTrustees();
    },
    methods: {
        GetNewsBlock(index, name) {
          this.id_button = index;
          this.name__tags = name;
        },      
        async GetTrustees() {

          await axios
          .get('/api/v1/get/trustees/list/')
          .then(response => {
            this.newteam = response.data

            console.log(response.data)

            this.team_first = this.newteam[0]
          })
          .catch(error => {
            console.log(error)
          })

        },
    }
  }
</script>