<template>

  <Header />

    <div class="container paddingcontainernew">
      <div class="product-block">
           
        <ProductList :paginate="true" :title_on="true" title="ТОВАРЫ СПОРТОБЩЕСТВА" />
      </div>

    </div>

  <Footer />
</template>

<script>
  import axios from 'axios'

  import Header from '@/components/Header/Header'
  import Footer from '@/components/Footer/Footer'

  import ProductList from '@/components/ProductList'
  export default {
    name: 'Home',
    data() {
      return {
        items: [],
        productsCategory: [],
        bannerss: [],
        page: 1,
        total: 1
      }
    },
    components: {
      ProductList,
      Header,
      Footer
    },
    mounted() {
      this.getLatestProducts(this.page)

      document.title = 'Все товары'
    },
    methods: {
      async getLatestProducts(pageNumber) {
        this.$store.commit('setIsLoading', true)

        await axios
        .get(`/api/v1/latest-products/?page=${pageNumber}`)
        .then(response => {
          this.total = response.data.count
          this.items = response.data.results
        })
        .catch(error => {
          console.log(error)
        })

        this.$store.commit('setIsLoading', false)
      },
    }
   
  }
</script>
