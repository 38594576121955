<template>
    <div class="full--auth--back">
        <HeaderSmall />
        <div class="position--auth-form">
            <div class="max--form" v-if="nextTo == 0">
                <h5 class="auth--title">Создать аккаунт</h5>
                <form @submit.prevent="submitPhone">
                    <div class="field--auth">
                        <input 
                            v-model="phone"
                            class="input--auth"
                            type="tel" 
                            v-mask="'+# (###) ###-##-##'"
                        >
                        <label 
                            class="auth--label" 
                            :class="{'valid--auth': vphone}"
                        >
                            Телефон
                        </label>
                    </div>
                    <div class="mb-5" v-if="!exist && phone.length == 18">
                        <button class="button--auth">Продолжить</button>
                    </div>
                    <div class="mb-5" v-else>
                        <button class="button--auth backTo" style="width: 100% !important;" disabled>Продолжить</button>
                    </div>
                    <span class="auth--text">Уже есть аккаунт? <router-link class="auth--text--1" to="/log-in">Войти</router-link> </span>
                </form> 
            </div>

            <div v-if="nextTo == 1" 
                class="modal-reg modal-reg__active" 
                :class="{'modal-reg__wait_wrapper': loading, 'modal-reg__error': error_code}">
                <div class="modal-reg__wrapper">
                    <h5 class="auth--title mbNs">Подтвердите номер</h5>
                    <div class="mobile__reg">
                        <p>Для подтверждения номера телефона вам необходимо позвонить на этот системный номер <br> <a :href="'tel: +' + callPhone">+{{ callPhone }}</a>
                        </p>
    <!--                     <form class="modal-reg__input">
                            <input ref="code_1" v-model="code_1" type="number" v-mask="'#'" maxlength="1" placeholder="__">
                            <input ref="code_2" v-model="code_2" type="number" v-mask="'#'" maxlength="1" placeholder="__">
                            <input ref="code_3" v-model="code_3" type="number" v-mask="'#'" maxlength="1" placeholder="__">
                            <input ref="code_4" v-model="code_4" type="number" v-mask="'#'" maxlength="1" placeholder="__">
                        </form>
                        <p class="modal-reg__text_error">неверный код</p>
                        <div class="modal-reg__wait">
                            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                        </div> -->
                    </div>
                    <div class="center-flex__block">
                        <button class="button--auth backTo" @click="nextTo = 0">Назад</button>
                    </div>
                </div>
            </div>
        </div>
        <Footersmall />
    </div>
</template>
<style type="text/css">

.center-flex__block {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 30px 0 0;
}

.modal-reg__active {
  z-index: 100;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.modal-reg__wrapper {
  z-index: 1;
  background-color: transparent;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.backTo {
    background-color: #C9C9C9 !important;
    color: #363636 !important;  
    width: 350px;  
}

.modal-reg__input {
  margin: 24px 0 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.modal-reg p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #ccc;
    max-width: 500px;
}

.modal-reg input {
  height: 64px;
  width: 52px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 42px;
  color: #fff;
  border: none;
  background: #363636;
  border-radius: 16px;
  margin: 0 5px 0;
  border: 2px solid transparent;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.mbNs {
    margin-bottom: 18px !important;
}

.modal-reg input:focus {
  border: 2px solid #D63727;
}

.modal-reg a {
    color: #D63727;
    font-weight: 600;
    letter-spacing: 0px !important;
}

.modal-reg__text_error {
  display: none;
}

.modal-reg__error .modal-reg__text_error {
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  margin: 16px 0 0;
  text-align: center;
  color: #D63727;
}

.modal-reg__error input {
  background: #D63727;
}

.modal-reg__wait {
  display: none;
}

.modal-reg__wait_wrapper .modal-reg__wait {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.modal-reg__wait_wrapper .modal-reg__wait img {
  -webkit-animation: wait 2s infinite ease-in-out;
          animation: wait 2s infinite ease-in-out;
}

@media (max-width: 425px) {
  .modal-reg__wrapper {
    height: 100%;
    border-radius: 0;
    padding: 50px 20px;
  }
  .modal-reg h2 {
    font-size: 26px;
  }
  .modal-reg h2 + p {
    font-size: 16px;
    line-height: 20px;
    max-width: 255px;
  }
  .modal-reg input {
    height: 50px;
    font-size: 30px;
    line-height: 1;
    width: 35px;
  }
}  

input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

</style>
<script>
    import axios from 'axios'

    import { mask } from 'vue-the-mask'
    import { toast } from 'bulma-toast'
    
    import HeaderSmall from '@/components/Header/HeaderSmall'
    import Footersmall from '@/components/Footer/Footersmall'

    export default {
        name: 'SignUp',
        directives: { mask },
        data() {
            return {
                username: '',

                password: 'DQawE|2Q',

                password2: '',
                type: "password",
                errors: [],
                color: '#fff',

                phone: "",
                code: "00000",
                
                callPhone: "",

                nextTo: 0,

                code_1: null,
                code_2: null,
                code_3: null,
                code_4: null,
                min: 1111,
                max: 9999,
                code_main: 0,

                code_check_1: 0,
                code_check_2: 0,
                code_check_3: 0,
                code_check_4: 0,

                redirect: "",
                error_code: false,

                inInterval: null,
                exist: false,

                user_account: {},

                nUserId: 0,
                mask: ['(', /\d/, /\d/, /\d/, ') ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
            }
        },
        components: {
            HeaderSmall,
            Footersmall
        },
        mounted() {
            document.title = 'Создать аккаунт'
        },
        methods: {
            errorAlert() {
                console.log(this.errors.length)
                for (let i = 0; i < this.errors.length; i++) {
                    const message_error = this.errors[i]
                    console.log(message_error)
                    toast({
                        message: message_error,
                        type: 'is-danger',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 10000,
                        position: 'bottom-right',
                    })
                }
            },
            async submitForm() {
                const formData = {
                    username: this.phone.replace(/[^0-9]/g, ''),
                    password: this.password,
                }

                await axios
                    .post("/api/v1/users/", formData)
                    .then(response => {
                        console.log(response.data)
                        this.nUserId = response.data.id
                    })
                    .catch(error => {
                        if (error.response) {
                            for (const property in error.response.data) {
                                this.errors.push(`${error.response.data[property]}`)
                            }

                            console.log(JSON.stringify(error.response.data))
                        } else if (error.message) {
                            this.errors.push('Попробуйте еще раз, произошла ошибка')
                            
                            console.log(JSON.stringify(error))
                        }
                    })
                
                const formData2 = {
                    phone: this.phone.replace(/[^0-9]/g, ''),
                    status_user: 2,
                    user: this.nUserId
                }

                await axios
                    .post("/api/v1/myProfiles/", formData2)
                    .then(response => {
                        this.user_account = response.data
                        let user_id_profile = response.data.id
                        this.$store.commit('setUser', user_id_profile)
                        localStorage.setItem("user_id", user_id_profile)
                    })
                    .catch(error => {console.log(error)})

                axios.defaults.headers.common["Authorization"] = ""
                localStorage.removeItem("token")

                await axios
                .post("/api/v1/token/login/", formData)
                .then(response => {
                    const token = response.data.auth_token

                    this.$store.commit('setToken', token)
                    
                    axios.defaults.headers.common["Authorization"] = "Token " + token

                    localStorage.setItem("token", token)

                    this.$store.commit('setUser', this.user_account.id)

                    localStorage.setItem("user_id", this.user_account.id)

                    localStorage.setItem("user", this.user_account)

                    this.$store.commit('setUserInfo', this.user_account)

                    this.$router.push('/next-step')

                    // this.$router.push('/')
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${error.response.data[property]}`)
                        }
                        this.errorAlert();
                    } else {
                        this.errors.push('Что-то пошло не так...')

                        this.errorAlert();
                        
                        console.log(JSON.stringify(error))
                    }
                })
            },
            async checkPhone(key) {
                await axios
                .get(`/api/v1/phone/status-check/${key}/`)
                .then(response => { 
                    console.log(response.data) 
                    if (String(response.data.status) == "success") {
                        clearInterval(this.inInterval)
                        this.submitForm();
                    }
                })
                .catch(error => { console.log(error) })
            },
            async submitPhone() {
                const formData = {
                    phone: this.phone,
                }

                await axios
                .post("/api/v1/phone/confirm/", formData)
                .then(response => { 
                    console.log(response.data) 
                    this.nextTo = 1
                    this.callPhone = response.data.data.phone
                    this.inInterval = setInterval(() => { this.checkPhone(response.data.data.key)  }, 2000)
                })
                .catch(error => { console.log(error) })
            },
            validEmail(email) {
              var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return re.test(email);
            },
            async checkUser(value) {
                await axios
                    .get(`/api/v1/me/check/${value.replace(/[^0-9]/g, '')}/`)
                    .then(response => {
                        this.exist = true
                        toast({
                            message: 'Учетная запись с таким номером телефона уже существует.',
                            type: 'is-danger',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 10000,
                            position: 'bottom-right',
                        })

                    })
                    .catch(error => {
                        this.exist = false
                        console.log(error)
                    })
            },
        },
        computed: {
          validate_username() {
            if(this.username.length > 0) {
              return true;
            } else {
              return false;
            }
          },
          validate_password() {
            if(this.password.length > 0) {
              return true;
            } else {
              return false;
            }
          },
          vphone() {
            return this.phone.length > 0 ? true : false
          }                        
        },
        watch: {
            phone(value) {
                if (value.length > 5) this.checkUser(value)
            },
            code_1(value) {
                this.code_1 = value;
                if(this.code_1) {
                    this.$nextTick(() => this.$refs.code_2.focus())
                }
                this.Redirect();
            },
            code_2(value) {
                this.code_2 = value;
                if(this.code_2) {
                    this.$nextTick(() => this.$refs.code_3.focus())
                }
                this.Redirect();
            },
            code_3(value) {
                this.code_3 = value;
                if(this.code_3) {
                    this.$nextTick(() => this.$refs.code_4.focus())
                }
                this.Redirect();
            },
            code_4(value) {
                this.code_4 = value;
                this.Redirect();
            },        
        },        
    }
</script>