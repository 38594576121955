<template>
    <div class="full--auth--back">
    
        <HeaderSmall />

        <div class="position--auth-form">
            <div class="max--form">
                <h4 class="pb-3 h4--sub-main">{{ subs.name }}</h4>
                <p class="p-sub-main">{{ subs.disk }}</p>
                <h5 class="h5-sub-main">Всего за {{ subs.price }} Рублей / Год</h5>
                <button @click="sendSub" class="btn--sub-main">Оформить</button>
                <router-link to="/" class="btn--sub-close">Отмена</router-link>
            </div>
        </div>

        <Footersmall />


    </div>

</template>

<style>
    .h4--sub-main {
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        margin-bottom: 20px;
        text-align: center;
    }

    .p-sub-main {
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        text-align: center;
        margin-bottom: 20px;        
    }

    .btn--sub-main {
      display: block;
      background: #D63727;
      border-radius: 10px;
      width: 100%;
      font-weight: 600;
      font-size: 16px;
      line-height: 45px;
      text-align: center;
      vertical-align: middle;
      color: #fff;
      margin-bottom: 20px;

      cursor: pointer;
      transition: all .2s ease;
    }

    .h5-sub-main {
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        text-align: center;
        margin-bottom: 30px;          
    }

    .btn--sub-main:hover {
        background-color: #af2e21 !important;
        transform: scale(1.05);
    }

    .btn--sub-close {
      display: block;
      background: transparent;
      border-radius: 10px;
      width: 100%;
      font-weight: 600;
      font-size: 16px;
      line-height: 50px;
      text-align: center;
      vertical-align: middle;
      color: #D63727;
      border: 1px solid #D63727;
      margin-bottom: 20px;

      cursor: pointer;
      transition: all .2s ease;        
    }

    .btn--sub-close:hover {
        background: #DDDDDD !important;
        color: #333 !important;
        border: 1px solid #DDDDDD !important;
    }
</style>

<script>
    import { toast } from 'bulma-toast'
    import axios from 'axios'
    import moment from 'moment';

    import HeaderSmall from '@/components/Header/HeaderSmall'
    import Footersmall from '@/components/Footer/Footersmall'

    export default {
        name: 'Subs',
        data() {
            return {
                subs: {},
                timestamp: "",

                date_start: "",
                date_end: "",
                user_me: {},
            }
        },
        components: {
            HeaderSmall,
            Footersmall
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
            if (this.$store.state.user.status_user == 2) { 
                this.$router.push('/anketa_success')
            }
            if (this.$store.state.user.status_user == 0) {
                this.$router.push('/anketa')
            }
        },
        mounted() {
            document.title = 'Оформление подписки'
            this.getSubs();
            this.dateSubs();
            this.user_id = this.$store.state.user_id
            this.getMyAccount();
        },
        methods: {
            dateSubs() {
                const today = new Date();
                const year = moment(String(today)).format('YYYY')
                const day = moment(String(today)).format('DD')
                const moth = moment(String(today)).format('MM')

                const currentData = year + '-' + moth + '-' + day
                const endData = (Number(year) + 1) + '-' + moth + '-' + day

                this.date_start = currentData;
                this.date_end = endData;
            },
            async getMyAccount() {

                await axios
                    .get(`/api/v1/me/${this.user_id}/`)
                    .then(response => {
                        this.user_me = response.data
                        console.log(this.user_me)
                    })
                    .catch(error => {
                        console.log(error)
                    })

            },
            async sendSub() {
                const data = {
                    'name': this.$store.state.user.email,
                    'date_start': this.date_start,
                    'date_end': this.date_end,
                }

                await axios
                    .post('/api/v1/subs_users/', data)
                        
                    
                    .then(response => {
                        let id_current = response.data.id
                        let id_paymant = response.data.id_pay

                        this.$store.commit('setSub', id_current)

                        localStorage.setItem("sub_id", id_current)

                        this.$store.commit('setSub', id_paymant)

                        localStorage.setItem("id_pay", id_paymant)

                        window.location.href = response.data.confirm_link

                    })
                    .catch(error => {
                        toast({
                            message: 'Что-то пошло не так попробуйте еще раз',
                            type: 'is-danger',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        })
                    })

            },
            async getSubs() {
                this.$store.commit('setIsLoading', true)

                await axios
                    .get('/api/v1/sub/1/')
                    .then(response => {
                        this.subs = response.data
                    })
                    .catch(error => {
                        console.log(error)
                    })

                this.$store.commit('setIsLoading', false)
            },
        },
        computed: {
      
        }
    }
</script>