<template>
   <div class="product-list">
      <h3 class="product--main" v-if="title_on">{{ title }}</h3>
      <div class="filter--block" v-if="filter_on">
         <div class="row mb-4">
            <div class="col-lg-2">
               <button class="btnFilter" @click="openCategory()">
                  <span class="svg--filter">
                     <svg style="transform: translateY(-1px);" xmlns="http://www.w3.org/2000/svg" width="20" height="13"
                        viewBox="0 0 23 16">
                        <g id="Сгруппировать_1153" data-name="Сгруппировать 1153" transform="translate(-1633 -13484)">
                           <g id="Прямоугольник_239" data-name="Прямоугольник 239" transform="translate(1633 13484)"
                              fill="#fff" stroke="#fff" stroke-width="2">
                              <rect width="23" height="2" rx="1" stroke="none" />
                              <rect x="1" y="1" width="21" fill="none" />
                           </g>
                           <g id="Прямоугольник_240" data-name="Прямоугольник 240" transform="translate(1633 13491)"
                              fill="#fff" stroke="#fff" stroke-width="2">
                              <rect width="23" height="2" rx="1" stroke="none" />
                              <rect x="1" y="1" width="21" fill="none" />
                           </g>
                           <g id="Прямоугольник_241" data-name="Прямоугольник 241" transform="translate(1633 13498)"
                              fill="#fff" stroke="#fff" stroke-width="2">
                              <rect width="23" height="2" rx="1" stroke="none" />
                              <rect x="1" y="1" width="21" fill="none" />
                           </g>
                        </g>
                     </svg>
                  </span>
                  Категории
               </button>
            </div>
            <div class="category_menu" v-if="categoryhide">
               <a class="close--modal" @click="categoryhide = false"></a>
               <div class="row box--row">
                  <div class="col-lg-12">
                     <h3 class="h3--category">
                        Категории
                     </h3>
                     <ul>
                        <li class="mb-2" v-for="list in category_all">
                           <router-link class="link_router" :to="list.get_absolute_url"><img width="20" height="20"
                                 v-if="list.get_icon" :src="list.get_icon"><span class="" style="margin-left: 10px;">{{
                                    list.name }}</span>
                              <svg style="margin-left: 10px;" width="16" xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 448 512">
                                 <path
                                    d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z"
                                    fill="#777" />
                              </svg>
                           </router-link>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="col-lg-10">
               <div class="search--block--main w-100">
                  <form class="form--search-flex w-100" method="get" action="/search">
                     <div class="search--block" style="width: 100% !important;">
                        <div v-show="focused" :class="{ 'hide_search': search_validate }" class="search--label--icon">
                           <span class="icon--search">
                              <svg xmlns="http://www.w3.org/2000/svg" width="17.483" height="21.291"
                                 viewBox="0 0 17.483 21.291">
                                 <g id="Сгруппировать_1151" data-name="Сгруппировать 1151"
                                    transform="translate(-434 -2857.354)">
                                    <g id="Сгруппировать_799" data-name="Сгруппировать 799"
                                       transform="translate(1297 -6544.646)">
                                       <g id="Сгруппировать_798" data-name="Сгруппировать 798">
                                          <g id="Эллипс_77" data-name="Эллипс 77" transform="translate(-863 9402)"
                                             fill="none" stroke="#ccc" stroke-width="1">
                                             <circle cx="8" cy="8" r="8" stroke="none" />
                                             <circle cx="8" cy="8" r="7.5" fill="none" />
                                          </g>
                                          <g id="Прямоугольник_182" data-name="Прямоугольник 182"
                                             transform="translate(-850.659 9416.52) rotate(50)" fill="#ccc" stroke="#ccc"
                                             stroke-width="1">
                                             <rect width="8" height="1" stroke="none" />
                                             <rect x="0.5" y="0.5" width="7" fill="none" />
                                          </g>
                                       </g>
                                    </g>
                                 </g>
                              </svg>
                           </span>
                           <label class="search--label">Найти товар</label>
                        </div>

                        <input @focus="focused = false" class="search--input" style="width: 100% !important;" type="text"
                           v-model="query__search" name="query">
                     </div>

                     <button class="search--button-find">Найти</button>
                  </form>
               </div>
            </div>
         </div>

         <div class="d-flex nowrap tags--row">
            <button v-for="(tag, index) in tags" class="tagbtn" @click="activeBtn(index, tag.name, tag.functional)"
               :class="{ active_tag: index == id_button }">
               {{ tag.name }}
            </button>
         </div>

      </div>

      <main>

         <div class="row mb-4">
            <div class="col-lg-2 marginbottomlist" v-for="(product, index) in sortProducts" :key="index"
               :product="product">
               <router-link :to="product.get_absolute_url">
                  <!-- <div class="img--list"></div> -->
                  <div class="img--inner">
                     <img class="img-fluid img-fluid--hovered" :src="product.get_image">
                  </div>
               </router-link>
               <div class="block-tittle--product">
                  <router-link :to="product.get_absolute_url" class="product--title">{{ product.name }}</router-link>

                  <h4 class="product--price">
                     <span v-if="product.discount" class="product--discount">{{ product.discount }} Руб.</span>
                     <span v-if="product.discount" class="text--product-s">
                        {{ product.price }} Руб.
                     </span>
                     <span v-else>
                        {{ product.price }} Руб.
                     </span>

                  </h4>
               </div>
               <button v-if="product.amount > 0" class="product--btn" @click="addToCart(index)">Добавить в корзину</button>
               <button v-else class="product--btn-disabled" disabled="disabled">Нет в наличии</button>
            </div>
         </div>

         <!--     <div class="d-flex w-100 justify-content-center">
      <div class="notMoreBlock">
        <h3 class="notMoreProducts">{{ sortProducts.length }} / {{ total }} 
        Товаров</h3>
      </div>
    </div> -->

      </main>

      <!-- 
<nav class="page--products" v-if="paginate">
  <ul class="pagination">
    <li v-if="pageNumber != 1" class="page--products-item">
      <a class="page--products--link" @click="prevPage()">
        <svg class="carousel__icon left-artform" viewBox="0 0 24 24" role="img" ariaLabel="arrowLeft"><path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path></svg>        
      </a>      
    </li>
    <li v-for="(page, index) in pageCount" 
      class="page--products-item" 
      :class="{'page--active-item': pageNumber === index + 1 }"
    >
      <a class="page--products--link" @click="changePage(page)">{{ page }}</a>
    </li>
    <li v-if="pageNumber != pageCount"  class="page--products-item">
      <a class="page--products--link" @click="nextPage()">
        <svg class="carousel__icon" viewBox="0 0 24 24" role="img" ariaLabel="arrowRight" style="transform: translate(-2px, -1px);"><path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path></svg>       
      </a>      
    </li>
  </ul>
</nav>
 -->

      <div class="modal--open" v-if="categoryhide"></div>

   </div>
</template>

<style scoped lang="scss">
.tags--row {
   @media(max-width: 1400px) {
      overflow: auto;

      .tagbtn {
         white-space: nowrap;
         margin-right: 30px;
      }
   }
}
</style>



<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import Scroll from '@/components/Scroll'

export default {
   name: 'ProductListHome',
   components: {
      Scroll
   },
   props: {
      title: String,
      title_on: {
         type: Boolean,
         default: false
      },
      filter_on: {
         type: Boolean,
         default: true
      },
      paginate: {
         type: Boolean,
         default: false
      }
      // products: Object
   },
   data() {
      return {
         sortProducts: [],
         category_all: [],
         tags: [
            { name: "ОТ НОВЫХ К СТАРЫМ", functional: "Old" },
            { name: "ОТ СТАРЫХ К НОВЫМ", functional: "New" },
            { name: "ОТ ДЕШЕВЫХ К ДОРОГИМ", functional: "Expensive" },
            { name: "ОТ ДОРОГИХ К ДЕШЕВЫМ", functional: "Сheap" },
            { name: "ПОПУЛЯРНЫЕ", functional: "Popular" },
            { name: "ПО АКЦИИ", functional: "Discounts" },
         ],
         id_button: 0,
         quantity: 1,

         cart: {
            items: []
         },

         page: 1,
         total: 1,
         last_page: 1,

         notMore: false,
         focused: true,

         query__search: "",
         pageNumber: 1,
         activeLink: 1,
         name__tags: "",
         activefunctional: "Old",
         categoryhide: false,
      }
   },
   mounted() {
      this.cart = this.$store.state.cart
      this.getProducts(this.page)
      this.categoryList()
   },
   methods: {
      async activeBtn(index, name, functional) {
         this.id_button = index;
         this.name__tags = name;

         this.page = 1
         this.activefunctional = functional
         this.sortProducts = []
         this.getProducts(this.page)

      },
      addToCart(index) {

         let check_amount = 0;

         if (this.cart.items.length > 0) {
            for (let q = 0; q < this.cart.items.length; q++) {
               const item = this.cart.items[q]
               let id = item.product.id

               if (id == this.sortProducts[index].id) {
                  if (item.quantity >= this.sortProducts[index].amount) {
                     check_amount = 1
                  }
               }

            }
         }

         if (check_amount == 0) {

            const item = {
               product: this.sortProducts[index],
               quantity: this.quantity
            }

            this.$store.commit('addToCart', item)

            toast({
               message: 'Товар был добавлен в корзину',
               type: 'is-success',
               dismissible: true,
               pauseOnHover: true,
               duration: 2000,
               position: 'bottom-right',
            })
         } else {
            toast({
               message: 'Товар больше нет в наличии',
               type: 'is-danger',
               dismissible: true,
               pauseOnHover: true,
               duration: 2000,
               position: 'bottom-right',
            })
         }

      },
      async getProducts(pageNumber) {

         this.$store.commit('setIsLoading', true)

         if (this.activefunctional == "New") {

            await axios
               .get(`/api/v1/productsNew/?page=${this.page}`)
               .then(response => {
                  this.total = response.data.count
                  this.sortProducts = response.data.results
               })
               .catch(error => {
                  console.log(error)
               })

         }

         if (this.activefunctional == "Old") {

            await axios
               .get(`/api/v1/latest-products/?page=${this.page}`)
               .then(response => {
                  this.total = response.data.count
                  this.sortProducts = response.data.results
               })
               .catch(error => {
                  console.log(error)
               })

         }

         if (this.activefunctional == "Popular") {

            await axios
               .get(`/api/v1/productsPopular/?page=${this.page}`)
               .then(response => {
                  this.total = response.data.count
                  this.sortProducts = response.data.results
               })
               .catch(error => {
                  console.log(error)
               })

         }

         if (this.activefunctional == "Expensive") {
            await axios
               .get(`/api/v1/productsExpensive/?page=${this.page}`)
               .then(response => {
                  this.total = response.data.count
                  this.sortProducts = response.data.results

                  this.sortProducts.sort((a, b) => a.new_price_sort - b.new_price_sort);
               })
               .catch(error => {
                  console.log(error)
               })
         }

         if (this.activefunctional == "Discounts") {
            await axios
               .get(`/api/v1/productsDiscounts/?page=${this.page}`)
               .then(response => {
                  this.total = response.data.count
                  this.sortProducts = response.data.results
               })
               .catch(error => {
                  console.log(error)
               })
         }

         if (this.activefunctional == "Сheap") {
            await axios
               .get(`/api/v1/productsСheap/?page=${this.page}`)
               .then(response => {
                  this.total = response.data.count
                  this.sortProducts = response.data.results

                  this.sortProducts.sort((a, b) => b.new_price_sort - a.new_price_sort);
               })
               .catch(error => {
                  console.log(error)
               })
         }

         this.$store.commit('setIsLoading', false)
      },
      async categoryList() {
         await axios
            .get('/api/v1/catall/')
            .then(response => {
               this.category_all = response.data.results
            })
            .catch(error => {
               console.log(error)
            })
      },
      openCategory() {
         if (this.categoryhide == false) {
            this.categoryhide = true;
         } else {
            this.categoryhide = false;
         }

      },
   },
   computed: {
      pageCount() {
         let l = this.total,
            s = 12;
         return Math.ceil(l / 12);
      },
      search_validate() {
         if (this.query__search.length > 0) {
            return true;
         } else {
            return false;
         }
      },

   }

}
</script>
