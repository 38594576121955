<template>

<div class="loader_loading">
    <div class="loader-inner ball-pulse ">
      <div></div>
      <div></div>
      <div></div>
    </div>
</div>

</template>

<script>
  export default {
    name: 'Loader',
  }
</script>