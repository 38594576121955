<template>
   <div class="full--auth--back">

      <HeaderSmall />

      <div class="position--auth-form">
         <div class="max--form">
            <h4 class="pb-3 h4--sub-main">Заполнените анкету участника Спортобщества "Россия" и получите доступ ко всем
               услугам</h4>

            <form>
               <!-- <div v-if="step == 1"> -->
<!-- 
                  <div class="field--auth">
                     <input class="input--auth" v-model="phone" type="tel" v-mask="'+# (###) ###-##-##'" />
                     <label class="auth--label" :class="{ 'valid--auth': Validate_Phone }">Номер телефона</label>
                  </div>
 -->
<!--                   <div class="mb-5">
                     <div v-if="phone.length == 18">
                        <button class="button--auth" @click="NextStep()">Следующий этап</button>
                     </div>
                     <div v-else>
                        <button class="button--auth-disabled" disabled>Следующий этап</button>
                     </div>

                  </div>

                  <div class="d-flex justify-content-between">
                     <h5 class="step">1 Этап</h5>
                     <h5 class="step text-right">Осталось 5 этапов</h5>
                  </div>

               </div> -->
               <div v-if="step == 1">

                  <div class="field--auth">
                     <input type="text" maxlength="26" class="input--auth" v-model="name">
                     <label class="auth--label" :class="{ 'valid--auth': Validate_Name }">Имя</label>
                  </div>

                  <div class="field--auth">
                     <input type="text" maxlength="26" class="input--auth" v-model="surname">
                     <label class="auth--label" :class="{ 'valid--auth': Validate_Surname }">Фамилия</label>
                  </div>

                  <div class="field--auth">
                     <input type="text" maxlength="26" class="input--auth" v-model="patronymic">
                     <label class="auth--label" :class="{ 'valid--auth': Validate_patronymic }">Отчество (при наличии)</label>
                  </div>

                  <div class="notification is-danger" v-if="errors.length > 0">
                     <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                  </div>

                  <div class="mb-5 d-flex justify-content-between">
                     <div class="w100" v-if="name && surname">
                        <button class="button--next-anketa w100" @click="NextStep()">Дальше</button>
                     </div>
                     <div class="w100" v-else>
                        <button class="button--next-disabled-anketa w100" disabled>Дальше</button>
                     </div>

                  </div>

                  <div class="d-flex justify-content-between">
                     <h5 class="step">{{ step }} Этап</h5>
                     <h5 class="step text-right">Осталось {{ maxStep - step }} этапа</h5>
                  </div>

               </div>

               <div v-if="step == 2">

                  <div class="field--auth">
                     <select class="input--auth" v-model="gender" style="height: 46px;">
                        <option value="1">Мужской</option>
                        <option value="0">Женский</option>
                     </select>
                     <label class="auth--label" :class="{ 'valid--auth': Validate_Gender }">Пол</label>
                  </div>

                  <div class="field--auth">
                     <input type="date" class="input--auth" v-model="birthday" required
                        style="padding-right: 1.25rem !important;">
                     <label class="auth--label valid--auth">День рождения</label>
                  </div>

                  <div class="notification is-danger" v-if="errors.length > 0">
                     <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                  </div>

                  <div class="mb-5 d-flex justify-content-between">
                     <button class="button--back-anketa" @click="BackStep()">Назад</button>
                     <div v-if="gender && birthday">
                        <button class="button--next-anketa" @click="NextStep()">Дальше</button>
                     </div>
                     <div v-else>
                        <button class="button--next-disabled-anketa" disabled>Дальше</button>
                     </div>

                  </div>

                  <div class="d-flex justify-content-between">
                     <h5 class="step">{{ step }} Этап</h5>
                     <h5 class="step text-right">Осталось {{ maxStep - step }} этапа</h5>
                  </div>
               </div>

               <div v-if="step == 3"> 

                  <div class="field--auth">
                     <select class="input--auth" :class="{'vnotActive': favoriteSport == ''}" v-model="favoriteSport" style="height: 46px;">
                        <option value="">Выберите Ваш любимый вид спорта:</option>
                        <option v-for="list in sports" :value="list.id">{{ list.name }}</option>
                     </select>
                  </div>

                  <div class="field--auth">
                     <input type="text" maxlength="26" class="input--auth" v-model="city">
                     <label class="auth--label" :class="{ 'valid--auth': Validate_city }">Город</label>
                  </div>


                  <div class="notification is-danger" v-if="errors.length > 0">
                     <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                  </div>

                  <div class="mb-5 d-flex justify-content-between">
                     <button class="button--back-anketa" @click="BackStep()">Назад</button>
                     <div v-if="favoriteSport && city">
                        <button class="button--next-anketa" @click="NextStep()">Дальше</button>
                     </div>
                     <div v-else>
                        <button class="button--next-disabled-anketa" disabled>Дальше</button>
                     </div>

                  </div>

                  <div class="d-flex justify-content-between">
                     <h5 class="step">{{ step }} Этап</h5>
                     <h5 class="step text-right">Осталось {{ maxStep - step }} этапа</h5>
                  </div>
               </div>

               <div v-if="step == 4">

                  <div class="field--auth">
                     <input type="text" maxlength="26" class="input--auth" v-model="email">
                     <label class="auth--label" :class="{ 'valid--auth': vemail }">E-mail</label>
                     <p v-if="!validEmail(email) && email"
                        class="help-text__step red">
                        Введите корректный адрес электронной почты
                    </p>
                  </div>

                  <div class="field--auth">
                     <select class="input--auth" :class="{'vnotActive': region == ''}" v-model="region" style="height: 46px;">
                        <option value="">Выберите ваше региональное отделение:</option>
                        <option v-for="list in regionsShort" :value="list.id">{{ list.name }}</option>
                     </select>
                     <p
                        class="help-text__step grey">
                        Наши региональные отделения помогают развивать спорт. Выберите ближайшее к Вам
                    </p>
                  </div>

                  <div class="mb-5 d-flex justify-content-between">
                     <button class="button--back-anketa" @click="BackStep()">Назад</button>
                     <div v-if="emailAprove && region">
                        <button class="button--next-anketa" type="button" @click="NextStep()">Дальше</button>
                     </div>
                     <div v-else-if="validEmail(email) && !emailAprove && region">
                        <button class="button--next-anketa" type="button" @click="AproveEmail()">Дальше</button>
                     </div>
                     <div v-else>
                         <button class="button--next-disabled-anketa" disabled>Дальше</button>
                     </div>
                  </div>

                  <div class="d-flex justify-content-between">
                     <h5 class="step">{{ step }} Этап</h5>
                     <h5 class="step text-right">Осталось {{ maxStep - step }} этапа</h5>
                  </div>
               </div>

               <div v-if="step == 5">
<!-- 
                  <div class="field-auth">
                     <h4 class="field-h4--anketa">Прошу принять в члены ОБЩЕРОССИЙСКОЙ ОБЩЕСТВЕННОЙ ОРГАНИЗАЦИИ -
                        ФИЗКУЛЬТУРНО-СПОРТИВНОЕ ОБЩЕСТВО ПРОФСОЮЗОВ "РОССИЯ".</h4>
                  </div> -->

<!--                   <div class="field--auth">
                     <div class="d-flex">
                        <input class="custom-checkbox" type="checkbox" v-model="info1" value="1" id="info1">
                        <label class="field--checkbox-anketa" for="info1">
                           <a href="https://xn--90aci8aadbjgghe2g.xn--p1ai/Ustav.pdf" class="link-anketa-ch" target="_blank">С
                              Уставом</a> и <a href="https://xn--90aci8aadbjgghe2g.xn--p1ai/polojenie_o_chlenstve.pdf"
                              class="link-anketa-ch" target="_blank">Положением о членстве</a> ознакомлен.</label>
                     </div>
                  </div> -->

                  <div class="field--auth">
                     <div class="d-flex">
                        <input class="custom-checkbox" type="checkbox" v-model="info1" value="1" id="info2">
                        <label class="field--checkbox-anketa" for="info2">
                           Я ознакомлен(а) с политикой <a href="https://xn--90aci8aadbjgghe2g.xn--p1ai/privacy-policy"
                              class="link-anketa-ch" target="_blank">обработки персональных данных</a> и даю согласие на <a href="https://xn--90aci8aadbjgghe2g.xn--p1ai/privacy-policy"
                              class="link-anketa-ch" target="_blank">обработку персональных данных</a> </label>
                     </div>
                  </div>

                  <div class="mb-5 d-flex justify-content-between">
                     <button class="button--back-anketa" @click="BackStep()">Назад</button>
                     <div v-if="info1">
                        <button class="button--next-anketa" type="button" @click="SendPUT()">Готово</button>
                     </div>
                     <div v-else>
                        <button class="button--next-disabled-anketa" disabled>Готово</button>
                     </div>

                  </div>

                  <div class="d-flex justify-content-between">
                     <h5 class="step">{{ step }} Этап</h5>
                     <h5 class="step text-right">Финал</h5>
                  </div>
               </div>

            </form>
         </div>
      </div>

      <EmailConfirm
        :vmodal="emailModal"
        :email="email"
        @close="emailModal = $event"
        @confirm="emailAprove = $event"
      />

      <Footersmall />
   </div>
</template>

<style type="text/css">
    .w100 {
        width: 100% !important;
    }

    .vnotActive {
        color: #CCCCCC !important;
    }

    .help-text__step {
        padding: 2px 0px 0px; 
        font-size: 14px;
    }

    .help-text__step.grey {
        color: #C9C9C9; 
    }

    .help-text__step.red {
        color: #b94632; 
    }

</style>

<script>
import { toast } from 'bulma-toast'
import axios from 'axios'
import moment from 'moment';

// Local Component
import { mask } from 'vue-the-mask'

import HeaderSmall from '@/components/Header/HeaderSmall'
import Footersmall from '@/components/Footer/Footersmall'
import EmailConfirm from '@/components/Modal/EmailConfirm'

export default {
   name: 'NextStep',
   directives: { mask },
   data() {
      return {
         sub_id: "",
         status: {},
         errors: [],
         status_payment: "",

         step: 1,

         name: "",
         surname: "",
         patronymic: "",
         gender: "",
         birthday: "",
         phone: "",

         city: "",
         email: "",

         seriya_pasport: "",
         number_pasport: "",
         vidan_pasport: "",
         data_pasport: "",

         pasport: "",

         file: "",
         filesend: {},
         info1: "",
         user_id: "",
         info2: "",

         favoriteSport: "",
         region: "",

         user_me: {},
         maxStep: 5,

         sports: [],
         regionsShort: [],

         emailAprove: false,
         emailModal: false,
      }
   },
   components: {
      HeaderSmall,
      Footersmall,
      EmailConfirm
   },
   beforeCreate() {
      this.$store.commit('initializeStore')
      // if (this.$store.state.user.status_user == 2 || this.$store.state.user.status_user == 1) {
      //    this.$router.push('/anketa_success')
      // }
   },
   mounted() {
      document.title = 'Заполнение анкеты'
      this.user_id = this.$store.state.user_id
      this.getMyAccount();
      this.GetSports()
      this.GetRegions()
      console.log(this.$route);
   },
   methods: {
      BackStep() {
         this.step--
      },
      NextStep() {
         this.step++
      },
      async getMyAccount() {

         await axios
            .get(`/api/v1/me/${this.user_id}/`)
            .then(response => {
               this.user_me = response.data
               console.log(this.user_me)
            })
            .catch(error => {
               console.log(error)
            })

      },
      async sendEmail() {
         const data2 = {
            email: this.user_me.email,
         }

         await axios
            .post('/api/v1/send_email_anketa/', data2)
            .then(response => {
               console.log(response)
            })
            .catch(error => {
               console.log(error)
            })

      },
      async SendPUT() {
         // const headers = {
         //    'content-Type': 'multipart/form-data;  boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
         // }

        var currentDate = new Date();

        const datasort = moment(String(currentDate)).format('YYYY-MM-DD')
        const databirthday = moment(String(this.birthday)).format('YYYY-MM-DD')

         // const filepath = `https://xn--90aci8aadbjgghe2g.xn--p1ai/admin/document_images/${this.file.name}`

        const dataForm = {
            name: this.name,
            surname: this.surname,
            patronymic: this.patronymic,
            gender: this.gender,
            birthday: databirthday,
            email: this.email,
            favoriteSport: this.favoriteSport,
            region: this.region,
            city: this.city,
            phone: this.user_me.phone,
            status_user: 1,
        }

        await axios
            .put(`/api/v1/me/${this.user_id}/`, dataForm)
            .then(response => {
               toast({
                  message: 'Добро пожаловать',
                  type: 'is-success',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 2000,
                  position: 'bottom-right',
               })

               localStorage.removeItem("user")

               this.$store.commit('removeUserInfo')

               localStorage.setItem("user", response.data)

               this.$store.commit('setUserInfo', response.data)

               // this.sendEmail();

               this.$router.push('/my-account')
            })
            .catch(error => {
               console.log(error)
               toast({
                  message: 'Произошла ошибка',
                  type: 'is-danger',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 2000,
                  position: 'bottom-right',
               })
            })
        },
        async GetSports() {
            await axios
                .get('/api/v1/all_sports/')
                .then(response => {this.sports = response.data})
                .catch(error => {console.log(error)})
        },
        async GetRegions() {
            await axios
                .get('/api/v1/get/regions/short-list/')
                .then(response => {this.regionsShort = response.data})
                .catch(error => {console.log(error)})
        },
        AproveEmail() {
            this.emailModal = true
        },
        validEmail(email) {
          var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(email);
        }
   },
   computed: {
      Validate_Phone() {
         if (this.phone) {
            return true;
         } else {
            return false;
         }
      },
      Validate_Name() {
         if (this.name) {
            return true;
         } else {
            return false;
         }
      },
      Validate_Surname() {
         if (this.surname) {
            return true;
         } else {
            return false;
         }
      },
      Validate_patronymic() {
         if (this.patronymic) {
            return true;
         } else {
            return false;
         }
      },
      Validate_Gender() {
         if (this.gender) {
            return true;
         } else {
            return false;
         }
      },
      Validate_birthday() {
         if (this.birthday) {
            return true;
         } else {
            return false;
         }
      },
      Validate_city() {
         if (this.city) {
            return true;
         } else {
            return false;
         }
      },
      vemail() {
         if (this.email) {
            return true;
         } else {
            return false;
         }
      },
      Validate_vidan_pasport() {
         if (this.vidan_pasport) {
            return true;
         } else {
            return false;
         }
      },
      Validate_favoriteSport() {
         if (this.favoriteSport) {
            return true;
         } else {
            return false;
         }
      },
   },
   watch: {
     city(string) {
        this.city = string.charAt(0).toUpperCase() + string.slice(1);
     }
   },
}
</script>