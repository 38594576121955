<template>

  <div class="row mb-4">
    <div class="col-lg-2 marginbottomlist" v-for="(prod, index) in product">
      <router-link :to="prod.get_absolute_url">
        <!-- <div class="img--list"></div> -->
        <div class="img--inner">
          <img class="img-fluid img-fluid--hovered" :src="prod.get_image">
      </div>
  </router-link>
  <div class="block-tittle--product">
     <router-link :to="prod.get_absolute_url" class="product--title">{{ prod.name }}</router-link>

     <h4 class="product--price">
        <span v-if="prod.discount" class="product--discount">{{ prod.discount }} Руб.</span>
        <span v-if="prod.discount" class="text--product-s">
          {{ prod.price }} Руб.
      </span>
      <span v-else>
          {{ prod.price }} Руб.
      </span> 

  </h4>            
</div>
<button v-if="prod.amount > 0" class="product--btn" @click="addToCart(index)">Добавить в корзину</button>         
<button v-else class="product--btn-disabled" disabled="disabled">Нет в наличии</button> 
</div>
</div>

</template>

<script>
import { toast } from 'bulma-toast'

export default {
    name: 'ProductBox',
    props: {
        product: Object
    },
    data() {
        return {
          quantity: 1,
          focused: true,
          cart: {
            items: []
          }, 
        }
    },
    mounted() {
      this.cart = this.$store.state.cart
    },
    methods: {
        addToCart(index) {

          let check_amount = 0;

          if (this.cart.items.length > 0) {

            for (let q = 0; q < this.cart.items.length; q++) {
              const item = this.cart.items[q]           
              let id = item.product.id

              if (id == this.product[index].id) {
                if (item.quantity >= this.product[index].amount) {
                  check_amount = 1
                }
              }

            }

          }

          if (check_amount == 0) {

            const item = {
              product: this.product[index],
              quantity: this.quantity
            }

            this.$store.commit('addToCart', item)

            toast({
              message: 'Товар был добавлен в корзину',
              type: 'is-success',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            })
          } else {
            toast({
              message: 'Товар больше нет в наличии',
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            })
          }

        }, 
    }  
}
</script>