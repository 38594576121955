<template>
   <div class="d-mobblockf burger-menu navbar navbar-dark bg-fo">

      <div class="flex__mobile w-100 padding-mobile_flex">

         <div>
            <router-link to="/" class="header-logo">
               <img src="@/assets/logo_main.svg">
            </router-link>
         </div>

         <div class="d-flex mr-5" style="line-height: 36px;">

            <!-- <router-link to="/cart" class="header-cart_btn btn-1 mr-3">
               <div class="position-relative">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 28 26">
                     <path id="cart-shopping-solid"
                        d="M4.685,0A1.188,1.188,0,0,1,5.837.991l.073.634H26.441a1.635,1.635,0,0,1,1.5,2.065l-2.635,9.752a1.573,1.573,0,0,1-1.5,1.183H8.33l.449,2.438H23.815a1.22,1.22,0,0,1,0,2.438H7.764a1.18,1.18,0,0,1-1.108-.99L3.716,2.438H1.171A1.2,1.2,0,0,1,0,1.219,1.2,1.2,0,0,1,1.171,0ZM6.247,23.563a2.392,2.392,0,0,1,2.342-2.437,2.439,2.439,0,0,1,0,4.875A2.392,2.392,0,0,1,6.247,23.563Zm18.74,0a2.344,2.344,0,1,1-2.342-2.437A2.392,2.392,0,0,1,24.986,23.563Z"
                        fill="#fff" />
                  </svg>
                  <span class="pos-abs-count">{{ cartTotalLength }}</span>
               </div>
            </router-link> -->

            <a href="tel:8 (495) 741-13-64" class="mr-2">
               <svg id="SvgjsSvg1011" width="20" height="20" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs"><defs id="SvgjsDefs1012"></defs><g id="SvgjsG1013"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="20" height="20"><path d="M13.216 8.064c-.382-4.394-4.966-6.55-5.16-6.638a.974.974 0 0 0-.582-.078c-5.292.878-6.088 3.958-6.12 4.086a.99.99 0 0 0 .02.54c6.312 19.584 19.43 23.214 23.742 24.408.332.092.606.166.814.234a.99.99 0 0 0 .722-.042c.132-.06 3.248-1.528 4.01-6.316a.997.997 0 0 0-.096-.612c-.068-.132-1.698-3.234-6.218-4.33a.977.977 0 0 0-.884.21c-1.426 1.218-3.396 2.516-4.246 2.65-5.698-2.786-8.88-8.132-9-9.146-.07-.57 1.236-2.572 2.738-4.2a.998.998 0 0 0 .26-.766z" fill="#ffffff" class="color000 svgShape"></path></svg></g></svg>
            </a>

            <router-link to="/my-account">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 27.427"
                  style="transform: translateY(-1px);">
                  <path id="user-solid"
                     d="M12,13.714A6.857,6.857,0,1,0,5.143,6.857,6.857,6.857,0,0,0,12,13.714Zm2.716,2.571H9.284A9.285,9.285,0,0,0,0,25.57a1.857,1.857,0,0,0,1.857,1.857H22.144A1.853,1.853,0,0,0,24,25.57,9.285,9.285,0,0,0,14.716,16.286Z"
                     fill="#fff" />
               </svg>

            </router-link>

            <label class="menu-btn" for="menu-toggle" @click="openMobileMenu()">
               <span></span>
            </label>

         </div>

      </div>

      <ul class="menubox" v-if="menumobile">
         <a @click="menumobile = false" class="close--modal close--moblemenubox"></a>
         <!-- <li class="li-menu-itemss">
            <router-link class="menu-item" to="/shop-fsop"
               :class="{ actives: $route.path === '/shop-fsop' }">ИНТЕРНЕТ-МАГАЗИН</router-link>
         </li> -->
<!--          <li class="li-menu-itemss">
            <router-link class="menu-item" to="/" :class="{ actives: $route.path === '/' }">СПОРТОБЩЕСТВО</router-link>
         </li> -->
         <li class="li-menu-itemss">
            <router-link class="menu-item" to="/trustees" :class="{ actives: $route.path === '/trustees' }">ПОПЕЧИТЕЛЬСКИЙ СОВЕТ</router-link>
         </li>
         <li class="li-menu-itemss">
            <router-link class="menu-item" to="/about_fsop" :class="{ actives: $route.path === '/about_fsop' }">О
               СПОРТОБЩЕСТВЕ</router-link>

         </li>
         <li class="li-menu-itemss">
            <router-link class="menu-item" to="/all_events"
               :class="{ actives: $route.path === '/all_events' }">КАЛЕНДАРЬ</router-link>
         </li>

         <li class="li-menu-itemss">
            <router-link class="menu-item" to="/contact"
               :class="{ actives: $route.path === '/contact' }">КОНТАКТЫ</router-link>
         </li>
         <li class="li-menu-itemss">
            <router-link class="menu-item" to="/regions" :class="{ actives: $route.path === '/regions' }">РЕГИОНЫ</router-link>
         </li>
         <li class="li-menu-itemss">
            <router-link class="menu-item" to="/team" :class="{ actives: $route.path === '/team' }">КОМАНДА</router-link>
         </li>
         <li class="li-menu-itemss">
            <router-link class="menu-item" to="/history" :class="{ actives: $route.path === '/history' }">ИСТОРИЯ</router-link>
         </li>
         <li class="li-menu-itemss">
            <router-link class="menu-item" to="/activity" :class="{ actives: $route.path === '/activity' }">ДЕЯТЕЛЬНОСТЬ</router-link>
         </li>
         <li class="pb-3 mb-3 border-bottom li-menu-itemss">
            <router-link class="menu-item" to="/news" :class="{ actives: $route.path === '/news' }">НОВОСТИ</router-link>
         </li>
         <li class="li-menu-itemss">
            <router-link class="menu-item" to="/deyatelnost" :class="{ actives: $route.path === '/deyatelnost' }">ЗАЛ
               СЛАВЫ</router-link>
         </li>
<!--          <li class="li-menu-itemss">
            <router-link class="menu-item" to="/all_product" :class="{ actives: $route.path === '/all_product' }">ВСЕ
               ТОВАРЫ</router-link>
         </li> -->
         <li class="li-menu-itemss">
            <router-link class="menu-item" to="/partners"
               :class="{ actives: $route.path === '/partners' }">ПАРТНЕРАМ</router-link>
         </li>
         <li class="position-bottom--mobile li-menu-itemss" v-if="$store.state.isAuthenticated">
            <a class="menu-item colorcccmobile" @click="ModalOpenLogout()">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512"
                  style="transform: translate(-1px, -1px); margin-right: 6px;">
                  <path
                     d="M160 416H96c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h64c17.67 0 32-14.33 32-32S177.7 32 160 32H96C42.98 32 0 74.98 0 128v256c0 53.02 42.98 96 96 96h64c17.67 0 32-14.33 32-32S177.7 416 160 416zM502.6 233.4l-128-128c-12.51-12.51-32.76-12.49-45.25 0c-12.5 12.5-12.5 32.75 0 45.25L402.8 224H192C174.3 224 160 238.3 160 256s14.31 32 32 32h210.8l-73.38 73.38c-12.5 12.5-12.5 32.75 0 45.25s32.75 12.5 45.25 0l128-128C515.1 266.1 515.1 245.9 502.6 233.4z" />
               </svg>

               ВЫЙТИ
            </a>
         </li>
         <li class="position-bottom--mobile li-menu-itemss" v-else>
            <router-link class="menu-item colorcccmobile" to="/my-account">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512"
                  style="transform: translate(-1px, -1px); margin-right: 6px;">
                  <path
                     d="M344.7 238.5l-144.1-136C193.7 95.97 183.4 94.17 174.6 97.95C165.8 101.8 160.1 110.4 160.1 120V192H32.02C14.33 192 0 206.3 0 224v64c0 17.68 14.33 32 32.02 32h128.1v72c0 9.578 5.707 18.25 14.51 22.05c8.803 3.781 19.03 1.984 26-4.594l144.1-136C354.3 264.4 354.3 247.6 344.7 238.5zM416 32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c17.67 0 32 14.33 32 32v256c0 17.67-14.33 32-32 32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c53.02 0 96-42.98 96-96V128C512 74.98 469 32 416 32z" />
               </svg>

               ВОЙТИ
            </router-link>
         </li>
      </ul>

   </div>
   <header class="d-hiden bg-header">

      <div class="container paddingcontainernew">
         <div class="header-row">
            <router-link to="/" class="header-logo">
               <img src="@/assets/logo_main.svg">
            </router-link>

            <div class="block_headerflex">
               <a class="phone_btn_header mr-header" href="tel:8 (495) 741-13-64">
                  + 7 (495) 741-13-64
               </a>
               <router-link v-if="$store.state.isAuthenticated" to="/my-account" class="btn_header_ic mr-header"
                  :class="{ 'activebtn--header': $route.path === '/my-account' }">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 27.427">
                     <path id="user-solid"
                        d="M12,13.714A6.857,6.857,0,1,0,5.143,6.857,6.857,6.857,0,0,0,12,13.714Zm2.716,2.571H9.284A9.285,9.285,0,0,0,0,25.57a1.857,1.857,0,0,0,1.857,1.857H22.144A1.853,1.853,0,0,0,24,25.57,9.285,9.285,0,0,0,14.716,16.286Z" />
                  </svg>
               </router-link>
               <a v-if="$store.state.isAuthenticated" @click="ModalOpenLogout()" class="btn_header_ic mr-header">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512"
                     style="transform: translate(-1px, -1px);">
                     <path
                        d="M160 416H96c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h64c17.67 0 32-14.33 32-32S177.7 32 160 32H96C42.98 32 0 74.98 0 128v256c0 53.02 42.98 96 96 96h64c17.67 0 32-14.33 32-32S177.7 416 160 416zM502.6 233.4l-128-128c-12.51-12.51-32.76-12.49-45.25 0c-12.5 12.5-12.5 32.75 0 45.25L402.8 224H192C174.3 224 160 238.3 160 256s14.31 32 32 32h210.8l-73.38 73.38c-12.5 12.5-12.5 32.75 0 45.25s32.75 12.5 45.25 0l128-128C515.1 266.1 515.1 245.9 502.6 233.4z" />
                  </svg>
               </a>
               <router-link v-else to="/log-in" class="btn_header_ic mr-header">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512"
                     style="transform: translate(-1px, -1px);">
                     <path
                        d="M344.7 238.5l-144.1-136C193.7 95.97 183.4 94.17 174.6 97.95C165.8 101.8 160.1 110.4 160.1 120V192H32.02C14.33 192 0 206.3 0 224v64c0 17.68 14.33 32 32.02 32h128.1v72c0 9.578 5.707 18.25 14.51 22.05c8.803 3.781 19.03 1.984 26-4.594l144.1-136C354.3 264.4 354.3 247.6 344.7 238.5zM416 32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c17.67 0 32 14.33 32 32v256c0 17.67-14.33 32-32 32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c53.02 0 96-42.98 96-96V128C512 74.98 469 32 416 32z"
                        fill="#fff" />
                  </svg>
               </router-link>
               <!-- <router-link to="/cart" class="btn_header_ic" :class="{ 'activebtn--header': $route.path === '/cart' }">
                  <div class="position-relative">
                     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 28 26">
                        <path id="cart-shopping-solid"
                           d="M4.685,0A1.188,1.188,0,0,1,5.837.991l.073.634H26.441a1.635,1.635,0,0,1,1.5,2.065l-2.635,9.752a1.573,1.573,0,0,1-1.5,1.183H8.33l.449,2.438H23.815a1.22,1.22,0,0,1,0,2.438H7.764a1.18,1.18,0,0,1-1.108-.99L3.716,2.438H1.171A1.2,1.2,0,0,1,0,1.219,1.2,1.2,0,0,1,1.171,0ZM6.247,23.563a2.392,2.392,0,0,1,2.342-2.437,2.439,2.439,0,0,1,0,4.875A2.392,2.392,0,0,1,6.247,23.563Zm18.74,0a2.344,2.344,0,1,1-2.342-2.437A2.392,2.392,0,0,1,24.986,23.563Z" />
                     </svg>
                     <span class="pos-abs-count">{{ cartTotalLength }}</span>
                  </div>

               </router-link> -->
            </div>

         </div>

      </div>
      <!--           <div class="header-row">
            <form class="header-searchbox" method="get" action="/search">
              <span class="header-searchbox__icon">
                <i class="fa fa-search" aria-hidden="true"></i>
              </span>
              <input class="header-searchbox__intext" type="text" name="query" placeholder="Поиск по названию или веществу препарата">
              <button class="header-searchbox__btn">Найти</button>
            </form>
          </div> -->
      <div style="width: 100%; height: 1px; background: rgb(68 67 67); margin-top: 0px; margin-bottom: 20px;"></div>
      <div class="container paddingcontainernew offvisiblie">
         <div class="header-row">
            <div class="d-flex justify-content-between w-100">

<!--                <router-link class="rd-nav-link-fsop" to="/"
                  :class="{ active: $route.path === '/' }">СПОРТОБЩЕСТВО</router-link>
 -->
               <div class="dropdown">
                  <div class="d-flex">
                     <a class="rd-nav-link-fsop" @click="ShowMenu()">
                        О СПОРТОБЩЕСТВЕ
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                           style="width: 14px; height: 14px; transform: translateY(-2px); margin-left: 6px; transition: 0.2 ease;">
                           <path
                              d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z" />
                        </svg>
                     </a>

                  </div>

                  <div v-if="showmenu" class="show__block">
                     <router-link class="dropdown-showblock" to="/trustees">Попечительский совет</router-link>
                     <router-link class="dropdown-showblock" to="/team">Команда</router-link>
                     <router-link class="dropdown-showblock" to="/history">История</router-link>
                     <router-link class="dropdown-showblock" to="/about_fsop">О спортобществе</router-link>
                     <router-link class="dropdown-showblock" to="/deyatelnost">Зал славы</router-link>
                     <router-link class="dropdown-showblock" to="/documents">Документы</router-link>
                     <router-link class="dropdown-showblock" to="/partners">Партнерам</router-link>
                  </div>
               </div>

               <router-link class="rd-nav-link-fsop" to="/regions"
                  :class="{ active: $route.path === '/regions' }">РЕГИОНЫ</router-link>

               <router-link class="rd-nav-link-fsop" to="/activity"
                  :class="{ active: $route.path === '/activity' }">ДЕЯТЕЛЬНОСТЬ</router-link>

               <router-link class="rd-nav-link-fsop" to="/all_events"
                  :class="{ active: $route.path === '/all_events' }">КАЛЕНДАРЬ</router-link>

               <router-link class="rd-nav-link-fsop" to="/news"
                  :class="{ active: $route.path === '/news' }">НОВОСТИ</router-link>

               <router-link class="rd-nav-link-fsop" to="/contact"
                  :class="{ active: $route.path === '/contact' }">КОНТАКТЫ</router-link>


               <!-- <router-link class="rd-nav-link-fsop" :class="{ active: $route.path === '/shop-fsop' }" to="/shop-fsop">
                  ИНТЕРНЕТ-МАГАЗИН
               </router-link> -->

            </div>
         </div>
      </div>
   </header>

   <div v-if="showmodallogout" class="modal-footer--open">
      <div class="modal-wrapper">

         <a @click="showmodallogout = false" class="close--modal--footer"></a>

         <div class="modal-container--footer">
            <div class="modal-body--foter">
               <div class="row margin-bottom--footer-modal">
                  <h4 class="logout-modal--h4">Вы действительно хотите выйти?</h4>
               </div>
               <div class="d-flex justify-content-between">
                  <button @click="showmodallogout = false" class="detail--button disablebutton"
                     style="margin-right: 20px;">Отмена</button>
                  <button class="detail--button" @click="logout()">
                     Выйти
                  </button>
               </div>

            </div>
         </div>
      </div>
   </div>

   <div @click="menumobile = false" v-if="menumobile" class="modal-shows--mobile"></div>
</template>

<script>
import axios from 'axios'

export default {
   name: 'Header',
   data() {
      return {
         menumobile: false,
         showmenu: false,
         showmenushop: false,
         showmodallogout: false,
         cart: {
            items: []
         },
         user: "",
      }
   },
   beforeCreate() {
      this.$store.commit('initializeStore')
   },
   mounted() {
      this.cart = this.$store.state.cart
      this.user = this.$store.state.user
   },
   methods: {
      openMobileMenu() {
         if (this.menumobile == false) {
            this.menumobile = true;
         } else {
            this.menumobile = false;
         }
      },
      ShowMenu() {
         if (this.showmenu == false) {
            this.showmenu = true;
         } else {
            this.showmenu = false;
         }
      },
      ShowMenuShop() {
         if (this.showmenushop == false) {
            this.showmenushop = true;
         } else {
            this.showmenushop = false;
         }
      },
      ModalOpenLogout() {
         this.showmodallogout = !this.showmodallogout
      },
      logout() {
         axios.defaults.headers.common["Authorization"] = ""

         localStorage.removeItem("token")
         localStorage.removeItem("username")
         localStorage.removeItem("userid")
         localStorage.removeItem("sub_id")
         localStorage.removeItem("id_pay")
         localStorage.removeItem("status_pay")
         localStorage.removeItem("user")

         this.$store.commit('removeUser')
         this.$store.commit('removeToken')
         this.$store.commit('removeSub')
         this.$store.commit('removePayID')
         this.$store.commit('removePayStatus')
         this.$store.commit('removeUserInfo')

         this.$router.push('/')
      },
   },
   computed: {
      cartTotalLength() {
         let totalLength = 0

         for (let i = 0; i < this.cart.items.length; i++) {
            totalLength += this.cart.items[i].quantity
         }

         return totalLength
      }
   }
}
</script>