<template>

  <HeaderFsop />

<!--       <div class="container paddingcontainernew">
          <div class="footer-menu--block" style="padding-bottom: 30px !important;">
              <h3 class="contact">{{ about.name }}</h3>
          </div>
      </div>

      <div class="position-relative max-h--aboutfsop mb-5">
        <img :src="about.image" class="img-fluid w-100">
        <div class="back--aboutfsop-img"></div>
        <div class="text-absolute-fsop paddingcontainernew">
          <h4 class="fsop---text-abs container" v-html="about.description">
          </h4>
        </div>
        
      </div>
 -->
      <div class="container paddingcontainernew">

        <div class="block--fsop-team">
          <div class="footer-menu--block">
              <h3 class="contact">{{ about.name }}</h3>
          </div>

          <div class="row marginb-6">
            <p v-html="about.description">
            </p>
          </div> 
        </div>
      </div>

  <FooterFsop />

</template>

<style type="text/css">
/*  .img-fluid {
    width: auto;
  }*/
  .tabs--fsopabout {
    display: flex;
    justify-content: space-between;
    max-width: 400px;
  }

  .max-h--teamfsopabout {
    max-height: 330px;
    overflow: hidden;
    margin-bottom: 20px;
  }

  .tabs--fsop--text {
    font-size: 17px;
    font-weight: 600;
  }

  .team--h4-fsopabout {
    font-weight: 600;
    color: #111;
    margin-bottom: 15px;
  }

  .team--p-fsopabout {
    font-weight: 500;
    color: #111;    
  }

  .noneactive--tabs {
    color: #8B8B8B;
  }

  .main-team-h3 {
    color: #111;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .main-team-h4 {
    color: #111;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 30px;
  }

  .text--fluid--teammain {
    font-weight: 500;
    color: #111;
    margin-bottom: 20px;
  }

  .block--p--aboutfsop {
    font-weight: 500;
    color: #111;
  }

  .active--tabs-fsop {
    color: #333333;
  }

  .back--aboutfsop-img {
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 10;
    height: 100%;
    background: rgba(214, 55, 39, 0.6);
  }

  .text-absolute-fsop {
    position: absolute;
    z-index: 999;
    left: 50px;
    bottom: 50%;
    width: 100%;
    padding-left: 25px !important;
  }

  .block--fsop-team {

  }

  .fsop---text-abs {
    color: #fff;
    line-height: 24px;
    font-weight: 700;
  }

  .max-h--aboutfsop {
    max-height: 400px;
    overflow: hidden;
  }

  .fsop--textss {
    color: #D63727;
    font-size: 18px;
    text-align: center;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 30px;
  }


</style>

<!-- 
          {name: "История"},
          {name: "Документы"}, -->
<script>
  import HeaderFsop from '@/components/Header/HeaderFsop'
  import FooterFsop from '@/components/Footer/FooterFsop'  
  import axios from 'axios'
  import { toast } from 'bulma-toast'

  export default {
    name: 'AboutFsop',
    data() {
      return {
        about: {},
      }
    },
    components: {
      HeaderFsop,
      FooterFsop
    },
    mounted() {
      this.GetAbout()
    },
    methods: {
        async GetAbout() {

          await axios
          .get('/api/v1/get/about/fsop/object/')
          .then(response => {
            this.about = response.data
            console.log(response.data)
          })
          .catch(error => {
            console.log(error)
          })

        },
    }
  }
</script>