<template lang="">
   <div 
   class="v-select"
   @click="toggleOptions"
   >
      <div class="v-select__backdrop"></div>
      <div class="v-select__wrapper">
         <!-- <input 
         class="v-select__input"
            type="text" 
            :disabled="!this.settings.selectSearch"
            :placeholder="this.settings.placeholder"
            v-model="this.choosedValue"
         > -->
         <span class="v-select__input">{{this.choosedValue}}</span>
         <div class="v-select__icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
               <path d="M5.5 9.25L11 14.75L16.5 9.25" stroke="#00A099" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>   
         </div>
      </div>
      <ul
         class="v-select__list"
      >
         <li 
         class="v-select__item"
         :class="{active: option.name === this.choosedValue}"
         v-for="(option,index) in this.settings.options"
         :key="index"
         @click="selectOption(option)"
         >
            {{option.name}}
         </li>
      </ul>
   </div>
</template>
<script>
export default {
   name: "v-select",
   props: {
      settings: {
         type: Object,
         default() {
            return {}
         }
      }
   },
   data() {
      return {
         choosedValue: "",
         selectedOption: "",
      }
   },
   methods: {
      toggleOptions(event) {
         event.target.closest(".v-select").classList.toggle("open")
      },
      selectOption(option) {
         this.choosedValue = option.name;
         this.$emit("selectOption", option)
      }
   },
   watch: {
      "$route.params"() {
         this.settings.options.forEach((item, index) => {
            if (index == 0) {
               this.selectOption(item)
            }
         })
      }
   },
   mounted() {
      this.settings.options.forEach(item => {
         if (item.selected) {
            this.selectOption(item)
         }
      });
   }
}
</script>
<style lang="scss">
.v-select {
   cursor: pointer;
   position: relative;
   max-width: fit-content;

   &.open {
      .v-select__backdrop {
         display: block;
      }

      ul {
         opacity: 1;
         pointer-events: all;
      }
   }

   &__wrapper {
      display: flex;
      align-items: center;
      gap: 2px;
   }

   &__backdrop {
      // background-color: red;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: none;
   }

   &__input {
      display: block;
      cursor: pointer;
      height: 24px;
   }

   &__list {
      position: absolute;
      left: 0;
      top: 24px;
      border: 1px solid #000;
      width: 100%;
      z-index: 1;
      opacity: 0;
      pointer-events: none;
      transition: 0.3s;
   }

   &__item {
      padding: 5px;
      border-bottom: 1px solid #000;
   }
}
</style>