<template>

<HeaderFsop />

  <div class="container paddingcontainernew">
    <div class="footer-menu--block">
      <h3 class="contact mb-3">Помощь</h3>
    </div>
    <div class="block--help-form">
      <div class="mx-w--help">
       <h5 class="text--help-form">Форма обратной связи</h5>
       <p class="help-help-text text-center">Заполните обратную форму обратной и связи, <br> и ответ на вашу проблему поступит в кратчайшее время</p>
       <form @submit.prevent="submitForm">
        <div class="row">
          <div class="col-lg-12">
            <div class="help--form-div">
              <label class="help-form--label"
              :class="{'valid--help-form': validate_email}"
              >E-mail</label>
              <input type="text" v-model="email" class="help--input-form">
            </div>           
            <div class="help--form-div">
              <label class="help-form--label"
              :class="{'valid--help-form': validate_description}"
              >Опишите проблему</label>
              <textarea type="text" v-model="description" class="help--input-form" />
            </div> 
          </div>
        </div>

        <button class="send--mesaage-help">Отправить</button>

        <div class="notification is-danger" v-if="errors.length">
            <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>

        <p class="help-help-text">* Все поля обязательны к заполнению</p>
      </form>        
    </div>

  </div>
</div>

<FooterFsop />

</template>

<style type="text/css"></style>

<script>
  import axios from 'axios'
  import { toast } from 'bulma-toast'
  import HeaderFsop from '@/components/Header/HeaderFsop'
  import FooterFsop from '@/components/Footer/FooterFsop' 

  export default {
    name: 'Help',
    data() {
      return {
        page: 1,
        email: "",
        description: "",
        user_me: {},
        errors: [],
      }
    },
    components: {
      HeaderFsop,
      FooterFsop
    },
    beforeCreate() {
      this.$store.commit('initializeStore')

      const token = this.$store.state.token

      const user_id = this.$store.state.user_id

      this.user_id = user_id

      this.token = token
    },
    mounted() {
        document.title = 'Помощь'

        this.user_id = this.$store.state.user_id

        this.getMyAccount()

        setTimeout(() => {
          if (this.user_me) {
            this.email = this.user_me.email;
          } else {
            this.email = "";
          }
        }, 500)

    },
    methods: {
      async getMyAccount() {
        this.$store.commit('initializeStore')

        this.$store.commit('setIsLoading', true)

        await axios
        .get(`/api/v1/me/${this.user_id}/`)
        .then(response => {
          this.user_me = response.data
        })
        .catch(error => {
          console.log(error)
        })

        this.$store.commit('setIsLoading', false)
      },
      validEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      },
      async submitForm() {
        this.errors = []

        if (this.email === '') {
            this.errors.push('Укажите электронную почту.');
        } else if (!this.validEmail(this.email)) {
            this.errors.push('Укажите корректный адрес электронной почты.')
        }

        if (this.description === '') {
            this.errors.push('Проблема не указана.')
        }

        if (!this.errors.length) {
            const formData = {
              email: this.email,
              description: this.description,
            }

            await axios
            .post(`/api/v1/helpMessages/`, formData)
            .then(response => {
              toast({
                message: 'Данные успешно отправлены',
                type: 'is-success',
                dismissible: true,
                pauseOnHover: true,
                duration: 2000,
                position: 'bottom-right',
              })

              this.email = "";
              this.description = "";
            })                
            .catch(error => {
              console.log(error)
              toast({
                message: 'Произошла ошибка',
                type: 'is-danger',
                dismissible: true,
                pauseOnHover: true,
                duration: 2000,
                position: 'bottom-right',
              })                    
            })           
          }    
      }   
    },
    computed: {
      validate_email() {
        if(this.email) {
          return true;
        } else {
          return false;
        }
      },
      validate_description() {
        if(this.description) {
          return true;
        } else {
          return false;
        }
      },
    }    
  }
</script>