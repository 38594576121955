<template>
   <HeaderFsop />

   <div class="container paddingcontainernew">
      <div class="footer-menu--block region__header" style="padding-bottom: 30px !important;">
         <div class="region__image">
            <img :src="region.get_image" alt="">
         </div>
         <h3 class="contact region__name">{{ region.name }}</h3>
      </div>

      <div class="disk--block">
         <h3 class="disk--h5">Описание</h3>
         <p class="disk--p pb-2" v-html="region.disk">
         </p>
      </div>
      <div class="region__ruck events-list__title" v-if="Object.keys(region).length > 0 && region.newsBlocks.length">
         Новости
      </div>
      <div class="row">
        <div v-for="(list, index) in region.newsBlocks" class="col-lg-4 news_listing" :key="index">
            <router-link :to="'/news/' + list.id">
                <div class="news--fluid">
                    <img class="img-fluid hover-scale rounded" :src="list.get_image">
                </div>            
            </router-link>
            
            <div class="div-block--news-list">
              <router-link :to="'/news/' + list.id" class="athelets-name" style="margin-bottom: 10px;">{{ list.name }}</router-link>

              <router-link :to="'/news/' + list.id" class="athelets-disk" style="margin-bottom: 10px;" v-html="list.description"></router-link>

              <router-link :to="'/news/' + list.id" class="athelets-disk" style="margin-bottom: 10px;">{{ datanews(list.date_added) }}</router-link>

            </div>
        </div>
      </div>
<!--       <div class="region__blocks" v-if="firstBlock">
         <div class="region__block region-block"
            :class="{ 'full-width': firstBlock?.width === 100, 'fifty-width': firstBlock?.width === 50 }">
            <div class="region-block__image">
               <img :src="firstBlock?.get_image" alt="">
            </div>
            <div class="region-block__description" v-html="firstBlock?.description"></div>
         </div>
      </div> -->
      <div class="region__ruck events-list__title" v-if="region?.get_imageMain">
         Руководитель
      </div>
      <div class="region__blocks" v-if="region.get_imageMain">
         <div class="region__block region-block">
            <div class="region-block__image">
               <img :src="region?.get_imageMain" alt="">
            </div>
            <div class="region-block__description" v-html="region?.descriptionMain"></div>
         </div>
      </div>
      <div class="events__list events-list" v-if="region?.regionEvents?.length">
         <div class="events-list__header">
            <div class="events-list__title">Мероприятия</div>
         </div>
         <div class="events-list__item events-list__item_header">
            <div class="events-list__category events-list__category_date">Дата</div>
            <div class="events-list__category events-list__category_description">Описание</div>
            <div class="events-list__category events-list__category_region">Регион</div>
            <div class="events-list__category events-list__category_time">Дата</div>
         </div>
         <div class="events-list__content">
            <div class="events-list__item" v-for="(event, index) in region?.regionEvents" @click="OpenModal(event)">
               <div class="events-list__date">{{
                  formatedEventDate(event) }}</div>
               <div class="events-list__description" v-html="event.description"></div>
               <div class="events-list__region">
                  г. {{ event.city?.name }}
               </div>
               <div class="events-list__time">
                  {{ formatedEventTime(event) }}
               </div>
            </div>
         </div>
         <div class="events-list__footer">
            <router-link :to='{
               name: "AllEvents", params: {
                  region: this.region.id,
               },
            }' class="event-list__btn btnHome">
               Посмотреть все мероприятия
            </router-link>
         </div>
      </div>

      <div class="events-list__title" style="margin-bottom: 24px" v-if="secondPartBlocks.length">Спортивные объекты</div>
      <div class="region__blocks" v-if="secondPartBlocks.length">
         <div class="region__block region-block" v-for="item in secondPartBlocks"
            :class="{ 'full-width': item?.width == 100, 'fifty-width': item?.width == 50 }">
            <div class="region-block__image">
               <img :src="item?.get_image" alt="">
            </div>
            <div class="region-block__description" v-html="item?.description"></div>
         </div>
      </div>
      <div class="region__support" v-if="region?.linkPayment">
         <a class="region__link homeBtn" :href="region.linkPayment" target="_blank">Поддержать организацию</a>
      </div>
      <div class="disk--block">
         <div class="row">
            <div class="col-lg-6">
               <h3 class="disk--h5">Номер телефона</h3>
               <p class="disk--p pb-2">
                  {{ region.number }}
               </p>
            </div>
            <div class="col-lg-6">
               <h3 class="disk--h5">Доверенные лица</h3>
               <p class="disk--p pb-2">
                  {{ region.dov_face }}
               </p>
            </div>
         </div>
      </div>

      <div class="map">
         <iframe :src="region.map_yandex" width="100%" height="500" frameborder="0"></iframe>
      </div>

   </div>
   <div v-if="showmodal" class="modal-footer--open">
      <div class="modal-wrapper">

         <a @click="closeModal()" class="close--modal--footer"></a>


         <div class="modal-container--footer" v-if="!loading">

            <div class="modal-body--foter" style="overflow-y: auto;" v-if="event_detail.id != undefined">
               <a v-if="event_details.length > 1" @click="backModal()" class="back--modal--footer">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                     <path
                        d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l105.4 105.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H416C433.7 224 447.1 238.3 447.1 256z"
                        fill="#fff" />
                  </svg>
               </a>
               <div class="row margin-bottom--footer-modal">
                  <h4 class="footer--h4"
                     style="text-align: center !important; font-weight: 600; margin-bottom: 10px !important;">{{
                        event_detail.name }}</h4>
               </div>

               <div v-if="event_detail.eventsImgs.length" class="position-relative mb-4"
                  style="max-height: 270px; overflow: hidden;">
                  <swiper :modules="modules" navigation :pagination="{ clickable: true }"
                     :breakpoints="swiperOptions.breakpoints" @swiper="onSwiper" @slideChange="onSlideChange"
                     class="events-modal__swiper">
                     <swiper-slide v-for="(list, index) in event_detail.eventsImgs" :key="index">
                        <div class="events-modal__swiper_div" v-if="showbutton">
                           <img :src="list.get_image" alt="" class="fluid-image">
                        </div>
                        <div class="events-modal__swiper_div" v-else>
                           <router-link to="/my-account">
                              <img :src="list.get_image" alt="" class="fluid-image">
                           </router-link>
                        </div>
                     </swiper-slide>
                  </swiper>
               </div>
               <h4 class="modal-event--text" v-html="event_detail.description">
               </h4>

               <!-- <div class="d-flex margin-bottom--footer-modal">
                  <input class="custom-checkbox" type="checkbox" v-model="info" value="1" id="info">
                  <label class="field--checkbox-anketa" for="info">
                     Я ознакомлен с условиями мероприятия
                  </label>
               </div> -->

               <div class="d-flex margin-bottom--footer-modal" v-if="event_detail.get_document">
                  <a target="_blank" class="link--document" :href="event_detail.get_document">Документ</a>
               </div>



               <router-link :to='{
                  path: "/anketa/" + this.event_detail.id + "/" + this.event_detail_date, params: {
                     id: this.event_detail.id,
                     date: this.event_detail_date
                  },
               }' class="disablebutton pointerclick" v-if="this.isAuthenticated">
                  Заполнить форму
               </router-link>
               <router-link to='/log-in' class="disablebutton pointerclick" v-else>
                  Вы не участник спортобщества
               </router-link>

               <!-- <div v-if="showbutton">
                  <div v-if="event_detail.check_order == 1">
                     <button disabled="disabled" class="disablebutton">Вы уже участвуете в мероприятии</button>
                  </div>
                  <div v-else>
                     <button v-if="info == 1" class="detail--button" @click="SendPUT(event_detail)">
                        Подтверждаю
                     </button>
                     <button v-else disabled="disabled" class="disablebutton">Подтверждаю</button>
                     <router-link :to='{
                        path: "/anketa/" + this.event_detail.id + "/" + this.event_detail_date, params: {
                           id: this.event_detail.id,
                           date: this.event_detail_date
                        },
                     }' class="disablebutton pointerclick" v-if="this.isAuthenticated">
                        Заполнить форму
                     </router-link>
                     <router-link to='/log-in' class="disablebutton pointerclick" v-else>
                        Вы не участник спортобщества
                     </router-link>
                  </div>
               </div> -->
               <!-- <div v-else>
                  <router-link :to='{
                     path: "/anketa/" + this.event_detail.id + "/" + this.event_detail_date, params: {
                        id: this.event_detail.id,
                        date: this.event_detail_date
                     },
                  }' class="disablebutton pointerclick" v-if="this.isAuthenticated">
                     Вы не участник Спортобщества
                  </router-link>
                  <button @click='this.$router.push("/my-account")' class="disablebutton pointerclick" v-else>
                     Вы не участник Спортобщества
                  </button>

               </div> -->

            </div>

            <div class="modal-body--foter" style="overflow-y: auto;" v-else>

               <div class="row margin-bottom--footer-modal">
                  <h4 class="footer--h4"
                     style="text-align: left !important; font-weight: 600; margin-bottom: 10px !important;">Выберите
                     мероприятие:</h4>
               </div>

               <ul>
                  <li class="li_event mb-5" v-for="(item, index) in event_details" @click="OpenModal(item)">
                     <a @click="GetInfoEvent(index)" class="event_name">{{ item.name }}</a>
                  </li>
               </ul>

            </div>

         </div>
         <div class="modal-container--footer" v-else>
            <div class="modal-body--foter" style="overflow-y: auto;">

               <div class="outer">
                  <div class="inner">
                     <Loader />
                  </div>
               </div>

            </div>
         </div>


      </div>
   </div>
   <FooterFsop />
</template>

<style>
   .disk--p p {
      background: #FEF9F3 !important;      
   }
</style>
<style lang="scss" scoped>
.disk--h5 {
   font-weight: 500;
}

.region {
   &__header {
      display: flex;
      justify-content: space-between;
      align-items: start;
      position: relative;
   }

   &__ruck {
      text-align: center;
      margin-bottom: 32px;
   }

   &__name {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      @media(max-width: 768px) {
         position: unset;
         left: unset;
         transform: unset;
      }
   }

   &__image {
      img {
         width: auto;
         height: 100px;
         object-fit: cover;
      }
   }

   &__blocks {
      display: flex;
      flex-wrap: wrap;
      gap: 32px;

      @media(max-width: 992px) {
         gap: 24px;
      }
   }

   &__block {}

   &__events {
      margin-bottom: 24px;
   }

   &__event {}

   &__support {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 100px;
      margin-top: 50px;

      a {
         display: flex;
         align-items: center;
         justify-content: center;
         width: 300px;
         background-color: #EB3624 !important;
         color: #fff;
         text-align: center;
         font-size: 14px;
         line-height: 40px;
         border-radius: 10px;
         font-weight: 600;
      }
   }
}

.region-event {
   border: 1px solid black;

   &__image {
      width: 300px;
      height: 300px;

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }

   &__content {
      padding: 24px;
   }

   &__title {
      font-size: 28px;
   }

   &__description {
      font-size: 22px;
      margin-bottom: 24px;
   }

   &__dates {
      display: flex;
      flex-direction: column;
      gap: 24px;
   }

   &__date {
      font-size: 16px;
      line-height: 30px;
      font-weight: 500;
   }
}

.region-block {
   display: flex;
   gap: 40px;
   // border-bottom: 1px solid black;
   // flex-direction: column;
   // align-items: center;
   margin-bottom: 24px;

   &.full-width {
      width: 100%;
      flex: 0 0 100%;
   }

   &.fifty-width {
      width: calc(50% - 10px);
      flex: 0 0 calc(50% - 10px);
   }

   &__image {
      max-width: 400px;
      max-height: 400px;
      flex: 0 0 400px;
      border-radius: 12px;
      overflow: hidden;

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }

   &__description {
      padding: 24px;
      padding-top: 0;
      font-size: 16px;
      line-height: 30px;
      font-weight: 500;
   }

   @media(max-width: 992px) {
      flex-direction: column;
      gap: 16px;

      &__description {
         padding: 0;
         font-size: 14px;
      }

      &__image {
         flex: unset;
      }
   }
}

.events {

   &__list {}
}

.events-list {
   color: black;

   &__footer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;
   }

   &__title {
      color: black;
      font-weight: 700;
      font-size: 24px;
   }

   &__item {
      border-bottom: 1px solid rgba(0, 0, 0, 0.157);
      padding-bottom: 5px;

      &_header {
         border: 0;
      }
   }

   &__category {

      &_date {}

      &_description {}

      &_region {
         text-align: center;
      }

      &_time {
         text-align: center;
      }
   }

   &__content {}

   &__date {}

   &__description {}

   &__region {
      text-align: center;
      font-weight: 500;
   }

   &__time {
      text-align: center;
      font-weight: 500;
   }
}
</style>

<script>
import HeaderFsop from '@/components/Header/HeaderFsop'
import FooterFsop from '@/components/Footer/FooterFsop'
import axios from 'axios'
import { toast } from 'bulma-toast'
import moment from 'moment';


// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
export default {
   name: 'RegionDetail',
   data() {
      return {
         page: 1,
         total: 1,
         region: {},




         showmodal: false,
         showbutton: false,
         loading: false,

         event_details: [],

         swiperOptions: {
            breakpoints: {
               320: {
                  slidesPerView: 1,
                  spaceBetween: 30
               },
            }
         },







         event_detail: {},
         event_detail_date: "",
         isAuthenticated: false
      }
   },
   components: {
      HeaderFsop,
      FooterFsop,
      Swiper,
      SwiperSlide

   },
   mounted() {
      this.isAuthenticated = this.$store.state.isAuthenticated

      this.GetRegion()
   },
   methods: {
      closeModal() {
         this.showmodal = false;
         this.info = 0;
         this.current_day = 0;
         this.event_detail = {};
         this.event_details = [];
         this.images = [];
         this.data_now_detail = "";
      },
      backModal() {
         this.info = 0;
         this.event_detail = {};
         this.data_now_detail = "";
         this.images = [];
      },
      OpenModal(event) {
         // let index_massive = 1; // индекс месяца в нашем массива

         // for (let q = 0; q < this.tagsmoth.length; q++) {
         //    const itemnew = this.tagsmoth[q]

         //    if (itemnew.name == moth) {
         //       index_massive = q;
         //    }

         // }
         // let event_detail_month = this.tagsmoth[index_massive].index
         // this.event_detail.eventsData.forEach(item => {
         //    let date = new Date(item.date_added)
         //    let YY = date.getFullYear()
         //    let MM = date.getMonth() + 1
         //    let DD = date.getDate()
         //    if (YY == this.current_year && MM == event_detail_month && DD == this.current_day) {
         //       this.event_detail_date = item.date_added
         //    }
         // })
         this.event_detail_date = event.eventsData[0].date_added
         this.event_detail = event
         this.showmodal = true
      },
      formatedEventDate(item) {
         let date = item.eventsData[0]?.date_added
         if (date) {
            return moment(String(date)).format('DD') + '.' + moment(String(date)).format('MM') + '.' + moment(String(date)).format('YY')
         } else {
            return "-"
         }
      },
      formatedEventTime(item) {
         let date = item.eventsData[0]?.date_added
         if (date) {
            return moment(String(date)).format('HH') + ':' + moment(String(date)).format('MM')
         } else {
            return "-"
         }
      },
      async GetRegion() {

         const pk = this.$route.params.int_pk
         await axios
            .get(`/api/v1/region/${pk}/`)
            .then(response => {
               this.region = response.data
               console.log(response.data)

            })
            .catch(error => {
               console.log(error)
            })

      },
      datanews(date) {
         moment.locale('ru');
         const datasort = moment(String(date)).format('DD MMMM YYYY')
         return datasort
      },
   },
   computed: {
      secondPartBlocks() {
         if (this.region?.regionBlocks?.length > 0) {
            return this.region.regionBlocks
         } else {
            return []
         }
      },
   },
   setup() {
      const onSwiper = (swiper) => {
         console.log();
      };
      const onSlideChange = () => {
         console.log();
      };
      return {
         onSwiper,
         onSlideChange,
         modules: [Navigation, Pagination, Scrollbar, A11y],
      };
   },
}
</script>