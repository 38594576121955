<template>
    <div class="container paddingcontainernew">
        <div class="product-list">
            <div class="column is-12 mt-4">
                <h1 class="product--main">Спасибо за покупку</h1>

                <p>Ваш заказ обрабатывается.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Success',
    mounted() {
        document.title = 'Успешно | Fsop'
        this.$store.commit('clearCart')
    },
}
</script>