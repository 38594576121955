<template>
<!--   <div class="modal-container--footer" >
       <div class="modal-body--foter">
            <div class="row margin-bottom--footer-modal">
                  <h4 class="footer--h4">Заполните данные</h4>
                  <div class="col-lg-12">
                     <div class="field mb-0">
                        <label 
                        class="label--checkout"
                        >E-mail</label>
                        <input 
                        type="text" class="input--checkout" 
                        @focus="focused = false"
                        style="border-radius: 4px !important;" 
                        >
                     </div>
                 </div>          
            </div>
            <div class="d-flex margin-bottom--footer-modal">
              <input class="checkbox--info" 
                type="checkbox" 
                value="1" 
                v-model="info"
              >
              <p class="footer--p">Нажимая на галочку вы подверждаете согласие на обработку 
                <router-link class="modal-footer--link" to="/personal_info">персональных данных</router-link></p>
            </div>
            <button v-if="info == 1 && email.length > 0" class="detail--button" @click="sendForm">
              Готово
            </button>
            <button v-else disabled="disabled" class="detail--button disablebutton">Готово</button>
       </div> 
  </div> -->
        
<div v-if="vmodal" class="modal-footer--open">
   <div class="modal-wrapper">
      <a @click="$emit('close', false)" class="close--modal--footer"></a>
      <div 
          class="modal-reg modal-reg__active modal-container--footer" 
          :class="{
            'modal-reg__wait_wrapper': loading, 
            'modal-reg__error': error_code}"
          >
          <div class="modal-body--foter">
              <h5 class="auth--title mbNs">Подтвердите почту</h5>
              <div class="mobile__reg">
                  <p>Мы отправили вам код подтверждения на эту почту <br> <a :href="'mailto:' + email" target="_blank">{{ email }}</a></p>
                  <form class="modal-reg__input">
                      <input ref="code_1" v-model="code_1" type="number" v-mask="'#'" maxlength="1" placeholder="__">
                      <input ref="code_2" v-model="code_2" type="number" v-mask="'#'" maxlength="1" placeholder="__">
                      <input ref="code_3" v-model="code_3" type="number" v-mask="'#'" maxlength="1" placeholder="__">
                      <input ref="code_4" v-model="code_4" type="number" v-mask="'#'" maxlength="1" placeholder="__">
                  </form>
                  <p class="modal-reg__text_error">неверный код</p>
                  <div class="modal-reg__wait">
                      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                  </div>
              </div>
   <!--            <div class="center-flex__block">
                  <button class="button--auth backTo" @click="nextTo = 0">Назад</button>
              </div> -->
          </div>
      </div>
   </div>
</div>
</template>

<style type="text/css"></style>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

// Local Component
import { mask } from 'vue-the-mask'

export default {
   name: 'EmailConfirm',
   directives: { mask },
   data() {
      return {
         code_1: null,
         code_2: null,
         code_3: null,
         code_4: null,
         min: 1111,
         max: 9999,
         code_main: 0,

         code_check_1: 0,
         code_check_2: 0,
         code_check_3: 0,
         code_check_4: 0,

         redirect: "",
         error_code: false,
         loading: false,   
         semail: "",
      }
   },
   props: {
      email: {
         type: String,
         default: "",
      },
      vmodal: {
         type: Boolean,
         default: false
      },
   },
   mounted() {
      // this.$nextTick(() => this.$refs.code_1.focus())
   },
   methods: {
      sendForm() {
         toast({
            message: 'Подписка успешно оформлена',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
         })

         this.showmodal = false;
      },
      async sendEmail() {
            const data = {
                email: this.semail,
                code: this.code_main,
            }
            console.log(data)

            await axios
               .post('/api/v1/send/aprove-email/', data)
               .then(response => {
                   console.log(response)
               })
               .catch(error => {
                   console.log(error)
               })

      },
      Redirect() {
         if (Number(this.code_1) == this.code_check_1 && Number(this.code_2) == this.code_check_2 && Number(this.code_3) == this.code_check_3 && Number(this.code_4) == this.code_check_4) {
            this.loading = true;

            toast({
               message: 'Почта успешно подтверждена',
               type: 'is-success',
               dismissible: true,
               pauseOnHover: true,
               duration: 2000,
               position: 'bottom-right',
            })

            this.$emit('confirm', true)
            this.$emit('close', false)
         } else {
             if(this.code_4 != null) {
                 if (Number(this.code_1) != this.code_check_1 || Number(this.code_2) != this.code_check_2 || Number(this.code_3) != this.code_check_3 || Number(this.code_4) != this.code_check_4) {
                     this.error_code = true
                 }
             }
         }
      },
      Code() {
         this.code_main = Number(Math.floor(Math.random() * (this.max - this.min + 1) + this.min))
         const stingCode = this.code_main.toString();
         this.code_check_1 = Number(stingCode[0])
         this.code_check_2 = Number(stingCode[1])
         this.code_check_3 = Number(stingCode[2])
         this.code_check_4 = Number(stingCode[3])
         setTimeout(() => { this.sendEmail() }, 1500)
      },      
   },
   computed: {

   },
   watch: {
      code_1(value) {
          this.code_1 = value;
          if(this.code_1) {
              this.$nextTick(() => this.$refs.code_2.focus())
          }
          this.Redirect();
      },
      code_2(value) {
          this.code_2 = value;
          if(this.code_2) {
              this.$nextTick(() => this.$refs.code_3.focus())
          }
          this.Redirect();
      },
      code_3(value) {
          this.code_3 = value;
          if(this.code_3) {
              this.$nextTick(() => this.$refs.code_4.focus())
          }
          this.Redirect();
      },
      code_4(value) {
          this.code_4 = value;
          this.Redirect();
      },
      email(value) {
         this.semail = this.email
         console.log(this.semail)
      },
      vmodal(value) {
         if (this.vmodal) {
            this.Code()
         }
      },
   },     
   created() {
      
   },
}
</script>