<template>

<HeaderFsop />

  <div class="container paddingcontainernew">
    <div class="contact--block">
      <h3 class="contact">Контакты</h3>
    </div>
    <div class="disk--block">
      <h3 class="disk--h5">Описание</h3>
      <p class="disk--p pb-2" v-html="contact.name"></p> 
    </div>
    <div class="disk--block">
      <div class="row">
        <div class="col-lg-6">
          <h3 class="disk--h5">Номер телефона</h3>
          <p class="disk--p pb-2" v-html="contact.phone"></p>            
        </div>
        <div class="col-lg-6">
          <h3 class="disk--h5">Доверенные лица</h3>
          <p class="disk--p pb-2" v-html="contact.dov_face"></p>            
        </div>
      </div>
    </div>    
    <div class="disk--block">
      <h3 class="disk--h5">Реквизиты</h3>
      <p class="disk--p pb-2" v-html="contact.rekvizit"></p>        
    </div>
    <div class="map">
        <iframe :src="contact.map_yandex" width="100%" height="500" frameborder="0"></iframe>    
    </div>  

</div>

<FooterFsop />

</template>

<style scoped>
  p {
    color: #676767 !important;
    font-weight: 500 !important;
    line-height: 35px !important;   
  }
</style>

<script>
  import axios from 'axios'
  import HeaderFsop from '@/components/Header/HeaderFsop'
  import FooterFsop from '@/components/Footer/FooterFsop'  
  export default {
    name: 'Contacts',
    data() {
      return {
        showModalFirst: false,
        contact: {},
      }
    },
    mounted() {
      this.GetContact()
    },
    components: {
      HeaderFsop,
      FooterFsop
    },
    methods: {
      async GetContact() {

        await axios
        .get('/api/v1/contact/1/')
        .then(response => {
          this.contact = response.data
        })
        .catch(error => {
          console.log(error)
        })

      },
    },    
  }
</script>