<template>
   <Header />

   <div class="full--blue">

      <div class="container paddingcontainernew">
         <div class="parners--block">
            <div class="d-flex justify-content-between mb-6">
               <div class="">
                  <h4 class="my-account--name">{{ user_me.email }}</h4>
                  <router-link class="link-edit--a" to="/edit_profile">Редактировать профиль</router-link>
               </div>


               <a class="exit" @click="logout()">Выйти</a>
            </div>

            <div class="row mb-6 my-profile-column">
               <div v-if="user_me.name || user_me.surname
                  ||
                  user_me.city || user_me.adress
                  " class="col-lg-4">
                  <div class="box--info">
                     <h4 class="h4-title--myinfo">Личные данные:</h4>
                     <h4 class="my--info-name mb-0 pb-2" v-if="user_me.name || user_me.surname">
                        <span v-if="user_me.name">{{ user_me.name }}</span> <span v-if="user_me.surname">{{ user_me.surname
                        }}</span>
                     </h4>
                     <h5 class="fff"><span v-if="user_me.city || user_me.adress">{{ user_me.city }}</span> <span
                           v-if="user_me.city && user_me.adress">,</span> <span v-if="user_me.adress">{{ user_me.adress
                           }}</span></h5>
                  </div>

               </div>

               <div class="col-lg-4" v-if="user_me.sub">
                  <div class="box--info">
                     <h4 class="h4-title--myinfo">Подписка оформлена:</h4>
                     <h4 v-if="sub.date_end" class="my--info-name mb-0 pb-2" style="color: #E33B2A !important;">Истекает:
                        {{ data_account(sub.date_end) }}</h4>
                     <h4 v-else class="my--info-name mb-0 pb-2">Загрузка...</h4>
                  </div>
               </div>
               <div class="col-lg-4">
                  <div class="box--info">
                     <h4 class="h4-title--myinfo">Мои мероприятия</h4>
                     <div class="pb-2">
                        <router-link to="/my_events" class="my--info-name mb-0"><span
                              class="link--event">Подробнее</span></router-link>
                     </div>

                  </div>
               </div>
            </div>

<!--             <div class="column is-12">
               <h4 class="my-orders-title">Мои заказы</h4>

               <div v-if="orders.length > 0">
                  <OrderSummary v-for="order in orders" v-bind:key="order.id" v-bind:order="order" />
               </div>
               <div v-else>
                  <p class="p-error-order">Вы еще не совершали заказов, вы можете оформить заказ, <br> набрав товаров в
                     <router-link to="/cart" class="cart--red-link">корзину</router-link>
                  </p>
               </div>

            </div> -->
         </div>
      </div>

   </div>


   <Footer :blue="true" :search_on="false" />
</template>

<style type="text/css">
.my-orders-title {
   color: #fff;
   font-size: 18px;
   font-weight: 600;
   margin-bottom: 30px;
}

.cart--red-link {
   color: #E33B2A;
   font-weight: 500;
   padding-bottom: 2px;
   border-bottom: 1px solid #E33B2A;
}

.my--info-name {
   color: #fff;
   font-size: 18px;
   font-weight: 500;
}

.my-account--name {
   color: #fff !important;
   font-weight: 600;
   margin-bottom: 2px;
   font-size: 20px;
}

.h4-title--myinfo {
   color: #fff !important;
   font-weight: 600;
   margin-bottom: 25px;
   font-size: 20px;
}

.box--info {
   background-color: #0B1629;
   border-radius: 10px;
   box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
   color: #4a4a4a;
   display: block;
   padding: 1.5rem;
}

@media(max-width: 992px) {
   .box--info {
      margin-bottom: 20px;
   }
}

.link-edit--a {
   color: #0d6efd;
   font-size: 14px;
   font-weight: 600;
}

.p-error-order {
   color: #eaeaea;
   font-size: 15px;
}

.exit {
   font-size: 14px;
   color: #fff;
   font-weight: 600;
}

.link--event {
   color: #E33B2A;
   border-bottom: 1px solid #E33B2A;
   padding-bottom: 1px;
}
</style>

<script>
import axios from 'axios'

import OrderSummary from '@/components/OrderSummary.vue'

import Header from '@/components/Header/Header'
import Footer from '@/components/Footer/Footer'

import moment from 'moment';

export default {
   name: 'MyAccount',
   components: {
      OrderSummary,
      Header,
      Footer
   },
   data() {
      return {
         orders: [],
         sub: {},
         user_me: {},
         user_id: "",
         token: "",
         current_id: "",
      }
   },
   beforeCreate() {
      this.$store.commit('initializeStore')

      const token = this.$store.state.token

      const user_id = this.$store.state.user_id

      this.user_id = user_id

      this.token = token
   },
   mounted() {
      document.title = 'Мой аккаунт'

      console.log(this.$store.state.user)
   },
   methods: {
      logout() {
         axios.defaults.headers.common["Authorization"] = ""

         localStorage.removeItem("token")
         localStorage.removeItem("username")
         localStorage.removeItem("userid")
         localStorage.removeItem("sub_id")
         localStorage.removeItem("id_pay")
         localStorage.removeItem("status_pay")

         this.$store.commit('removeUser')
         this.$store.commit('removeToken')
         this.$store.commit('removeSub')
         this.$store.commit('removePayID')
         this.$store.commit('removePayStatus')

         this.$router.push('/')
      },
      async getMyOrders() {
         this.$store.commit('setIsLoading', true)

         await axios
            .get('/api/v1/orders/')
            .then(response => {
               this.orders = response.data
            })
            .catch(error => {
               console.log(error)
            })

         this.$store.commit('setIsLoading', false)
      },
      async getMyAccount() {
         this.$store.commit('initializeStore')

         this.$store.commit('setIsLoading', true)

         await axios
            .get(`/api/v1/me/${this.user_id}/`)
            .then(response => {
               this.user_me = response.data

               localStorage.setItem("user", this.user_me)

               this.$store.commit('setUserInfo', this.user_me)
            })
            .catch(error => {
               console.log(error)
            })

         this.$store.commit('setIsLoading', false)
      },
      async getMySub() {

         await axios
            .get(`/api/v1/sub/${this.$store.state.user.sub}/`)
            .then(response => {
               this.sub = response.data
            })
            .catch(error => {
               console.log(error)
            })

      },
      data_account(date) {
         moment.locale('ru');
         const datasort = moment(String(date)).format('DD MMMM YYYY')
         return datasort
      },
   },
   created() {
      this.user_id = this.$store.state.user_id

      this.getMyOrders()
      this.getMyAccount()

      setTimeout(() => {
         this.getMySub()
      }, 1500)

   }
}
</script>