<template>
  <div class="maincarousel">
    <swiper
      :modules="modules"
      :slides-per-view="1"
      :autoplay="autoplay"
      :pagination="{ clickable: true }"

      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide v-for="baner in baners" :key="baner">
        <div class="d-flex justify-content-center">
          <img :src="baner.get_image" class="carousel__item">
<!--           <div class="z-back--slide"></div> -->
        </div>
      </swiper-slide>

    </swiper>
  </div>
</template>
<script>
  // import Swiper core and required modules
  import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';

  // Import Swiper styles
  import 'swiper/css';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  import 'swiper/css/scrollbar';

  // Import Swiper styles
  export default {
    name: 'CarouselMain',
    components: {
      Swiper,
      SwiperSlide,
    },
    data() {
      return {
        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },
      }
    },
    props: {
      baners: Object,
    },
    setup() {
      const onSwiper = (swiper) => {

      };
      const onSlideChange = () => {

      };
      return {
        onSwiper,
        onSlideChange,
        modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
      };
    },
  };
</script>