<template>


      <div class="row">
        <div v-for="(list, index) in news" class="col-lg-4 mb-5" :key="index" v-show="index > 1">
            <router-link :to="'/news/' + list.id">
                <div class="news--fluid">
                    <img class="img-fluid hover-scale rounded" :src="list.get_image">
                </div>            
            </router-link>
            
            <div class="div-block--news-list">
              <router-link :to="'/news/' + list.id" class="athelets-name" style="margin-bottom: 10px;">{{ list.name }}</router-link>

              <router-link :to="'/news/' + list.id" class="athelets-disk" style="margin-bottom: 10px;" v-html="list.description"></router-link>

              <router-link :to="'/news/' + list.id" class="athelets-disk" style="margin-bottom: 10px;">{{ datanews(list.date_added) }}</router-link>

            </div>
        </div>
      </div>

</template>

<script>
  import axios from 'axios'
  import { toast } from 'bulma-toast'
  import moment from 'moment';

  export default {
    name: 'NewsSearchBox',
    props: {
        news: Object
    },
    mounted() {

    },
    methods: {
        datanews(date) {
          moment.locale('ru');
          const datasort = moment(String(date)).format('DD MMMM YYYY')
          return datasort
        },  
    }
  }
</script>