<template>

    <div class="full--404">
        <div class="container paddingcontainernew">
            <div class="parners--block">
                <div class="textCenter col-lg-12">
                    <div class="NotFound--block">
                        <h4 class="title-h4 textCenter">Страницы не существует ошибка 404</h4>
                        <router-link to="/">
                            <img src="@/assets/404.png">
                        </router-link>
                        <br>  
                        <router-link class="a--link" to="/">
                            На главную
                        </router-link>                    
                    </div>
                </div>
            </div>
        </div>    
    </div>

</template>

<style type="text/css">
    .NotFound--block {
        position: relative;
        padding: 4rem;
    }

    .title-h4 {
        color: #111;
        margin-bottom: 30px;
        font-size: 24px;
        font-weight: 600;
    }

    .a--link {
        font-weight: 600;
        color: #111;
        text-decoration: underline;    
    }

    .full--404 {
        background-color: #e5776c;
    }
</style>

<script>
export default {
    name: 'NotFound',
    mounted() {
        document.title = '404'
    },
}
</script>