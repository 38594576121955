<template>

  <HeaderFsop />

  <div class="container paddingcontainernew">
    <div class="category--block">
      <nav class="mb-lg-5 mt-3" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/" class="navbar-item">Главная</router-link></li>
          <li class="breadcrumb-item"><router-link to="/deyatelnost" class="navbar-item">Деятельность</router-link></li>
          <li class="breadcrumb-item"><span style="font-weight: 500;">{{ athelet.name }}</span></li>
        </ol>
      </nav>
    </div>

    <div class="row">
      <div class="col-lg-5">
        <div class="position-relative">
          <h5 class="athelet--name">{{ athelet.name }}</h5>
          <img :src="athelet.get_image" :alt="athelet.name" class="img-fluid rounded">
        </div>
      </div>
      <div class="col-lg-7">
        <div class="desc--block-athlet">
          <p class="description-athlet">{{ athelet.description }}</p>
        </div>

      </div>
    </div>

    

  </div>

  <div class="d-flex justify-content-center">
    <router-link to="/deyatelnost" class="back--athlet"><span class="border--back">Назад</span></router-link>
  </div>
  
  <FooterFsop />

</template>

<style type="text/css">

</style>

<script>
  import axios from 'axios'
  import { toast } from 'bulma-toast'
  import HeaderFsop from '@/components/Header/HeaderFsop'
  import FooterFsop from '@/components/Footer/FooterFsop' 

  export default {
    name: 'AtheletDetail',
    data() {
      return {
        athelet: {},
      }
    },
    components: {
      HeaderFsop,
      FooterFsop
    },
    mounted() {
      this.getAthelet()
    },
    methods: {
      async getAthelet() {
        this.$store.commit('setIsLoading', true)

        const pk = this.$route.params.int_pk

        await axios
        .get(`/api/v1/athelet/${pk}/`)
        .then(response => {
          this.athelet = response.data

          document.title = this.athelet.name
        })
        .catch(error => {
          this.errorcheck = error
          console.log(error)
        })

        this.$store.commit('setIsLoading', false)
      },     
    }
  }
</script>