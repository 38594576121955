<template>

  <HeaderFsop />


  <div class="container paddingcontainernew">
    <div v-if="!loader" class="category--block">
      <nav class="mb-lg-5 mt-3" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/" class="navbar-item">Главная</router-link></li>
          <li class="breadcrumb-item"><router-link to="/about_fsop" class="navbar-item">О спортбществе</router-link></li>
          <li class="breadcrumb-item"><span style="font-weight: 500;">{{ team.name }}</span></li>
        </ol>
      </nav>
    </div>

    <div v-if="loader"  class="outer">
      <div class="inner">
      <Loader />
      </div>
    </div>
    

    <div v-if="!loader" class="row">
      <div class="col-lg-5">
        <div class="position-relative">
          <h5 class="athelet--name">{{ team.name }}</h5>
          <img :src="team.get_image" :alt="team.name" class="img-fluid">
        </div>
      </div>
      <div class="col-lg-7">
        <div class="desc--block-athlet">
          <div class="px-lg-5">
            <p class="description-athlet" v-html="team.description"></p>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div v-if="!loader" class="d-flex justify-content-center">
    <router-link to="/about_fsop" class="back--athlet"><span class="border--back">Назад</span></router-link>
  </div>
  
  <FooterFsop />

</template>

<style type="text/css">

.outer {
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;
    width: 100%;
    justify-content: center;
    display: flex;
}

.inner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

</style>
<script>
  import axios from 'axios'
  import { toast } from 'bulma-toast'
  import HeaderFsop from '@/components/Header/HeaderFsop'
  import FooterFsop from '@/components/Footer/FooterFsop' 
  import Loader from '@/components/Loader/Loader' 

  export default {
    name: 'TeamDetail',
    data() {
      return {
        team: {},
        loader: false,
      }
    },
    components: {
      HeaderFsop,
      FooterFsop,
      Loader
    },
    mounted() {
      this.getTeam()
    },
    methods: {
      async getTeam() {
        this.loader = true;

        const pk = this.$route.params.int_pk

        await axios
        .get(`/api/v1/team/${pk}/`)
        .then(response => {
          this.team = response.data

          document.title = this.team.name
        })
        .catch(error => {
          this.errorcheck = error
          console.log(error)
        })

        setTimeout(() => {
          this.loader = false;
        }, 1000)
        
      },     
    }
  }
</script>