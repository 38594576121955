<template>

  <HeaderFsop />

  <div class="container paddingcontainernew">
      <div class="footer-menu--block">
        <h3 class="contact">Зал славы</h3>
      </div>

      <div class="row">
        <div class="col-lg-4 m-deyatelnost" v-for="list in athelets">
          <router-link :to="'/deyatelnost/' + list.id">
              <div class="ath--fluid">
                  <img class="img-fluid hover-scale rounded" :src="list.get_image">
              </div>            
          </router-link>
          
          <router-link to="/deyatelnost/1" class="athelets-name">{{ list.name }}</router-link>
        </div>
      </div>

  </div>

  <FooterFsop />

</template>

<style type="text/css">
  .m-deyatelnost {
    margin-bottom: 40px;
  }
</style>

<script>
  import axios from 'axios'
  import { toast } from 'bulma-toast'
  import HeaderFsop from '@/components/Header/HeaderFsop'
  import FooterFsop from '@/components/Footer/FooterFsop' 

  export default {
    name: 'Deyatelnost',
    data() {
      return {
        showModalFirst: false,
        page: 1,
        total: 1,
        athelets: [],
      }
    },
    components: {
      HeaderFsop,
      FooterFsop,
    },
    mounted() {
      this.getAthelets(this.page)
    },
    methods: {
         async getAthelets(pageNumber) {

            await axios
            .get(`/api/v1/athelets/?page=${pageNumber}`)
            .then(response => {
                this.total = response.data.count
                this.athelets = response.data.results
                console.log(this.athelets)
            })
            .catch(error => {
              console.log(error)
            })

        },      
    }
  }
</script>