<template>
   <div class="full--auth--back">

      <HeaderSmall />

      <div>
         <div class="position--auth-form event-record">
            <div class="max--form">
               <h4 class="pb-3 h4--sub-main">Участие на мероприятие</h4>
               <div v-if="event_detail?.eventsImgs?.length" class="position-relative mb-4"
                  style="max-height: 270px; overflow: hidden;">
                  <swiper :modules="modules" navigation :pagination="{ clickable: true }"
                     :breakpoints="swiperOptions.breakpoints" @swiper="onSwiper" @slideChange="onSlideChange"
                     class="events-modal__swiper">
                     <swiper-slide v-for="(list, index) in event_detail?.eventsImgs" :key="index">
                        <div class="events-modal__swiper_div">
                           <img :src="list?.get_image" alt="" class="fluid-image">
                        </div>
                        <div class="events-modal__swiper_div">
                           <router-link to="/my-account">
                              <img :src="list?.get_image" alt="" class="fluid-image">
                           </router-link>
                        </div>
                     </swiper-slide>
                  </swiper>
               </div>
               <h4 class="modal-event--text" v-html="event_detail?.description">
               </h4>
               <a v-if="event_detail?.get_document" 
                  target="_blank" 
                  class="link--document" 
                  :href="event_detail.get_document"
                  style="margin-bottom: 25px !important; display: block;" 
               >
                  Документ
               </a>
               <div class="event-dates" v-if="event_detail?.eventsData?.length > 1">
                  <ul class="event-dates__list">
                     <li class="event-dates__item" v-for="(date, index) in event_detail.eventsData" :key="index">
                        <input type="radio" :value="date.date_added" :id="index" v-model="choosedDate" name="date">
                        <label :for="index">{{ data_event(date.date_added) }}</label>
                     </li>
                  </ul>
               </div>
               <div class="event-record__radios">
                  <div v-if="event_detail.typeSolo">
                     <input type="radio" name="soloOrMulti" value="solo" id="solo" checked v-model="soloOrMulty">
                     <label for="solo" class="event-record__radio">
                        Индивидуальное
                     </label>
                  </div>
                  <div v-if="event_detail.typeGroup">
                     <input type="radio" name="soloOrMulti" value="multi" id="multi" v-model="soloOrMulty">
                     <label for="multi" class="event-record__radio">
                        Групповое
                     </label>
                  </div>
               </div>





               <div class="event-record__form record-form">
                  <div class="record-form__multi record-multi" v-if="soloOrMulty == 'multi'">
                     <div class="record-multi__edit" v-if="people.length && !multiAdding">
                        <ul class="record-multi__list">
                           <li v-for="(item, index) in people">
                              <div class="record-multi__item record__item" @click="editPeople(index)">
                                 <span>{{ item.email }}</span>
                                 <div class="record-multi__remove record__remove" @click.stop="removePeople(index)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"
                                       fill="none">
                                       <path d="M0 0H2L13 13H11L0 0Z" fill="#FFF" />
                                       <path d="M13 0H11L0 13H2L13 0Z" fill="#FFF" />
                                    </svg>
                                 </div>
                              </div>
                           </li>
                        </ul>
                        <button class="record-multi__add record__add btnHome" @click="recordOneMorePeople">Добавить участника</button>
                        <div class="d-flex margin-bottom--footer-modal">
                           <input class="custom-checkbox" type="checkbox" v-model="info" value="1" id="info">
                           <label class="field--checkbox-anketa" for="info">
                              Я ознакомлен с условиями мероприятия
                           </label>
                        </div>
                        <button class="record-form__res btnHome" @click="sendData">Записаться</button>
                     </div>
                     <div class="record-multi__adding" v-else>
                        <div class="record-form__suggest">Добавить данные участника</div>
                        <div class="record-form__inputs">
                           <div class="field--auth">
                              <input type="text" class="input--auth" v-model="people[activePeopleIndex].fio">
                              <label class="auth--label"
                                 :class="{ 'valid--auth': this.validate_field(people[activePeopleIndex].fio) }">Фио</label>
                           </div>
                           <div class="field--auth">
                              <input type="text" class="input--auth" v-model="people[activePeopleIndex].email"
                                 ref="peopleEmail" @input="removeError">
                              <label class="auth--label"
                                 :class="{ 'valid--auth': this.validate_field(people[activePeopleIndex].email) }">E-mail</label>
                           </div>
                        </div>

                        <button class="record-form__res btnHome" @click="recordPeople(activePeopleIndex)">Записать</button>
                     </div>
                  </div>
                  <div class="record-form__solo record-solo" v-if="soloOrMulty == 'solo'">
                     <div class="record-solo__edit" v-if="!soloAdding">
                        <ul class="record-solo__list">
                           <li v-if="parent" style="margin-bottom: 30px">
                              <div class="record-solo__item record__item" @click="editParent()">
                                 <span>{{ parent }}</span>
                              </div>
                           </li>
                           <li v-for="(item, index) in children">
                              <div class="record-solo__item record__item" @click="editChild(index)">
                                 <span>{{ item.fio }}</span>
                                 <div class="record-multi__remove record__remove" @click.stop="removeChild(index)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"
                                       fill="none">
                                       <path d="M0 0H2L13 13H11L0 0Z" fill="#FFF" />
                                       <path d="M13 0H11L0 13H2L13 0Z" fill="#FFF" />
                                    </svg>
                                 </div>
                              </div>
                           </li>
                        </ul>
                        <div class="d-flex margin-bottom--footer-modal">
                           <input class="custom-checkbox" type="checkbox" v-model="info" value="1" id="info">
                           <label class="field--checkbox-anketa" for="info">
                              Я ознакомлен с условиями мероприятия
                           </label>
                        </div>
                        <button class="record-solo__add record__add btnHome" @click="recordOneMoreChildren">Записать ребенка</button>
                        <button class="record-form__res btnHome" @click="sendData">Записать себя</button>
                     </div>
                     <div class="record-solo__adding" v-else>
                        <div class="record-form__suggest">Запись ребенка</div>
                        <div class="record-solo__parent" v-if="soloParent">
                           <v-select :settings="parentSelectSettings" @selectOption="selectClientOption" />
                           <div class="field--auth" v-if="parentSelect.index == 0">
                              <input type="text" class="input--auth" v-model="parent">
                              <label class="auth--label"
                                 :class="{ 'valid--auth': this.validate_field(this.parent) }">Представитель</label>
                           </div>
                           <button class="record__add btnHome" @click="cancelRecordParent">Сохранить</button>
                           <button class="record-form__res btnHome" @click="recordOneMoreChildren">Продолжить</button>
                        </div>
                        <div class="record-solo__child" v-else>
                           <div class="record-form__inputs">
                              <div class="field--auth">
                                 <input type="text" class="input--auth" v-model="children[activeChildrenIndex].fio">
                                 <label class="auth--label"
                                    :class="{ 'valid--auth': this.validate_field(children[activeChildrenIndex].fio) }">Фио</label>
                              </div>
                              <div class="field--auth">
                                 <input type="tel" v-mask="'##.##.####'" class="input--auth"
                                    v-model="children[activeChildrenIndex].birthday">
                                 <label class="auth--label"
                                    :class="{ 'valid--auth': this.validate_field(children[activeChildrenIndex].birthday) }">
                                    Дата рождения(01.01.2004)</label>
                              </div>
                              <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">

                              <div class="example-1">
                                 <div class="form-group" v-if="!children[activeChildrenIndex].document">
                                    <label class="label">
                                       <i class="material-icons">attach_file</i>
                                       <span class="title">Скан документа</span>
                                       <input type="file" @change="handleFileUpload($event, activeChildrenIndex)">
                                    </label>
                                 </div>
                                 <div class="form-group" v-else>
                                    <label class="label" style="border: 2px dashed #fff !important;">
                                       <i class="material-icons" style="color: #fff !important;">attach_file</i>
                                       <span class="title" style="color: #fff;">{{
                                          children[activeChildrenIndex].document.name }}</span>
                                       <input type="file" accept="image/*"
                                          @change="handleFileUpload($event, activeChildrenIndex)">
                                    </label>
                                 </div>
                              </div>
                           </div>
                           <button class="record__add btnHome" @click="cancelRecordChild">Удалить</button>
                           <button class="record-form__res btnHome"
                              @click="recordChild(activeChildrenIndex)">Записать</button>
                        </div>

                     </div>
                  </div>

               </div>






               <!-- <div class="d-flex margin-bottom--footer-modal">
                  <input class="custom-checkbox" type="checkbox" v-model="info" value="1" id="info">
                  <label class="field--checkbox-anketa" for="info">
                     Я ознакомлен с условиями мероприятия
                  </label>
               </div>
               <div class="record-form__send" style="margin-bottom: 40px">
                  <button type="button" class=btnHome>Подтверждаю запись на мероприятие</button>
               </div>
               <div class="d-flex margin-bottom--footer-modal" v-if="event_detail.get_document">
                  <a target="_blank" class="link--document" :href="event_detail.get_document">Документ</a>
               </div> -->
            </div>
         </div>
      </div>




      <Footersmall style="padding-bottom: 50px" />

   </div>
</template>
<style lang="scss" scoped>
.btnHome {
   width: 100%;
}

.v-select {
   margin-bottom: 16px;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
   pointer-events: all;
}

.full--auth--back {
   min-height: 100vh;
   height: unset;
}

.position--auth.w-100 {
   position: unset !important;
}

.event-record {
   margin-bottom: 100px;

   .position-relative.mb-4,
   .swiper {
      height: 400px;
      width: 400px;
      max-width: unset;
      max-height: 400px !important;
   }

   .max-form {
      max-width: 600px;
   }

   &__radios {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      gap: 12px;
      justify-content: center;

      input {
         display: none;
      }

      input:checked+label {
         opacity: 1;
      }
   }

   &__radio {
      color: white;
      opacity: 0.6;
      transition: 0.3s;
      cursor: pointer;
   }
}

.record-form {
   &__suggest {
      color: white;
      margin-bottom: 24px;
   }

   &__adding {}

   &__inputs {
      display: flex;
      flex-direction: column;
      // gap: 12px;
   }

   &__input {

      input,
      .record-form__more {
         border-radius: 10px;
         background: #B9AEAD;
         width: 100%;
         height: 50px;
         padding-left: 12px;
      }
   }

   &__added {}

   &__res {}

   &__add {}
}

.input--auth {
   border-radius: 10px;

   &.error {
      border: 1px solid red;
   }
}


.record-solo {
   &__add {}
}

.field--auth {
   margin-bottom: 24px;
}

.record {
   &__item {
      position: relative;
      margin-bottom: 16px;
      display: block;
      width: 100%;
      /* background-color: #EB3624 !important; */
      color: #fff;
      padding-left: 12px;
      border: 1px dashed white;
      /* text-align: center; */
      font-size: 14px;
      line-height: 45px;
      border-radius: 10px;
      font-weight: 600;
      cursor: pointer;
   }

   &__add {
      background-color: #CCC !important;
      margin-bottom: 24px;
   }

   &__remove {
      position: absolute;
      right: -12px;
      top: 50%;
      transform: translateY(-50%) translateX(100%);
      cursor: pointer;
   }
}

.event-dates {
   margin-bottom: 24px;

   &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
   }

   &__item {
      flex: 1;

      input {
         display: none;
      }

      label {
         white-space: nowrap;
         transition: 0.3s;
         display: flex;
         align-items: center;
         justify-content: center;
         background-color: #B9AEAD;
         color: #fff;
         padding: 0 12px;
         line-height: 45px;
         border-radius: 10px;
         font-weight: 600;
         cursor: pointer;
         transition: 0.3s;
      }

      input:checked+label {
         background-color: #EB3624;

      }
   }
}
</style>

<script>
import { toast } from 'bulma-toast'
import axios from 'axios'
import moment from 'moment';

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
// Local Component
import { mask } from 'vue-the-mask'

import HeaderSmall from '@/components/Header/HeaderSmall'
import Footersmall from '@/components/Footer/Footersmall'



import vSelect from '@/components/Select/v-select'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
export default {
   name: 'Anketa',
   directives: { mask },
   data() {
      return {
         swiperOptions: {
            breakpoints: {
               320: {
                  slidesPerView: 1,
                  spaceBetween: 30
               },
            }
         },


         user_id: 0,
         event_id: 0,
         event_detail: {},


         info: "",
         date: "",
         parentSelect: {
            name: "",
            index: 0,
         },
         parentSelectSettings: {
            options: [
               {
                  name: 'Законный представитель ребенка',
                  value: 1,
                  selected: true,
               },
               {
                  name: 'Законный представитель организации (в которой состоит ребенок)',
                  value: 2
               },
               {
                  name: 'Другое (вписать самостоятельно)',
                  value: 0
               },
            ]
         },
         parent: null,
         parentEditting: true,

         soloOrMulty: 'solo',


         multiAdding: true,
         soloAdding: false,
         soloParent: true,

         file: "",
         filesend: {},

         people: [
            {
               email: "",
               fio: ""
            },
         ],
         children: [],
         childrenFiles: [],


         activePeopleIndex: 0,
         activeChildrenIndex: -1,



         choosedDate: [],
         user: {},
         userMain: 0,
      }
   },
   components: {
      HeaderSmall,
      Footersmall,
      Swiper,
      SwiperSlide,
      vSelect
   },
   mounted() {
      this.user_id = this.$store.state.user_id
      this.date = this.$route.params.date
      this.event_id = this.$route.params.id
      this.GetEventDetail(this.event_id)
      this.getMyAccount()
   },
   methods: {
      async getMyAccount() {
         this.$store.commit('initializeStore')
         await axios
            .get(`/api/v1/me/${this.user_id}/`)
            .then(response => {
               this.user = response.data
               this.userMain = response.data.user
               console.log(response.data)
            })
            .catch(error => {
               console.log(error)
            })
      },
      clearData() {
         this.children = []
         this.childrenFiles = []
         this.activePeopleIndex = 0
         this.activeChildrenIndex = -1
         this.multiAdding = true
         this.soloAdding = false
         this.soloParent = true
         this.parent = null
         this.parentEditting = true
         this.info = ''
         this.people = [
            {
               email: "",
               fio: ""
            },
         ]
      },
      eventType() {
         if (this.event_detail.typeGroup && !this.event_detail.typeSolo) {
            this.soloOrMulty = 'multi'
         } else if (!this.event_detail.typeGroup && this.event_detail.typeSolo) {
            this.soloOrMulty = 'solo'
         }
      },
      setDate() {

         this.event_detail.eventsData.forEach(item => {
            if (item.date_added == this.date) {
               this.choosedDate = this.date
            }
         })
      },
      async GetEventDetail(id_event) {

         await axios
            .get(`/api/v1/event/${id_event}/`)
            .then(response => {
               console.log(response.data);
               this.event_detail = response.data
               this.eventType()
               this.setDate()
            })
            .catch(error => {
               console.log(error)
            })

      },
      async sendData() {
         if (!this.info) {
            toast({
               message: 'Ознакомьтесь с условиями мероприятия',
               type: 'is-success',
               dismissible: true,
               pauseOnHover: true,
               duration: 3000,
               position: 'bottom-right',
            })
            return
         }
         const object = {
            user: Number(this.userMain),
            event: Number(this.event_id),
            date_added: this.date,
         }
         if (this.soloOrMulty == 'multi') {
            object.parentType = null
            object.itemsEvents = this.people
            object.itemsOrderChild = []
         } else if (this.soloOrMulty == 'solo') {
            object.parentType = this.parent
            object.itemsEvents = []
            this.children.forEach(item => {
               item.document = `https://xn--90aci8aadbjgghe2g.xn--p1ai/admin/child/${item.document.name}`
            })
            object.itemsOrderChild = this.children
            this.sendFiles()
         }

         console.log('object', object);
         await axios
            .post("/api/v1/post/events-order/", object)
            .then(res => {
               toast({
                  message: 'Данные отправились',
                  type: 'is-success',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 3000,
                  position: 'bottom-right',
               })
               this.VSendMail()
               this.clearData()
            })
            .catch(err => {
               console.log(err);
            })

      },
      async VSendMail() {
         const object = {
            email: this.user.email,
            name: this.user.name,
            eventdata: moment(String(this.date), "YYYY-MM-DD").format('DD MMMM YYYY'),
         }

         await axios
            .post("/api/v1/send/anketa-email/", object)
            .then(res => { console.log(res.data) })
            .catch(err => { console.log(err) })
      },
      async sendFiles() {
         await this.childrenFiles.forEach(item => {
            let name = item.name
            // console.log(item);
            axios
               .put(`/api/v1/upload_file/${name}`, item, {
                  headers: {
                     'content-Type': 'multipart/form-data'
                  }
               })
               .then(res => {

                  console.log("files", res.data);
               })
         })

      },
      recordPeople(index) {
         let active = this.people[index]
         if (!active.fio.length) {
            toast({
               message: 'Введите имя',
               type: 'is-success',
               dismissible: true,
               pauseOnHover: true,
               duration: 3000,
               position: 'bottom-right',
            })
            return
         }
         if (!this.validEmail(active.email)) {
            this.$refs.peopleEmail.classList.add("error")
            toast({
               message: 'Неккоректна почта',
               type: 'is-success',
               dismissible: true,
               pauseOnHover: true,
               duration: 3000,
               position: 'bottom-right',
            })
            return
         }
         this.multiAdding = false
      },
      recordOneMorePeople() {
         let obj = {
            fio: "",
            email: ""
         }
         this.people.push(obj)
         this.activePeopleIndex++
         this.multiAdding = true
      },
      editPeople(index) {
         this.multiAdding = true
         this.activePeopleIndex = index
      },
      removePeople(index) {
         if (this.people.length == 1) {
            let obj = {
               fio: "",
               email: "",
            }
            this.people.push(obj)
            this.multiAdding = true
            this.activePeopleIndex == 0
         } else {
            this.activePeopleIndex--
         }
         this.people.splice(index, 1)
      },

      handleFileUpload(event, index) {
         this.children[index].document = event.target.files[0]
         this.childrenFiles[index] = this.children[index].document
      },



      recordOneMoreChildren() {
         if (this.parent) {
            this.soloParent = false;
            let obj = {
               fio: "",
               birthday: "",
               document: null
               // eventOrderId: 0,
            }
            this.children.push(obj)
            this.activeChildrenIndex++


         }
         this.soloAdding = true
      },
      removeChild(index) {
         this.activeChildrenIndex--
         this.children.splice(index, 1)
      },
      editChild(index) {
         this.soloAdding = true
         this.activeChildrenIndex = index
      },
      recordChild(index) {
         let active = this.children[index]
         if (!active.fio.length) {
            toast({
               message: 'Введите имя',
               type: 'is-success',
               dismissible: true,
               pauseOnHover: true,
               duration: 3000,
               position: 'bottom-right',
            })
            return
         }
         if (active.document == null) {
            toast({
               message: 'Укажите документ',
               type: 'is-success',
               dismissible: true,
               pauseOnHover: true,
               duration: 3000,
               position: 'bottom-right',
            })
            return
         }
         if (moment(String(active.birthday), "YYYY-MM-YYYY").isValid()) {
            active.birthday = moment(String(active.birthday), "YYYY-MM-DD").format('YYYY-MM-DD')
            this.soloAdding = false
            return
         }
         if (active.birthday.length !== 10 || !moment(String(active.birthday), "DD.MM.YYYY", true).isValid()) {
            toast({
               message: 'Неккоректная дата рождения',
               type: 'is-success',
               dismissible: true,
               pauseOnHover: true,
               duration: 3000,
               position: 'bottom-right',
            })
            return
         }
         active.birthday = moment(String(active.birthday), "DD.MM.YYYY", true).format('YYYY-MM-DD')
         this.soloAdding = false




















         // let dateArr = active.birthday.split(".")
         // let DD = dateArr[0]
         // let MM = dateArr[1]
         // let YYYY = dateArr[2]
         // if (!(DD <= 31 && DD > 0 && MM <= 12 && MM > 0 && YYYY < new Date().getFullYear() && YYYY > 1950)) {
         //    toast({
         //       message: 'Неккоректна дата рождения',
         //       type: 'is-success',
         //       dismissible: true,
         //       pauseOnHover: true,
         //       duration: 3000,
         //       position: 'bottom-right',
         //    })
         //    return
         // }
         // console.log(String(active.birthday));

         // console.log(moment("30.01.2004").format('DD-MM-YYYY'));
         // active.birthday = moment(String(active.birthday)).format('YYYY-MM-DD')
         // active.birthday = dateArr.reverse().join("-")
      },
      cancelRecordChild() {
         this.children.splice(this.activeChildrenIndex, 1)
         this.activeChildrenIndex--;
         this.soloAdding = false
      },



      editParent() {
         this.soloAdding = true
         this.soloParent = true
      },
      cancelRecordParent() {
         this.soloAdding = false;
      },

      selectClientOption(option) {
         this.parentSelect.name = option.name;
         this.parentSelect.index = option.value;

         this.parent = option.name
      },


      validEmail(email) {
         var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
         return re.test(email);
      },
      validate_field(value) {
         if (value?.length > 0) {
            return true;
         } else {
            return false;
         }
      },
      removeError(e) {
         e.target.classList.remove("error")
      },
      data_event(date) {
         moment.locale('ru');
         const datasort = moment(String(date)).format('DD MMMM YYYY')
         return datasort
      },
   },
   setup() {
      const onSwiper = (swiper) => {
         console.log();
      };
      const onSlideChange = () => {
         console.log();
      };
      return {
         onSwiper,
         onSlideChange,
         modules: [Navigation, Pagination, Scrollbar, A11y],
      };
   },
   computed: {
      Validate_Phone() {
         if (this.phone) {
            return true;
         } else {
            return false;
         }
      },
      Validate_Name() {
         if (this.name) {
            return true;
         } else {
            return false;
         }
      },
      Validate_Surname() {
         if (this.surname) {
            return true;
         } else {
            return false;
         }
      },
      Validate_patronymic() {
         if (this.patronymic) {
            return true;
         } else {
            return false;
         }
      },
      Validate_Gender() {
         if (this.gender) {
            return true;
         } else {
            return false;
         }
      },
      Validate_birthday() {
         if (this.birthday) {
            return true;
         } else {
            return false;
         }
      },
      Validate_seriya_pasport() {
         if (this.seriya_pasport) {
            return true;
         } else {
            return false;
         }
      },
      Validate_number_pasport() {
         if (this.number_pasport) {
            return true;
         } else {
            return false;
         }
      },
      Validate_vidan_pasport() {
         if (this.vidan_pasport) {
            return true;
         } else {
            return false;
         }
      },
      Validate_data_pasport() {
         if (this.data_pasport) {
            return true;
         } else {
            return false;
         }
      },
   },
   watch: {
      choosedDate() {
         this.date = this.choosedDate
      }
   }
}
</script>