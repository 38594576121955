<template>
<Header />

    <div class="container paddingcontainernew">

        <div class="product--detail--padding">
            <div class="row">
                <div class="col-lg-8">
                    <div class="round-img---detail">
                     <a @click="open_full_img()">
                        <img class="rounded img-fluid img-fluid--hovered-mini" v-bind:src="product.get_image">                            
                    </a>

                </div>

            </div>
            <div class="col-lg-4">
                <div class="block--product">
                    <h5 class="product--detail-title">{{ product.name }}</h5>
                    <h6 class="product--detail-price">
                        <span v-if="product.discount" class="product--detail-discount">
                            {{ product.discount }} Руб.
                        </span>
                        <span v-if="product.discount" class="product--detail-price-decoration">{{ product.price }} Руб.</span>
                        <span v-else>{{ product.price }} Руб.</span>


                    </h6>
                    <h6 class="product--detail-amount">В наличии: {{ product.amount }}</h6>
                    <h6 class="product--detail-amount">Производитель: {{ product.selection_p }}</h6>
                    <h6 class="product--detail-amount">Артикул: {{ product.slug }}</h6>

                    <div class="amount--block w-100">
                        <div class="d-flex justify-content-start pab15">
                            <button v-on:click="quantity -= 1" class="quality--amount-ins">-</button>
                            <input type="number" class="quanity--number-input" min="1" v-model="quantity">
                            <button v-on:click="quantity += 1" class="quality--amount-ins">+</button>
                        </div>
                        <div class="control marg-5" v-if="product.amount >= quantity">
                            <button :disabled="isDisable(quantity)" v-if="isHidden == false" class="detail--button" @click="addToCart()">В корзину</button>
                            <button v-if="isHidden == true" class="detail--button">Добавлено в корзину</button>
                        </div>
                        <div class="control marg-5" v-else>
                            <button disabled="disabled" class="detail--button">Нет в наличии</button>
                        </div> 
                    </div>

                </div>
                <div class="">
                    <h3 class="content__title-2">Описание</h3>
                    <div v-if="product.description">
                        <p class="product-detail-description">
                            {{ product.description }}
                        </p>
                    </div>
                    <div v-else>
                        <div class="product-detail-description" style="color: red !important;">Описание временно отсутствует</div>
                    </div>                         
                </div>
            </div>
        </div>            
    </div>
    <div class="product--list-detail">
        <div class="container paddingcontainernew p-576-0">
          <div class="product-block">
              <div class="product-carousel-slider">
                <h3 class="product--main">Похожие товары</h3>
                  <swiper
                  :modules="modules"
                  navigation
                  :breakpoints="swiperOptions.breakpoints"
                  @swiper="onSwiper"
                  @slideChange="onSlideChange"
                  >
                  <swiper-slide v-for="(prod, index) in products" :key="index">
                      <a @click="newProductOpen(prod.get_absolute_url)">
                        <div class="img--inner">
                          <img class="img-fluid img-fluid--hovered" :src="prod.get_image">
                      </div>
                  </a>
                  <div class="block-tittle--product">
                     <a @click="newProductOpen(prod.get_absolute_url)" class="product--title">{{ prod.name }}
                     </a>

                     <h4 class="product--price">
                        <span v-if="prod.discount" class="product--discount">{{ prod.discount }} Руб.</span>
                        <span v-if="prod.discount" class="text--product-s">
                          {{ prod.price }} Руб.
                      </span>
                      <span v-else>
                          {{ prod.price }} Руб.
                      </span>
                  </h4>            

              </div>

              <button v-if="prod.amount > 0 || check_amount == 1" class="product--btn" @click="addToCart()">Добавить в корзину</button>         
              <button v-else class="product--btn-disabled" disabled="disabled">Нет в наличии</button> 
          </swiper-slide>

    </swiper>
</div>
<!--       
  <TheBestProduct title="Похожие товары" /> -->
</div>
</div>         
</div>

</div>

<div v-if="showmodal" class="modal-mask-s" >

  <div class="modal-wrapper">

   <a @click="showmodal = false" class="close--modal"></a>

   <div class="modal-containers-s">

      <div class="modal-body">
        <slot name="body">
            <img class="img-fluid" v-bind:src="product.get_image">     
        </slot>
    </div>

</div>
</div>
</div>

<Footer />

</template>

<style type="text/css">
td {
    font-size: 1.1rem;
}
</style>

<script>
    import { toast } from 'bulma-toast'
    import axios from 'axios'

    // import Swiper core and required modules
    import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

    // Import Swiper Vue.js components
    import { Swiper, SwiperSlide } from 'swiper/vue';

    // Import Swiper styles
    import 'swiper/css';
    import 'swiper/css/navigation';
    import 'swiper/css/pagination';
    import 'swiper/css/scrollbar';

    import Header from '@/components/Header/Header'
    import Footer from '@/components/Footer/Footer'

    export default {
        name: 'ProductView',
        components: {
          Swiper,
          SwiperSlide,        
          Header,
          Footer
        },
        data() {
            return {
              cart: {
                items: []
              },
              products: [],
              product: {},
              quantity: 1,
              amount: 0,
              page: 1,
              check_amount: 0,
              isHidden: false,
              errorcheck: '',
              showmodal: false,
              swiperOptions: {
                breakpoints: {       
                    320: {       
                       slidesPerView: 2,
                       spaceBetween: 30     
                    },          
                    770: {       
                       slidesPerView: 3,       
                       spaceBetween: 30     
                    },   
                
                    1024: {       
                       slidesPerView: 5.5,       
                       spaceBetween: 30     
                    } 
                 }
            }  
        }
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  mounted() {
        this.cart = this.$store.state.cart
        this.getLatestProducts(this.page)
        this.getProduct();
        
        // setTimeout(() => {
        //     this.check_product_cart();
        // }, 300);
        
    },
    methods: {
       async getLatestProducts(pageNumber) {
        this.$store.commit('setIsLoading', true)

        await axios
        .get(`/api/v1/latest-products/?page=${pageNumber}`)
        .then(response => {
          this.products = response.data.results
      })
        .catch(error => {
          console.log(error)
      })

        this.$store.commit('setIsLoading', false)
    },
    newProductOpen(url) {
        this.$router.push(url)

        this.$store.commit('setIsLoading', true)

        setTimeout(() => {
            this.getProduct();
        }, 300)

        this.$store.commit('setIsLoading', false)
    },
    async getProduct() {
        this.$store.commit('setIsLoading', true)

        const category_slug = this.$route.params.category_slug
        const product_slug = this.$route.params.product_slug

        await axios
        .get(`/api/v1/products/${category_slug}/${product_slug}/`)
        .then(response => {
            this.product = response.data
            this.amount = response.data.amount

            this.cart = this.$store.state.cart
            this.check_amount = 0

            document.title = this.product.name
        })
        .catch(error => {
            this.errorcheck = error
            console.log(error)
        })

        this.$store.commit('setIsLoading', false)
    },
    isDisable() {
       return this.quantity < 1;
    },
    open_full_img() {
        this.showmodal = true;
    },
    addToCart() {

        if (this.cart.items.length > 0) {

            for (let q = 0; q < this.cart.items.length; q++) {
                const item = this.cart.items[q]           
                let id = item.product.id


                if (id == this.product.id) {
                    if (item.quantity >= this.product.amount) {
                        this.check_amount = 1
                    }
                }

            }
        }

        if (this.check_amount == 0) {

          const item = {
            product: this.product,
            quantity: this.quantity
        }

        this.$store.commit('addToCart', item)

            toast({
                message: 'Товар был добавлен в корзину',
                type: 'is-success',
                dismissible: true,
                pauseOnHover: true,
                duration: 2000,
                position: 'bottom-right',
            })
        } else {
          toast({
            message: 'Товар больше нет в наличии',
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
            })
        }

    }, 
    // addToCart() {
    //     if (isNaN(this.quantity) || this.quantity < 1) {
    //         this.quantity = 1
    //     }

    //     const item = {
    //         product: this.product,
    //         quantity: this.quantity
    //     }

    //     this.$store.commit('addToCart', item)

    //     toast({
    //         message: 'Продукт был добавлен в корзину',
    //         type: 'is-success',
    //         dismissible: true,
    //         pauseOnHover: true,
    //         duration: 2000,
    //         position: 'bottom-right',
    //     })

    //     this.isHidden = true
    // },
      
}
}
</script>