<template>
    <div class="full--auth--back">
        
        <HeaderSmall />

        <div class="position--auth-form">
            <div class="max--form">
                <h4 class="pb-3 h4--sub-main">Успех</h4>
            </div>
        </div>

        <Footersmall />

    </div>
</template>

<script>
    import { toast } from 'bulma-toast'
    import axios from 'axios'

    import HeaderSmall from '@/components/Header/HeaderSmall'
    import Footersmall from '@/components/Footer/Footersmall'

    export default {
        name: 'SuccessSubs',
        data() {
            return {
                user_me: {},
                sub_id: "",
            }
        },
        components: {
            HeaderSmall,
            Footersmall
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {
            document.title = 'Успех подписки'

            this.sub_id = this.$store.state.sub_id
            this.user_id = this.$store.state.user_id

            this.getMyAccount();

            setTimeout(() => {
                this.sendSub();
            }, 500)

        },
        methods: {
            async getMyAccount() {

                await axios
                    .get(`/api/v1/me/${this.user_id}/`)
                    .then(response => {
                        this.user_me = response.data
                    })
                    .catch(error => {
                        console.log(error)
                    })

            },
            async sendSub() {

                await axios

                const dataUser = {
                    'sub': this.sub_id,
                    'email': this.user_me.email
                }

                await axios
                    .put(`/api/v1/me/${this.user_id}/`, dataUser)
                    .then(response => {
                        toast({
                            message: 'Подписка успешно оформлена',
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        })

                    })                
                    .catch(error => {
                        console.log(error)
                        toast({
                            message: 'Обратитесь к нам в поддержку',
                            type: 'is-danger',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        })                    
                    })        

            },
        },
        computed: {
      
        }
    }
</script>